const ListViewSkeleton = () => {
  return (
    <div className="mb-4">
      <div className="flex items-center justify-between p-4 md:p-6 m-2 bg-gray-800 rounded-md">
        <div className="flex items-center space-x-4 w-full">
          {/* Title and status section */}
          <div className="flex-1">
            <div className="h-5 bg-gray-700 rounded w-1/4 mb-2"></div>
            <div className="h-4 bg-gray-700 rounded w-1/6"></div>
          </div>

          {/* Project section */}
          <div className="hidden md:block w-32">
            <div className="h-4 bg-gray-700 rounded w-3/4"></div>
          </div>

          {/* Priority section */}
          <div className="hidden md:block w-24">
            <div className="h-4 bg-gray-700 rounded w-1/2"></div>
          </div>

          {/* Date section */}
          <div className="hidden md:block w-32">
            <div className="h-4 bg-gray-700 rounded w-2/3"></div>
          </div>

          {/* Status section */}
          <div className="hidden md:block w-32">
            <div className="h-4 bg-gray-700 rounded w-1/2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListViewSkeleton;
