import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * StepContent displays the main content for a step in the onboarding flow
 *
 * @param {Object} props - Component props
 * @param {Object} props.stepData - The step data object
 * @returns {React.Component} StepContent component
 */
const StepContent = ({ stepData }) => {
  const { title, description, secondaryDescription } = stepData;

  return (
    <div className="min-h-[200px] mb-16">
      <h2 className="text-3xl font-bold text-white mb-4">{title}</h2>

      <p className="text-[#E6E6EA] font-medium text-base mb-4">{description}</p>

      {secondaryDescription && (
        <p className="text-[#E6E6EA] font-medium text-base mb-4">
          {secondaryDescription}
        </p>
      )}
    </div>
  );
};

StepContent.propTypes = {
  stepData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    secondaryDescription: PropTypes.string,
    background: PropTypes.string,
    icon: PropTypes.elementType,
    showBadgeCount: PropTypes.bool,
    showSocialIcons: PropTypes.bool,
    finalStep: PropTypes.bool,
  }).isRequired,
};

export default StepContent;
