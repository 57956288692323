import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Cross2Icon } from "@radix-ui/react-icons";
import { POSITION_CLASSES } from "../constants";

/**
 * ModalWrapper provides the outer container for the onboarding modal
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - The content to render inside the modal
 * @param {Function} props.onClose - Handler for close button click
 * @param {string} props.position - The position of the modal
 * @returns {React.Component} ModalWrapper component
 */
const ModalWrapper = ({ children, onClose, position = "center" }) => {
  return (
    <div
      className={classNames(
        "fixed inset-0 bg-black/50 flex z-50",
        POSITION_CLASSES[position] || POSITION_CLASSES["center"]
      )}
    >
      <div className="bg-[#1F1B29] border border-[#2B2735] rounded-2xl relative shadow-[0px_0px_32px_4px_rgba(155,138,223,0.3)] w-[486px]">
        {/* Close button */}
        <div
          className="absolute top-4 right-4 bg-white rounded-full p-[2px] cursor-pointer"
          onClick={onClose}
        >
          <Cross2Icon color="#150F23" />
        </div>

        {children}
      </div>
    </div>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.oneOf(Object.keys(POSITION_CLASSES)),
};

ModalWrapper.defaultProps = {
  position: "center",
};

export default ModalWrapper;
