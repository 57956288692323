import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  useReducer,
} from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import moment from "moment";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
  CircleIcon,
  ClockIcon,
  CrossCircledIcon,
  DoubleArrowUpIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@radix-ui/react-icons";
import Button from "../Button";
import "react-circular-progressbar/dist/styles.css";
import "./ProjectPreviewTabs.css";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { useMutationDelete } from "../../hooks/crud/mutation.delete";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { notify } from "../Toast/ToastComponent";
import Modal from "../Modal/Modal";
import { CreateModal } from "../Modal/Modal";
import { ModalViewDetails } from "./ModalView";
import { CreateProjectForm } from "../CreateProject/CreateProject";
import { CreateTaskForm } from "../CreateTask/CreateTask";
import Dialog from "../Dialog/Dialog";
import { DeleteOutlined } from "../../assets/svg/DeleteOutlined";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  ProjectCardSkeleton,
  TaskCardSkeleton,
  CalendarSkeleton,
  StatsSkeleton,
} from "../Skeleton/Skeleton";
import { NoTasks } from "src/assets/svg/NoTasks";
import { CreateEventForm } from "../CreateEvent/CreateEvent";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/app-routes";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { NoProjects } from "src/assets/svg/NoProjects";
import { CheckCircle } from "src/assets/svg/CheckCircle";
import { estimatedTimeData } from "src/constants/constant";
import {
  dashboardReducer,
  initialState,
  dashboardActions,
} from "../../reducers/dashboardReducer";

const DashboardOverview = () => {
  const navigate = useNavigate();
  const calendarRef = useRef(null);
  const [state, dispatch] = useReducer(dashboardReducer, initialState);
  const dropdownRef = useRef(null);

  // Data Queries
  const {
    data: projectData,
    refetch: refetchProjectData,
    isLoading: isProjectLoading,
  } = useDataQuery({
    url: API_END_POINTS.project,
    params: {},
  });

  const { data: tasksData, refetch: refetchTaskData } = useDataQuery({
    url: API_END_POINTS.tasks,
    params: {},
  });

  const { data: dashboardData, refetch: refetchDashboardData } = useDataQuery({
    url: API_END_POINTS.dashboard,
    params: {},
  });

  // Mutations
  const { mutate: patchTask } = useMutationPatch(
    `${API_END_POINTS.tasks}/${state.data.selectedData?._id}`
  );

  const { mutate: deleteTask } = useMutationDelete(
    API_END_POINTS.tasks,
    state.data.selectedData?._id
  );

  const { mutate: deleteProject } = useMutationDelete(
    API_END_POINTS.project,
    state.data.selectedData?._id
  );

  const { mutate: createEvent } = useMutationCreate(API_END_POINTS.events);

  const { mutate: patchTaskList } = useMutationPatch(API_END_POINTS.tasks);
  const { mutate: patchProjectList } = useMutationPatch(API_END_POINTS.project);

  // Handlers
  const handleViewTask = (task) => {
    dispatch(dashboardActions.setSelectedData(task));
    dispatch(
      dashboardActions.setModal({
        isModalOpen: true,
        modalTitle: "Task Details",
        modalContentType: "Task",
      })
    );
  };

  const handleViewProject = async (project) => {
    // First refetch the latest data
    await refetchProjectData();

    // Then find the latest version of this project
    const updatedProject = projectData.data.find((p) => p._id === project._id);

    dispatch(dashboardActions.setSelectedData(updatedProject));
    dispatch(
      dashboardActions.setModal({
        isModalOpen: true,
        modalTitle: "Project Details",
        modalContentType: "Project",
      })
    );
  };

  const handleCreateTask = (projectId) => {
    dispatch(
      dashboardActions.setModal({
        isCreateModalOpen: true,
        modalTitle: "Create Task",
        modalContentType: "Task",
        selectedData: {
          projectId,
          project: projectId,
        },
        isEdit: false,
      })
    );
  };

  const handleMarkAsComplete = () => {
    const updatedData = {
      ...state.data.selectedData,
      completed: true,
      status: "COMPLETED",
    };

    patchTask(updatedData, {
      onSuccess(res) {
        dispatch(dashboardActions.setJustUpdated(true));
        dispatch(dashboardActions.setModal({ isModalOpen: false }));
        refetchProjectData();
        refetchTaskData();
        notify(
          `${state.modals.modalContentType} marked as complete`,
          "success"
        );
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleDelete = () => {
    dispatch(dashboardActions.setModal({ confirmationDialog: true }));
  };

  const handleDeleteConfirmation = () => {
    dispatch(dashboardActions.setLoading(true));
    const mutation =
      state.modals.modalContentType === "Task" ? deleteTask : deleteProject;
    const refetch =
      state.modals.modalContentType === "Task"
        ? refetchTaskData
        : refetchProjectData;

    mutation(
      {},
      {
        onSuccess(res) {
          dispatch(
            dashboardActions.setModal({
              isModalOpen: false,
              confirmationDialog: false,
            })
          );
          dispatch(dashboardActions.setJustUpdated(true));
          refetch();
          dispatch(dashboardActions.setLoading(false));
          notify(
            `${state.modals.modalContentType} deleted successfully`,
            "success"
          );
        },
        onError: (err) => {
          notify(err?.error?.message, "error");
          dispatch(dashboardActions.setLoading(false));
        },
      }
    );
  };

  //navigaation handlers
  // Add these navigation handlers
  const handleProjectClick = () => {
    // Navigate to tasks and projects page with project tab selected
    navigate(APP_ROUTES.eventAndTasks, {
      state: {
        selectedTab: "Projects",
      },
    });
  };

  const handleTaskClick = () => {
    // Navigate to tasks and projects page with tasks tab selected
    navigate(APP_ROUTES.eventAndTasks, {
      state: {
        selectedTab: "Tasks",
      },
    });
  };

  const handleEventClick = (event) => {
    // Navigate to calendar page
    navigate(APP_ROUTES.calendar, {
      state: {
        selectedEvent: event, // Pass the event data to potentially highlight it
      },
    });
  };

  // Helper functions
  const calculateProgress = (project) => {
    if (!project?.tasksList?.length) return 0;
    const completed = project.tasksList.filter((task) => task.completed).length;
    return Math.round((completed / project.tasksList.length) * 100);
  };

  const getTaskPriority = (priority) => {
    const priorities = {
      HIGH: { color: "#FF4D4F", label: "High" },
      MEDIUM: { color: "#FAAD14", label: "Medium" },
      LOW: { color: "#52C41A", label: "Low" },
    };
    // Return LOW priority if priority is undefined, null, or not in the priorities object
    return priorities[priority] || priorities.LOW;
  };

  // Group tasks
  const groupedTasks = {
    today:
      tasksData?.data?.filter((task) =>
        moment(task.endDate).isSame(moment(), "day")
      ) || [],
    upcoming:
      tasksData?.data?.filter((task) =>
        moment(task.endDate).isAfter(moment())
      ) || [],
    overdue:
      tasksData?.data?.filter(
        (task) => moment(task.endDate).isBefore(moment()) && !task.completed
      ) || [],
  };

  const handleExpand = (id, e) => {
    e.stopPropagation(); // Prevent event bubbling

    // If we're closing the expanded section, clear the selection
    if (state.data.expandedId === id) {
      dispatch(dashboardActions.setExpanded(null));
      dispatch(dashboardActions.setSelectedData(null));
      return;
    }

    // Find the task data from the tasks list
    const task = tasksData?.data?.find((task) => task._id === id);

    // Update both expandedId and selectedData
    dispatch(dashboardActions.setExpanded(id));
    dispatch(dashboardActions.setSelectedData(task));
  };

  // Helper function to get project name
  const getProjectName = (projectId) => {
    const project = projectData?.data?.find((p) => p._id === projectId);
    return project?.title || "No Project";
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      dispatch(dashboardActions.toggleDropdown(null)); // This will close all dropdowns
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderTaskCard = (task) => {
    const statusOptions = [
      { label: "To Do", value: "TODO", icon: <CircleIcon color="#06719A" /> },
      {
        label: "In Progress",
        value: "IN_PROGRESS",
        icon: <ClockIcon color="#FF7700" />,
      },
      {
        label: "Blocked",
        value: "BLOCKED",
        icon: <CrossCircledIcon color="#EF4870" />,
      },
      {
        label: "Completed",
        value: "COMPLETED",
        icon: <CheckCircle color="#06719A" />,
      },
    ];

    const priorityOptions = [
      {
        label: "High",
        value: "HIGH",
        icon: <DoubleArrowUpIcon color="#EF4870" />,
      },
      {
        label: "Medium",
        value: "MEDIUM",
        icon: <ArrowUpIcon color="#FF7700" />,
      },
      { label: "Low", value: "LOW", icon: <ArrowDownIcon color="#4CAF50" /> },
    ];

    return (
      <div key={task._id} className="project-card m-1">
        <div
          className="project-header cursor-pointer"
          onClick={(e) => handleExpand(task._id, e)}
        >
          <div className="project-title">
            <div>
              <h3 className="text-white text-lg">{task.title}</h3>
              <span className="text-gray-400 text-sm">
                {getProjectName(task?.project)} • ETC:{" "}
                {task.estimatedTimeHours || 0} hours
              </span>
              <span
                className="priority-label"
                style={{
                  backgroundColor: getTaskPriority(task.priority).color + "20",
                  color: getTaskPriority(task.priority).color,
                  padding: "2px 8px",
                  borderRadius: "4px",
                  fontSize: "0.75rem",
                }}
              >
                {getTaskPriority(task.priority).label}
              </span>
            </div>
          </div>
          {state.data.expandedId === task._id ? (
            <ChevronUpIcon className="text-gray-400 chevron-icon open" />
          ) : (
            <ChevronDownIcon className="text-gray-400 chevron-icon" />
          )}
        </div>

        {state.data.expandedId === task._id && (
          <div
            className={`accordion-content open ${
              Object.values(state.dropdowns).some(Boolean)
                ? "expanded-dropdown"
                : ""
            }`}
            style={{
              zIndex: Object.values(state.dropdowns).some(Boolean)
                ? 10
                : "auto",
            }}
            ref={dropdownRef}
          >
            <div className="stats-grid">
              <div className="stat-card relative">
                <div className="stat-label text-xs md:text-sm text-gray-400 flex items-center gap-2">
                  Status
                  {
                    statusOptions.find((option) => option.value === task.status)
                      ?.icon
                  }
                </div>
                <div
                  className="stat-value text-sm md:text-base lg:text-lg cursor-pointer flex items-center gap-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(dashboardActions.toggleDropdown("status"));
                  }}
                >
                  <span>
                    {
                      statusOptions.find(
                        (option) => option.value === task.status
                      )?.label
                    }
                  </span>

                  {state.dropdowns.status && (
                    <div className="absolute top-full left-0 mt-1 bg-[#1F2437] border border-gray-700 rounded-md shadow-lg z-20 min-w-[150px]">
                      {statusOptions.map((option) => (
                        <div
                          key={option.value}
                          className="flex items-center gap-2 px-4 py-2 hover:bg-gray-700 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            const updatedTask = {
                              ...task,
                              status: option.value,
                              completed: option.value === "COMPLETED",
                            };

                            // Optimistically update UI
                            dispatch(
                              dashboardActions.setData((prevData) => {
                                return prevData.map((project) => ({
                                  ...project,
                                  tasksList:
                                    project.tasksList?.map((t) =>
                                      t._id === task._id ? updatedTask : t
                                    ) || project.tasksList,
                                }));
                              })
                            );

                            patchTask(updatedTask, {
                              onSuccess() {
                                notify(
                                  `Status changed to ${option.label}`,
                                  "success"
                                );
                                refetchTaskData();
                                refetchProjectData();
                              },
                              onError: (err) => {
                                // Revert optimistic update
                                dispatch(
                                  dashboardActions.setData((prevData) => {
                                    return prevData.map((project) => ({
                                      ...project,
                                      tasksList:
                                        project.tasksList?.map((t) =>
                                          t._id === task._id ? task : t
                                        ) || project.tasksList,
                                    }));
                                  })
                                );
                                notify(err?.error?.message, "error");
                              },
                            });
                            dispatch(dashboardActions.toggleDropdown("status"));
                          }}
                        >
                          {option.icon}
                          <span className="text-white">{option.label}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="stat-card relative">
                <div className="stat-label flex items-center gap-2">
                  Priority{" "}
                  {
                    priorityOptions.find(
                      (option) => option.value === task.priority
                    )?.icon
                  }
                </div>
                <div
                  className="stat-value cursor-pointer flex items-center gap-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(dashboardActions.toggleDropdown("priority"));
                  }}
                >
                  <span>
                    {priorityOptions.find(
                      (option) => option.value === task.priority
                    )?.label || "Medium"}
                  </span>

                  {state.dropdowns.priority && (
                    <div className="absolute top-full left-0 mt-1 bg-[#1F2437] border border-gray-700 rounded-md shadow-lg z-20 min-w-[150px]">
                      {priorityOptions.map((option) => (
                        <div
                          key={option.value}
                          className="flex items-center gap-2 px-4 py-2 hover:bg-gray-700 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            const updatedTask = {
                              ...task,
                              priority: option.value,
                            };

                            // Optimistically update UI
                            dispatch(
                              dashboardActions.setData((prevData) => {
                                return prevData.map((project) => ({
                                  ...project,
                                  tasksList:
                                    project.tasksList?.map((t) =>
                                      t._id === task._id ? updatedTask : t
                                    ) || project.tasksList,
                                }));
                              })
                            );

                            patchTask(updatedTask, {
                              onSuccess() {
                                notify(
                                  `Priority changed to ${option.label}`,
                                  "success"
                                );
                                refetchTaskData();
                              },
                              onError: (err) => {
                                // Revert optimistic update
                                dispatch(
                                  dashboardActions.setData((prevData) => {
                                    return prevData.map((project) => ({
                                      ...project,
                                      tasksList:
                                        project.tasksList?.map((t) =>
                                          t._id === task._id ? task : t
                                        ) || project.tasksList,
                                    }));
                                  })
                                );
                                notify(err?.error?.message, "error");
                              },
                            });
                            dispatch(
                              dashboardActions.toggleDropdown("priority")
                            );
                          }}
                        >
                          {option.icon}
                          <span className="text-white">{option.label}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="stat-card relative">
                <div className="stat-label flex items-center gap-2">
                  Est. Time <ClockIcon />
                </div>
                <div
                  className="stat-value cursor-pointer flex items-center gap-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(dashboardActions.toggleDropdown("estimatedTime"));
                  }}
                >
                  <span>{task.estimatedTimeHours || 0} hrs</span>

                  {state.dropdowns.estimatedTime && (
                    <div className="absolute top-full left-0 mt-1 bg-[#1F2437] border border-gray-700 rounded-md shadow-lg z-20 min-w-[150px]">
                      {estimatedTimeData.map((option) => (
                        <div
                          key={option.id}
                          className="flex items-center gap-2 px-4 py-2 hover:bg-gray-700 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            const updatedTask = {
                              ...task,
                              estimatedTimeHours: option.value,
                            };

                            // Optimistically update UI
                            dispatch(
                              dashboardActions.setData((prevData) => {
                                return prevData.map((project) => ({
                                  ...project,
                                  tasksList:
                                    project.tasksList?.map((t) =>
                                      t._id === task._id ? updatedTask : t
                                    ) || project.tasksList,
                                }));
                              })
                            );

                            patchTask(updatedTask, {
                              onSuccess() {
                                notify(
                                  `Estimated time updated to ${option.label}`,
                                  "success"
                                );
                                refetchTaskData();
                              },
                              onError: (err) => {
                                // Revert optimistic update
                                dispatch(
                                  dashboardActions.setData((prevData) => {
                                    return prevData.map((project) => ({
                                      ...project,
                                      tasksList:
                                        project.tasksList?.map((t) =>
                                          t._id === task._id ? task : t
                                        ) || project.tasksList,
                                    }));
                                  })
                                );
                                notify(err?.error?.message, "error");
                              },
                            });
                            dispatch(
                              dashboardActions.toggleDropdown("estimatedTime")
                            );
                          }}
                        >
                          <span className="text-white">{option.label}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="action-buttons">
              <Button
                variety="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  handleViewTask(task);
                }}
                className="action-button"
              >
                View Details
              </Button>
              <Button
                variety="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteTask(task);
                  dispatch(
                    dashboardActions.setModal({ modalContentType: "Task" })
                  );
                }}
                className="action-button"
              >
                Delete Task
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const localizer = momentLocalizer(moment);

  // Format tasks for calendar
  const calendarEvents =
    dashboardData?.data?.events
      ?.filter(
        (event) =>
          // Filter out events without titles and check if event is today
          event.title &&
          (moment(event.startDateTime).isSame(moment(), "day") ||
            moment(event.endDateTime).isSame(moment(), "day"))
      )
      ?.map((event) => ({
        id: event._id,
        title: event.title,
        start: moment(event.startDateTime).local().toDate(),
        end: moment(event.endDateTime).local().toDate(),
        resource: event, // store original event data
      })) || [];

  // Custom event styling
  const eventStyleGetter = (event) => {
    const isGoogleEvent = event.resource?.isGoogleEvent;
    return {
      style: {
        backgroundColor: isGoogleEvent
          ? "rgba(66, 133, 244, 0.2)"
          : "rgba(255, 255, 255, 0.1)",
        borderLeft: `3px solid ${isGoogleEvent ? "#4285F4" : "#fff"}`,
        color: "white",
        borderRadius: "4px",
        border: "none",
        padding: "2px 5px",
      },
    };
  };

  // Add delete handler
  const handleDeleteTask = (task) => {
    dispatch(dashboardActions.setSelectedData(task));
    dispatch(dashboardActions.setModal({ confirmationDialog: true }));
  };

  const sortProjectsByCreatedDate = (projects) => {
    return [...projects].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  };

  useEffect(() => {
    // Function to scroll to current time
    const scrollToCurrentTime = () => {
      const scrollContainer = document.querySelector(
        ".calendar-dark .rbc-time-content"
      );
      if (scrollContainer) {
        const currentHour = new Date().getHours();
        const hourHeight = scrollContainer.scrollHeight / 24; // Height per hour
        const scrollTo = (currentHour - 1) * hourHeight; // Scroll to 1 hour before current time

        scrollContainer.scrollTop = scrollTo;
      }
    };

    // Initial scroll with a delay to ensure calendar is rendered
    const timeoutId = setTimeout(scrollToCurrentTime, 300);

    return () => clearTimeout(timeoutId);
  }, [calendarEvents]);

  const handleCreateProject = () => {
    dispatch(
      dashboardActions.setModal({
        isCreateModalOpen: true,
        modalTitle: "Create Project",
        modalContentType: "Project",
        selectedData: {},
        isEdit: false,
      })
    );
  };

  const handleCreateEvent = () => {
    dispatch(
      dashboardActions.setModal({
        isCreateModalOpen: true,
        modalTitle: "Create Event",
        modalContentType: "Event",
        selectedData: {},
        isEdit: false,
      })
    );
  };

  const {
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});

  const onEventSubmit = (data) => {
    const startTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[0];
    const endTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[1];
    const startDateTime = new Date(
      moment(data?.startDate).format("YYYY-MM-DD") + " " + startTime
    );
    const endDateTime = new Date(
      moment(data?.endDate).format("YYYY-MM-DD") + " " + endTime
    );

    createEvent(
      {
        ...data,
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
        startTime: startTime,
        endTime: endTime,
        tags: [],
        eventType: "Meeting",
        collaboration: "Public",
      },
      {
        onSuccess(res) {
          dispatch(dashboardActions.setModal({ isCreateModalOpen: false }));
          refetchDashboardData();
          notify("Event created successfully", "success");
          reset();
        },
      }
    );
  };

  const handleUpdateTitle = (newTitle, taskId) => {
    dispatch(
      dashboardActions.setData((prevData) => {
        return prevData.map((project) => {
          if (project.tasksList) {
            return {
              ...project,
              tasksList: project.tasksList.map((task) =>
                task._id === taskId ? { ...task, title: newTitle } : task
              ),
            };
          }
          return project;
        });
      })
    );

    dispatch(
      dashboardActions.setSelectedData((prevData) => {
        if (prevData?.tasksList) {
          return {
            ...prevData,
            tasksList: prevData.tasksList.map((task) =>
              task._id === taskId ? { ...task, title: newTitle } : task
            ),
          };
        }
        return prevData;
      })
    );
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Handle project tasks reordering
    if (source.droppableId === "projectTasks") {
      // Get the current project's tasks and reorder them
      const newTasksList = Array.from(state.data.selectedData.tasksList).sort(
        (a, b) => (a.projectDisplayIndex || 0) - (b.projectDisplayIndex || 0)
      );

      const [removed] = newTasksList.splice(source.index, 1);
      newTasksList.splice(destination.index, 0, removed);

      // Create a full project list update with the reordered tasks
      const updatedProjects = projectData.data.map((project) => {
        if (project._id === state.data.selectedData._id) {
          return {
            ...project,
            tasksList: newTasksList.map((task, index) => ({
              ...task,
              projectDisplayIndex: index,
            })),
          };
        }
        return project;
      });

      // Update local state
      dispatch(
        dashboardActions.setData((prev) => ({
          ...prev,
          selectedData: {
            ...prev.selectedData,
            tasksList: newTasksList.map((task, index) => ({
              ...task,
              projectDisplayIndex: index,
            })),
          },
        }))
      );

      // Send the array directly without wrapping it in an object
      patchProjectList(updatedProjects, {
        onSuccess: () => {
          notify("Task order updated successfully", "success");
          refetchProjectData();
        },
        onError: (err) => {
          notify(err?.error?.message || "Failed to update task order", "error");
        },
      });
    }
  };

  // Add an effect to update selectedData when projectData changes
  useEffect(() => {
    if (state.data.selectedData && projectData?.data) {
      const updatedProject = projectData.data.find(
        (p) => p._id === state.data.selectedData._id
      );
      if (updatedProject) {
        dispatch(dashboardActions.setSelectedData(updatedProject));
      }
    }
  }, [projectData, state.data.selectedData?._id]);

  const handleTaskCompletion = (e, task) => {
    e.stopPropagation(); // Prevent event bubbling

    // Optimistically update the local state
    dispatch(
      dashboardActions.setSelectedData((prev) => {
        if (prev?._id === task._id) {
          return {
            ...prev,
            completed: !task.completed,
            status: !task.completed ? "COMPLETED" : "TODO",
          };
        }
        return prev;
      })
    );

    const updatedTask = {
      ...task,
      completed: !task.completed,
      status: !task.completed ? "COMPLETED" : "TODO",
    };

    patchTask(updatedTask, {
      onSuccess: () => {
        refetchProjectData();
        refetchTaskData();
        notify(
          `Task marked as ${!task.completed ? "completed" : "uncompleted"}`,
          "success"
        );
      },
      onError: (err) => {
        // Revert the optimistic update on error
        dispatch(
          dashboardActions.setSelectedData((prev) => {
            if (prev?._id === task._id) {
              return {
                ...prev,
                completed: task.completed,
                status: task.status,
              };
            }
            return prev;
          })
        );
        notify(err?.error?.message || "Failed to update task", "error");
      },
    });
  };

  return (
    <div className="dashboard-overview">
      {/* Projects Section */}
      <section className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h2
            onClick={() => handleProjectClick()}
            className="text-lg md:text-xl lg:text-2xl text-white cursor-pointer"
          >
            Recent Projects
          </h2>
          <div
            variety="icon"
            onClick={handleCreateProject}
            className="w-12 h-12 rounded-full bg-gray-800 hover:bg-gray-700 items-center flex justify-center cursor-pointer"
          >
            <PlusIcon color="white" className="w-5 h-5" />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4">
          {isProjectLoading ? (
            <>
              {[...Array(3)].map((_, i) => (
                <ProjectCardSkeleton key={i} />
              ))}
            </>
          ) : projectData?.data?.length > 0 ? (
            sortProjectsByCreatedDate(projectData.data)
              ?.slice(0, 3)
              .map((project) => (
                <div key={project._id} className="project-card">
                  <div
                    className="project-header cursor-pointer"
                    onClick={(e) => handleExpand(project._id, e)}
                  >
                    <div className="project-title">
                      <div className="progress-circle">
                        <CircularProgressbar
                          value={calculateProgress(project)}
                          text={`${calculateProgress(project)}%`}
                          styles={{
                            path: {
                              stroke: "#6875F5",
                            },
                            text: {
                              fontSize: "2rem",
                            },
                          }}
                        />
                      </div>
                      <div>
                        <h3 className="text-base md:text-lg lg:text-xl text-white">
                          {project.title}
                        </h3>
                        <p className="text-xs md:text-sm text-gray-400">
                          {project.tasksList?.length || 0} tasks • Due{" "}
                          {moment(project.endDate).format("MMM D")}
                        </p>
                      </div>
                    </div>
                    {state.data.expandedId === project._id ? (
                      <ChevronUpIcon className="text-gray-400 chevron-icon open" />
                    ) : (
                      <ChevronDownIcon className="text-gray-400 chevron-icon" />
                    )}
                  </div>

                  {state.data.expandedId === project._id && (
                    <div className="accordion-content open">
                      <div className="stats-grid">
                        <div className="stat-card">
                          <div className="stat-label">Completed</div>
                          <div className="stat-value">
                            {project.tasksList?.filter((t) => t.completed)
                              .length || 0}
                          </div>
                        </div>
                        <div className="stat-card">
                          <div className="stat-label">In Progress</div>
                          <div className="stat-value">
                            {project.tasksList?.filter((t) => !t.completed)
                              .length || 0}
                          </div>
                        </div>
                        <div className="stat-card">
                          <div className="stat-label">Due Date</div>
                          <div className="stat-value">
                            {project.endDate
                              ? moment(project.endDate).format("MMM D")
                              : "No date"}
                          </div>
                        </div>
                      </div>

                      {/* tasks list with fixed height and scroll */}
                      <div className="tasks-container">
                        <div className="tasks-list mt-4">
                          <h4 className="text-white text-sm mb-2">Tasks</h4>
                          <div className="tasks-scroll-area">
                            {project.tasksList &&
                            project.tasksList.length > 0 ? (
                              project.tasksList.map((task) => (
                                <div
                                  key={task._id}
                                  className="task-item flex items-center justify-between p-2 mb-2 bg-gray-800 rounded"
                                >
                                  <div className="flex items-center">
                                    <div
                                      className="w-4 h-4 mr-2 cursor-pointer hover:opacity-80 transition-opacity"
                                      onClick={async (e) => {
                                        await dispatch(
                                          dashboardActions.setSelectedData(task)
                                        );
                                        handleTaskCompletion(e, task);
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={task.completed ? 100 : 0}
                                        text=""
                                        styles={{
                                          path: {
                                            stroke: task.completed
                                              ? "#52C41A"
                                              : "#6b7280",
                                          },
                                          root: {
                                            cursor: "pointer",
                                          },
                                        }}
                                      />
                                    </div>
                                    <span
                                      className={`text-sm text-gray-300 ${
                                        task.completed
                                          ? "line-through opacity-50"
                                          : ""
                                      }`}
                                    >
                                      {task.title}
                                    </span>
                                  </div>
                                  <span
                                    className="priority-label text-xs"
                                    style={{
                                      backgroundColor:
                                        getTaskPriority(task.priority).color +
                                        "20",
                                      color: getTaskPriority(task.priority)
                                        .color,
                                      padding: "2px 8px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {getTaskPriority(task.priority).label}
                                  </span>
                                </div>
                              ))
                            ) : (
                              <div className="text-gray-400 text-sm">
                                No tasks created yet
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Fixed action buttons at the bottom */}
                      <div className="action-buttons-fixed">
                        <Button
                          variety="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCreateTask(project._id);
                          }}
                          className="action-button"
                        >
                          Add Task
                        </Button>
                        <Button
                          variety="filled"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleViewProject(project);
                          }}
                        >
                          View Details
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ))
          ) : (
            <div className="col-span-full">
              <div className="project-card flex flex-col items-center justify-center p-8 text-center">
                <NoProjects className="w-48 h-48 mb-4" />
                <h3 className="text-white text-xl font-medium mb-2">
                  No Projects Yet
                </h3>
                <p className="text-gray-400 text-sm max-w-md mb-6">
                  Create your first project to start organizing your tasks and
                  tracking progress. Projects help you group related tasks
                  together.
                </p>
                <Button
                  variety="filled"
                  onClick={handleCreateProject}
                  className="px-6"
                >
                  Create Your First Project
                </Button>
              </div>
            </div>
          )}
        </div>
      </section>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-4 mb-8">
        {/* Today's Tasks Column */}
        <section>
          <div className="flex justify-between items-center mb-4">
            <h2
              onClick={() => handleTaskClick()}
              className="text-lg md:text-xl lg:text-2xl text-white cursor-pointer"
            >
              Today's Tasks ({groupedTasks.today?.length || 0})
            </h2>
            <div
              variety="icon"
              onClick={handleCreateTask}
              className="w-12 h-12 rounded-full bg-gray-800 hover:bg-gray-700 items-center flex justify-center cursor-pointer"
            >
              <PlusIcon color="white" className="w-5 h-5" />
            </div>
          </div>
          <Suspense
            fallback={
              <div className="project-card p-4" style={{ height: "600px" }}>
                <div className="space-y-3">
                  {[...Array(5)].map((_, i) => (
                    <TaskCardSkeleton key={i} />
                  ))}
                </div>
              </div>
            }
          >
            <div className="project-card p-4" style={{ height: "600px" }}>
              <div className="tasks-scroll-container">
                {!tasksData?.data ? (
                  <div className="space-y-3">
                    {[...Array(5)].map((_, i) => (
                      <TaskCardSkeleton key={i} />
                    ))}
                  </div>
                ) : groupedTasks.today.length > 0 ? (
                  groupedTasks.today.map(renderTaskCard)
                ) : (
                  <div className="flex flex-col items-center justify-center h-full text-center p-4">
                    <NoTasks />
                    <h3 className="text-gray-300 text-lg font-medium mb-2">
                      No Tasks for Today
                    </h3>
                    <p className="text-gray-400 text-sm max-w-sm">
                      Looks like you're all caught up! Create a new task to get
                      started.
                    </p>
                    <Button
                      variety="outlined"
                      onClick={() => handleCreateTask()}
                      className="mt-4"
                    >
                      Create New Task
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Suspense>
        </section>

        {/* Today's Calendar Events Column */}
        <section>
          <div className="flex justify-between items-center mb-4 text-lg md:text-xl lg:text-2xl text-white cursor-pointer">
            <h2
              onClick={() => handleEventClick()}
              className="text-lg md:text-xl lg:text-2xl text-white"
            >
              Today's Events ({calendarEvents?.length || 0})
            </h2>
            <div
              variety="icon"
              onClick={handleCreateEvent}
              className="w-12 h-12 rounded-full bg-gray-800 hover:bg-gray-700 items-center flex justify-center cursor-pointer"
            >
              <PlusIcon className="w-5 h-5" />
            </div>
          </div>
          <Suspense fallback={<CalendarSkeleton />}>
            <div className="project-card p-4" style={{ height: "600px" }}>
              {!dashboardData?.data ? (
                <CalendarSkeleton />
              ) : (
                <Calendar
                  ref={calendarRef}
                  localizer={localizer}
                  events={calendarEvents}
                  startAccessor="start"
                  endAccessor="end"
                  defaultView="day"
                  views={["day"]}
                  defaultDate={new Date()}
                  eventPropGetter={eventStyleGetter}
                  onSelectEvent={(event) => {
                    dispatch(dashboardActions.setSelectedData(event.resource));
                    dispatch(
                      dashboardActions.setModal({
                        isModalOpen: true,
                        modalTitle: "Event Details",
                        modalContentType: "Event",
                      })
                    );
                  }}
                  style={{ height: "550px" }}
                  className="calendar-dark"
                  step={30}
                  timeslots={2}
                  min={moment().startOf("day").toDate()}
                  max={moment().endOf("day").toDate()}
                />
              )}
            </div>
          </Suspense>
        </section>
      </div>

      {/* Modals */}
      <Suspense
        fallback={
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
            <div className="bg-background-primary p-6 rounded-lg w-96">
              <StatsSkeleton />
            </div>
          </div>
        }
      >
        {/* Modal for viewing details */}
        <Modal
          isOpen={state.modals.isModalOpen}
          onClose={() =>
            dispatch(dashboardActions.setModal({ isModalOpen: false }))
          }
          title={state.modals.modalTitle}
          handleMark={handleMarkAsComplete}
          completed={state.data.selectedData?.completed}
          status={state.data.selectedData?.status}
          handleDeleteTask={handleDelete}
          isEditButton={true}
          onEditClick={() => {
            dispatch(
              dashboardActions.setModal({
                isModalOpen: false,
                isCreateModalOpen: true,
                modalTitle: `Edit ${state.modals.modalContentType}`,
                isEdit: true,
              })
            );
          }}
          completionStatus={true}
        >
          <ModalViewDetails
            selectedEvent={state.data.selectedData}
            setSelectedEvent={(event) =>
              dispatch(dashboardActions.setSelectedData(event))
            }
            contentType={state.modals.modalContentType}
            completed={projectData?.data?.filter((project) =>
              project.tasksList?.every((task) => task.completed)
            )}
            patchTask={patchTask}
            handleTaskClick={handleViewTask}
            data={projectData?.data}
            setData={(data) => dispatch(dashboardActions.setData(data))}
            onDragEnd={onDragEnd}
          />
        </Modal>
        {/* Create/Edit Modal */}
        <CreateModal
          isOpen={state.modals.isCreateModalOpen}
          onClose={() => {
            dispatch(dashboardActions.setModal({ isCreateModalOpen: false }));
            reset();
          }}
          title={state.modals.modalTitle}
          className={"w-2/5"}
        >
          {!state.data.selectedData ? (
            <StatsSkeleton />
          ) : state.modals.modalContentType === "Project" ? (
            <CreateProjectForm
              setModal={(isCreateModalOpen) =>
                dispatch(dashboardActions.setModal({ isCreateModalOpen }))
              }
              refetch={refetchProjectData}
              isEdit={state.ui.isEdit}
              selectedData={state.data.selectedData}
            />
          ) : state.modals.modalContentType === "Task" ? (
            <CreateTaskForm
              setModal={(isCreateModalOpen) =>
                dispatch(dashboardActions.setModal({ isCreateModalOpen }))
              }
              selectedData={state.data.selectedData}
              isEdit={state.ui.isEdit}
              refetch={() => {
                dispatch(
                  dashboardActions.setModal({ isCreateModalOpen: false })
                );
                refetchDashboardData();
                refetchProjectData();
                refetchTaskData();
              }}
              setJustUpdated={(justUpdated) =>
                dispatch(dashboardActions.setJustUpdated(justUpdated))
              }
              projectData={projectData?.data}
            />
          ) : state.modals.modalContentType === "Event" ? (
            <CreateEventForm
              onSubmit={onEventSubmit}
              control={control}
              errors={errors}
              register={register}
            />
          ) : null}
        </CreateModal>

        {/* Confirmation Dialog */}
        <Dialog
          show={state.modals.confirmationDialog}
          action1ButtonLabel={"Cancel"}
          action2ButtonLabel={"Delete"}
          handelAction1={() =>
            dispatch(dashboardActions.setModal({ confirmationDialog: false }))
          }
          handelAction2={handleDeleteConfirmation}
          isLoading={state.ui.isButtonLoading}
        >
          {state.ui.isButtonLoading ? (
            <div className="flex justify-center">
              <StatsSkeleton />
            </div>
          ) : (
            <div className="flex-col justify-center align-middle">
              <div
                style={{
                  background: "rgba(37, 31, 52, 1)",
                  width: 100,
                  height: 100,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: 20,
                  left: "40%",
                }}
              >
                <DeleteOutlined size={72} />
              </div>
              <div className="pt-28">
                <h3 className="white text-align-center">
                  You are about to delete a{" "}
                  {state.modals.modalContentType?.toLowerCase()}
                </h3>
                <h5 className="white text-align-center">
                  This will delete your{" "}
                  {state.modals.modalContentType?.toLowerCase()} permanently.
                </h5>
              </div>
            </div>
          )}
        </Dialog>

        {/* Modal for event details */}
        <Modal
          isOpen={state.modals.isPopupOpen}
          onClose={() =>
            dispatch(dashboardActions.setModal({ isPopupOpen: false }))
          }
          title={"Task Details"}
          handleDeleteTask={deleteTask}
          isEditButton={true}
          completionStatus={false}
          onEditClick={() => {
            dispatch(dashboardActions.setModal({ isPopupOpen: false }));
            dispatch(dashboardActions.setModal({ isCreateModalOpen: true }));
            dispatch(dashboardActions.setModal({ modalTitle: "Edit Task" }));
            dispatch(dashboardActions.setModal({ isEdit: true }));
          }}
        >
          <ModalViewDetails
            selectedEvent={state.data.selectedEvent}
            refetchDashboardData={refetchDashboardData}
          />
        </Modal>
      </Suspense>
    </div>
  );
};

export default DashboardOverview;
