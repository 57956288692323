import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ModalViewDetails } from "../Dashboard/ModalView";
import Modal from "../Modal/Modal";
import CustomToolbar from "../Toolbar/CustomToolbar";
import "./MyCalendar.css";
import { ListViewComponent } from "../ListView/ListViewComponent";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  useMutationPatch,
  useMutationPatchEvent,
} from "src/hooks/crud/mutation.patch";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { notify } from "../Toast/ToastComponent";
import Dialog from "../Dialog/Dialog";
import { CalendarIcon } from "@radix-ui/react-icons";

const localizer = momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar);

// Add this helper function before the MyCalendar component
const getEventColor = (status) => {
  const colors = {
    pending: "#f59e0b",
    confirmed: "#10b981",
    cancelled: "#ef4444",
    inProgress: "rgba(68, 115, 218, 0.4) !important",
    completed: "#059669",
    default: "rgba(68, 115, 218, 0.4) !important",
  };
  return colors[status?.toLowerCase()] || colors.default;
};

// Add this helper function for timezone conversion
const convertToLocalTime = (utcDate) => {
  return moment.utc(utcDate).local();
};

const convertToUTC = (localDate) => {
  return moment(localDate).utc();
};

// Update the isAllDayEvent helper function
const isAllDayEvent = (start, end) => {
  // Check if the dates are in YYYY-MM-DD format (Google Calendar style)
  const isDateOnlyFormat = (date) => {
    return /^\d{4}-\d{2}-\d{2}$/.test(date);
  };

  // If either start or end is in date-only format, it's an all-day event
  if (typeof start === "string" && typeof end === "string") {
    if (isDateOnlyFormat(start) || isDateOnlyFormat(end)) {
      return true;
    }
  }

  // Check for midnight-to-midnight events
  const startMoment = moment(start);
  const endMoment = moment(end);

  return (
    startMoment.hours() === 0 &&
    startMoment.minutes() === 0 &&
    endMoment.hours() === 0 &&
    endMoment.minutes() === 0 &&
    endMoment.diff(startMoment, "days") >= 1
  );
};

const MyCalendar = ({
  deleteTask,
  setIsModalOpen,
  isEdit,
  setIsEdit,
  setIsCreateModalOpen,
  setModalTitle,
  data,
  isTask,
  selectedTab,
  selectedData,
  setSelectedData,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [view, setView] = useState(() => {
    return localStorage.getItem("calendarView") || "month";
  });
  const [isTodaySelected, setIsTodaySelected] = useState(false); // New state variable

  const [localEvents, setLocalEvents] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [pendingEventUpdate, setPendingEventUpdate] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  // update the event
  const { mutate: updateEvent } = useMutationPatchEvent(API_END_POINTS.events);

  const [showMorePopup, setShowMorePopup] = useState(null);

  // Update the events formatting with enhanced all-day detection
  useEffect(() => {
    console.log("Raw data:", data); // Debug log

    const formattedEvents = data
      ?.filter((event) => {
        // Add validation checks
        if (!event?.title) {
          console.log("Skipping event without title:", event);
          return false;
        }
        if (!event?.startDateTime || !event?.endDateTime) {
          console.log("Skipping event without dates:", event);
          return false;
        }
        return true;
      })
      ?.map((task) => {
        // Check if it's a Google Calendar event with date-only format
        const isGoogleAllDay =
          task.isGoogleEvent &&
          task.calendarData?.start?.date &&
          task.calendarData?.end?.date;

        // Use date-only values if available, otherwise use datetime
        const startDate = isGoogleAllDay
          ? moment(task.calendarData.start.date)
          : convertToLocalTime(task?.startDateTime);

        const endDate = isGoogleAllDay
          ? moment(task.calendarData.end.date)
          : convertToLocalTime(task?.endDateTime);

        // Check if this should be an all-day event
        const shouldBeAllDay =
          isGoogleAllDay || isAllDayEvent(startDate, endDate);

        console.log("Processing event:", {
          title: task.title,
          start: startDate.format(),
          end: endDate.format(),
          allDay: shouldBeAllDay,
          isGoogleAllDay,
        });

        return {
          id: task._id,
          title: task.title,
          start: startDate.toDate(),
          end: endDate.toDate(),
          allDay: shouldBeAllDay,
          resource: task,
        };
      });

    setLocalEvents(formattedEvents || []);
  }, [data]);

  const moveEvent = (eventDetails) => {
    setPendingEventUpdate({
      type: "move",
      details: {
        ...eventDetails,
        start: convertToUTC(eventDetails.start).toDate(),
        end: convertToUTC(eventDetails.end).toDate(),
      },
    });
    setConfirmationDialog(true);
  };

  const resizeEvent = (eventDetails) => {
    setPendingEventUpdate({
      type: "resize",
      details: {
        ...eventDetails,
        start: convertToUTC(eventDetails.start).toDate(),
        end: convertToUTC(eventDetails.end).toDate(),
      },
    });
    setConfirmationDialog(true);
  };

  const handleConfirmation = () => {
    setIsButtonLoading(true);
    const { type, details } = pendingEventUpdate;

    if (type === "move") {
      const { event, start, end, isAllDay } = details;
      const updatedStart = isAllDay
        ? convertToUTC(moment(start).startOf("day")).toDate()
        : convertToUTC(start).toDate();
      const updatedEnd = isAllDay
        ? convertToUTC(moment(end).endOf("day")).toDate()
        : convertToUTC(end).toDate();

      setLocalEvents((prevEvents) => {
        const idx = prevEvents.findIndex((e) => e.id === event.id);
        const updatedEvent = {
          ...event,
          start: convertToLocalTime(updatedStart).toDate(),
          end: convertToLocalTime(updatedEnd).toDate(),
          allDay: isAllDay,
        };

        const nextEvents = [...prevEvents];
        nextEvents.splice(idx, 1, updatedEvent);

        updateEvent(
          {
            ...updatedEvent,
            startDateTime: updatedStart,
            endDateTime: updatedEnd,
          },
          event.id,
          {
            onSuccess: () => {
              notify("Event updated successfully", "success");
              setConfirmationDialog(false);
              setIsButtonLoading(false);
            },
            onError: () => {
              notify("Error updating event", "error");
              setConfirmationDialog(false);
              setIsButtonLoading(false);
            },
          }
        );
        return nextEvents;
      });
    } else if (type === "resize") {
      const { event, start, end } = details;
      setLocalEvents((prevEvents) => {
        const idx = prevEvents.findIndex((e) => e.id === event.id);
        const updatedEvent = { ...event, start, end };

        updateEvent(
          {
            ...updatedEvent,
            startDateTime: start,
            endDateTime: end,
          },
          event.id,
          {
            onSuccess: () => {
              notify("Event updated successfully", "success");
              setConfirmationDialog(false);
              setIsButtonLoading(false);
            },
            onError: () => {
              notify("Error updating event", "error");
              setConfirmationDialog(false);
              setIsButtonLoading(false);
            },
          }
        );

        const nextEvents = [...prevEvents];
        nextEvents.splice(idx, 1, updatedEvent);
        return nextEvents;
      });
    }
  };

  // Enhanced event styling with status colors
  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: getEventColor(event.resource?.status),
      borderRadius: "4px",
      opacity: 0.8,
      border: "none",
      color: "#fff",
      display: "block",
    };
    return { style };
  };

  // Enhanced EventComponent with more professional layout
  const EventComponent = ({ event }) => {
    return (
      <div className="calendar-event-wrapper status-${event.resource?.status || 'default'}">
        <div className="event-content">
          <div
            className="event-title"
            title={event.title}
            style={{
              // padding: "4px 8px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {event.title.split("•")[0].trim()} •{" "}
            {moment(event.start).format("h:mm A") +
              " - " +
              moment(event.end).format("h:mm A")}
          </div>
        </div>
      </div>
    );
  };

  // Add custom date header format
  const formats = {
    dateFormat: "D",
    dayFormat: "ddd DD/MM",
    dayHeaderFormat: "dddd, MMMM D",
    dayRangeHeaderFormat: ({ start, end }) =>
      `${moment(start).format("MMMM D")} - ${moment(end).format(
        "MMMM D, YYYY"
      )}`,
  };

  // Enhanced slot selection with business hours validation
  const handleSelect = ({ start, end, action }) => {
    const isBusinessHours = (time) => {
      const hour = moment(time).hour();
      return hour >= 9 && hour <= 17; // 9 AM to 5 PM
    };

    // if (action === "click" || action === "select") {
    //   if (!isBusinessHours(start) || !isBusinessHours(end)) {
    //     notify(
    //       "Please select time within business hours (9 AM - 5 PM)",
    //       "warning"
    //     );
    //     return;
    //   }
    // }

    const today = moment().startOf("day");
    if (moment(start).isSame(today, "d")) {
      setIsTodaySelected(true);
    } else {
      setIsTodaySelected(false);
    }
  };

  // Update localStorage when view changes
  const handleViewChange = (newView) => {
    setView(newView);
    localStorage.setItem("calendarView", newView);
  };

  const handleEventClick = (event) => {
    // Hide any open overlays
    const overlays = document.querySelectorAll(".rbc-overlay");
    overlays.forEach((overlay) => {
      overlay.style.display = "none";
    });

    // Then proceed with opening the event details
    setSelectedData(event.resource);
    setSelectedEvent(event);
    setIsPopupOpen(true);
    setShowMorePopup(null);
  };

  // Add debug logging to dayLayoutAlgorithm
  const dayLayoutAlgorithm = (
    events,
    minimumStartDiff,
    slotMetrics,
    accessors
  ) => {
    console.log("dayLayoutAlgorithm events:", events);
    const nonAllDayEvents = events?.filter((event) => !event.allDay) || [];
    console.log("nonAllDayEvents:", nonAllDayEvents);
    return Calendar.defaultProps.dayLayoutAlgorithm(
      nonAllDayEvents,
      minimumStartDiff,
      slotMetrics,
      accessors
    );
  };

  // Log when localEvents changes
  useEffect(() => {
    console.log("localEvents updated:", localEvents);
  }, [localEvents]);

  // Add useEffect for auto-scrolling to current time - only in day view
  // useEffect(() => {
  //   // Only auto-scroll in day view
  //   if (view !== "day") return;

  //   const scrollToCurrentTime = () => {
  //     const scrollContainer = document.querySelector(".rbc-time-content");
  //     if (scrollContainer) {
  //       const currentHour = new Date().getHours();
  //       const hourHeight = scrollContainer.scrollHeight / 24;
  //       const scrollTo = (currentHour - 1) * hourHeight;

  //       scrollContainer.scrollTop = scrollTo;
  //     }
  //   };

  //   const timeoutId = setTimeout(scrollToCurrentTime, 300);

  //   return () => clearTimeout(timeoutId);
  // }, [localEvents, view]); // Re-run when events or view changes

  // Add debug logging to see if the function is being called
  const handleShowMore = (events, date) => {
    // Just let the default popup behavior work
    return true;
  };

  const ShowMorePopup = ({ events, date, onClose, position }) => {
    return (
      <div
        className="show-more-popup"
        style={{
          position: "fixed",
          zIndex: 1000,
          backgroundColor: "var(--background)",
          border: "1px solid var(--border)",
          borderRadius: "8px",
          padding: "12px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          maxHeight: "300px",
          overflowY: "auto",
          width: "300px",
          left: position?.left || "50%",
          top: position?.top || "50%",
          transform: position ? "none" : "translate(-50%, -50%)",
        }}
      >
        <div className="popup-header" style={{ marginBottom: "8px" }}>
          <h4>{moment(date).format("MMMM D, YYYY")}</h4>
          <button
            onClick={onClose}
            style={{
              position: "absolute",
              right: "12px",
              top: "12px",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            ×
          </button>
        </div>
        <div className="popup-events">
          {events.map((event, index) => (
            <div
              key={index}
              onClick={() => handleEventClick(event)}
              style={{
                padding: "8px",
                margin: "4px 0",
                cursor: "pointer",
                borderRadius: "4px",
                backgroundColor: getEventColor(event.resource?.status),
                color: "#fff",
              }}
            >
              <div style={{ fontWeight: "bold" }}>{event.title}</div>
              <div style={{ fontSize: "0.9em" }}>
                {moment(event.start).format("h:mm A")} -{" "}
                {moment(event.end).format("h:mm A")}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="calendar-container">
      {isTodaySelected ? (
        <ListViewComponent />
      ) : (
        <>
          <DnDCalendar
            onSelectSlot={handleSelect}
            className={`${view === "day" ? "rbc-day-view" : ""} ${
              isTask && (view === "day" || view === "week")
                ? "hide-time-gutter"
                : ""
            } highlight-business-hours`}
            localizer={localizer}
            defaultDate={new Date()}
            defaultView={view}
            events={localEvents}
            startAccessor="start"
            endAccessor="end"
            style={{
              height: "85vh",
              background: "transparent",
            }}
            onSelectEvent={handleEventClick}
            onView={handleViewChange}
            onShowMore={handleShowMore}
            components={{
              toolbar: CustomToolbar,
              event: EventComponent,
              timeGutterHeader: () => (
                <div className="time-gutter-header">
                  <div>{moment().format("z")}</div>
                  <div>UTC</div>
                </div>
              ),
              popup: ({ events, position, accessors, onClose }) => (
                <div className="rbc-overlay bg-[var(--App-Background)] border border-[var(--border)] rounded-lg p-4 shadow-lg min-w-[300px]">
                  <div className="rbc-overlay-header">
                    {events.length} Events
                  </div>
                  {events.map((event, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        handleEventClick(event);
                        onClose();
                      }}
                      className="rbc-overlay-event"
                    >
                      <div>{accessors.title(event)}</div>
                      <div>
                        {moment(event.start).format("h:mm A")} -{" "}
                        {moment(event.end).format("h:mm A")}
                      </div>
                    </div>
                  ))}
                </div>
              ),
            }}
            selectable
            resizable
            step={30}
            timeslots={2}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            resizableAccessor={() => true}
            draggableAccessor={() => true}
            popup
            popupOffset={{ x: 30, y: 30 }}
            tooltipAccessor={(event) =>
              `${event.title}\n${moment(event.start).format(
                "h:mm A"
              )} - ${moment(event.end).format("h:mm A")}`
            }
            // showMultiDayTimes
            longPressThreshold={250}
            min={moment().startOf("day").toDate()}
            max={moment().endOf("day").toDate()}
            scrollToTime={new Date(new Date().setHours(7, 0, 0))}
            eventPropGetter={eventStyleGetter}
            formats={{
              ...formats,
              eventTimeRangeFormat: () => "", // This only hides the time in events
            }}
            doShowMoreDrillDown={true}
          />
          {/* {showMorePopup && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 999,
              }}
              onClick={() => setShowMorePopup(null)}
            >
              <ShowMorePopup
                events={showMorePopup.events}
                date={showMorePopup.date}
                position={showMorePopup.position}
                onClose={() => setShowMorePopup(null)}
              />
            </div>
          )} */}
        </>
      )}
      <Modal
        isOpen={isPopupOpen}
        // className={"card-app-background align-center"}
        onClose={() => setIsPopupOpen(false)}
        title={"Task Details"}
        handleDeleteTask={deleteTask}
        isEditButton={true}
        completionStatus={false}
        onEditClick={() => {
          setIsPopupOpen(false);
          setIsCreateModalOpen(true);
          setModalTitle("Edit Event");
          setIsEdit(true);
        }}
      >
        <ModalViewDetails selectedEvent={selectedEvent} />
      </Modal>
      <Dialog
        show={confirmationDialog}
        action1ButtonLabel={"Cancel"}
        action2ButtonLabel={"Update"}
        handelAction1={() => setConfirmationDialog(false)}
        handelAction2={handleConfirmation}
        isLoading={isButtonLoading}
      >
        <div className="flex-col justify-center align-middle">
          <div
            style={{
              background: "rgba(37, 31, 52, 1)",
              width: 100,
              height: 100,
              borderRadius: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 20,
              left: "40%",
            }}
          >
            <CalendarIcon color="white" style={{ width: 72, height: 72 }} />
          </div>
          <div className="pt-28">
            <h3 className="white text-align-center">Update Event</h3>
            <h5 className="white text-align-center">
              Are you sure you want to update this event's schedule?
            </h5>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MyCalendar;
