import { BadgeIcon } from "src/assets/svg/BadgeIcon";
import { BrandingBadges } from "src/assets/svg/BrandingBadges";
import { Level1Icon } from "src/assets/svg/LevelIcon";
import OnboardingBadge from "src/assets/svg/OnboardingBadge";
import Button from "src/components/Button";
import LevelProgress from "src/components/LevelProgress/LevelProgress";
import Speedometer from "src/components/Speedometer/Speedometer";
import { StatCard } from "src/components/StatsCard/StatCard";
import { ActivityMetrics, StatsCardData } from "src/constants/constant";
import { useState, useEffect } from "react";
import OnboardingModal from "src/components/OnboardingModal/OnboardingModal";
import { EmptyGoals } from "src/assets/svg/EmptyGoals";
import QuestionnaireModal from "src/components/QuestionnaireModal/QuestionnaireModal";
// import { questionnaireData } from "src/data/questionnaireData";
import ProgressionBadges from "src/components/ProgressionBadges/ProgressionBadges";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { useDataQuery } from "src/hooks/crud/data.query";
import { useMutationPatch } from "src/hooks/crud/mutation.patch";
import { useMutationCreate } from "src/hooks/crud/mutation.create";
import LevelUpModal from "src/components/LevelUpModal";
import { SpotifyIcon } from "src/assets/svg/SpotifyIcon";
import { InstagramIcon } from "src/assets/svg/InstagramIcon";
import { AddPreIcon } from "src/assets/svg/AddPreIcon";
import SocialSpeedometer from "src/assets/svg/SocialSpeedometer";
import Dialog from "src/components/Dialog";
import BuzzFactorySkeleton from "src/components/Skeleton/BuzzFactorySkeleton";
import CTACardList from "src/components/CTACard/CTACardList";
import { APP_ROUTES } from "../../utils/app-routes";

const LevelTwoCTAs = [
  {
    id: 1,
    title: "Post Multiple Pieces of Content Weekly",
    description: "Optimally post about 3-10 post per week.",
    buttonText: "Schedule a post",
    buttonAction: () => {
      console.log("buttonAction");
    },
    indicatorColor: "#7C3AED",
    buttonColor: "#38B2AC",
  },
  {
    id: 2,
    title: "Maintain a Regular Release Schedule",
    description: "Drop track consistently over the course of six months.",
    buttonText: "Create a task",
    buttonAction: () => (window.location.href = APP_ROUTES.eventAndTasks),
    indicatorColor: "#7C3AED",
    buttonColor: "#38B2AC",
  },
];

const LevelThreeCTAs = [
  {
    id: 1,
    title: "Review your analytics to know what worked.",
    description:
      "Analyze what works best in music/content, then amplify successful approaches.",
    buttonText: "View Analytics",
    buttonAction: () => {
      console.log("buttonAction");
    },
    indicatorColor: "#7C3AED",
    buttonColor: "#38B2AC",
  },
];

const BuzzFactory = () => {
  const [showTutorial, setShowTutorial] = useState(false);
  const [goalsSet, setGoalsSet] = useState(false);
  const [showGoalsQuestionnaire, setShowGoalsQuestionnaire] = useState(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [questionnaireProgress, setQuestionnaireProgress] = useState(0);
  const [showLevelUpModal, setShowLevelUpModal] = useState(false);
  const [currentLevel, setCurrentLevel] = useState({});
  const [questionnaireData, setQuestionnaireData] = useState(null);
  const [socialsLinked, setSocialsLinked] = useState(false);
  const [showSkipConfirmation, setShowSkipConfirmation] = useState(false);

  //gamification level information
  const {
    data: gamificationLevel,
    isLoading: isGamificationLevelLoading,
    refetch: refetchGamificationLevel,
  } = useDataQuery({
    url: API_END_POINTS.gamification + "/progress",
  });

  // Get social overview data
  const { data: socialOverviewData, isLoading: isSocialOverviewLoading } =
    useDataQuery({
      url: API_END_POINTS.socialOverview,
      params: {},
    });

  // Get profile data to check if tutorial has been visited
  const { data: profileData, isLoading: isProfileLoading } = useDataQuery({
    url: API_END_POINTS.profile,
  });

  const { data: questionnaireDataFromApi, isLoading: isQuestionnaireLoading } =
    useDataQuery({
      url: API_END_POINTS.questionnaire,
    });

  // Get user profile data with badges
  const { data: userProfileData, isLoading: isUserProfileLoading } =
    useDataQuery({
      url: API_END_POINTS.profile,
      params: {},
    });

  const { mutate: updateProfile } = useMutationPatch(API_END_POINTS.profile);
  const { mutate: submitQuestionnaire } = useMutationCreate(
    API_END_POINTS.questionnaire
  );

  // Check if any of the data is still loading
  const isLoading =
    isSocialOverviewLoading ||
    isGamificationLevelLoading ||
    isProfileLoading ||
    isQuestionnaireLoading ||
    isUserProfileLoading;

  useEffect(() => {
    // Check if both Instagram and Spotify are linked from profile data
    if (socialOverviewData?.data) {
      const platforms = socialOverviewData.data.filter(
        (platform) => platform.isActive
      );
      const instagramLinked = platforms.some(
        (p) => p.platformName.toLowerCase() === "instagram" && p.isLinked
      );
      const spotifyLinked = platforms.some(
        (p) => p.platformName.toLowerCase() === "spotify" && p.isLinked
      );
      setSocialsLinked(instagramLinked && spotifyLinked);
    }
  }, [socialOverviewData]);

  useEffect(() => {
    // Show tutorial if isBuzzVisited is false or undefined
    if (profileData?.data && !profileData.data.isBuzzVisited) {
      setShowTutorial(true);
    }
  }, [profileData]);

  useEffect(() => {
    if (gamificationLevel?.data) {
      setCurrentLevel(gamificationLevel?.data?.levelInfo);
      setQuestionnaireProgress(gamificationLevel.data?.buzzProgress);
      if (gamificationLevel.data?.buzzProgress >= 1) {
        setGoalsSet(true);
      } else {
        setGoalsSet(false);
      }
    }
  }, [gamificationLevel]);

  useEffect(() => {
    if (
      gamificationLevel?.data?.buzzProgress ===
      questionnaireData?.sections?.length
    ) {
      console.log("level up");
      setShowLevelUpModal(true);
      // setCurrentLevel(2);
    }
  }, [gamificationLevel, questionnaireData]);

  useEffect(() => {
    if (questionnaireDataFromApi?.data) {
      setQuestionnaireData(questionnaireDataFromApi.data);
    }
  }, [questionnaireDataFromApi]);

  const handleCloseTutorial = () => {
    updateProfile(
      { isBuzzVisited: true },
      {
        onSuccess: () => {
          setShowTutorial(false);
        },
        onError: (error) => {
          console.error("Failed to update profile:", error);
          setShowTutorial(false);
        },
      }
    );
  };

  const handleQuestionnaireComplete = () => {
    setGoalsSet(true);
    setShowGoalsQuestionnaire(false);

    if (questionnaireProgress >= 5) {
      setCurrentLevel(2);

      // updateProfile(
      //   { level: 2 },
      //   {
      //     onError: (error) => {
      //       console.error("Failed to update user level:", error);
      //     },
      //   }
      // );
    }

    refetchGamificationLevel();
  };

  const handleStartQuestionnaire = () => {
    setShowGoalsQuestionnaire(true);
    setCurrentSectionIndex(0);
  };

  const handleSkipQuestionnaire = () => {
    setShowGoalsQuestionnaire(false);
  };

  const handleCloseLevelUpModal = () => {
    setShowLevelUpModal(false);
  };

  // Show skeleton loader while data is loading
  if (isLoading) {
    return <BuzzFactorySkeleton />;
  }
  console.log("cirrentr level", currentLevel?.currentLevel);
  return (
    <div>
      <span className="gray-400 regularL">
        Build up your career and create your BUZZ!
      </span>
      {/* Main Content Container */}
      <div className="flex min-h-screen">
        {/* Left Side - Buzz Factory Card */}
        <div className="p-6 w-2/3">
          {/* Progress Track */}
          <LevelProgress currentLevel={currentLevel?.currentLevel} />

          {/* Tab Navigation */}
          <div className="flex gap-4 mb-6">
            <button className="px-4 py-2 bg-[#7C3AED] rounded-full text-white">
              Milestones/Metrics
            </button>
            <button className="px-4 py-2 bg-[#2D2D3D] rounded-full text-gray-300">
              Badges History
            </button>
          </div>

          {/* Main Content */}
          <div className="gap-6">
            <div className="space-y-6 p-8 bg-[#1F1B29] rounded-lg">
              <div className="flex items-center gap-4 mb-4">
                <div className="relative">
                  <div className="absolute inset-0 animate-sunburst">
                    <div className="absolute inset-0 bg-gradient-to-r from-gray-500/50 to-gray-500/50  blur-xl"></div>
                  </div>
                  <OnboardingBadge color="#fff" />
                </div>
                <div>
                  <div className="flex items-center gap-1">
                    <BadgeIcon />
                    <div className="text-sm text-[#9E9CAE]">
                      LEVEL {currentLevel?.currentLevel}
                    </div>
                  </div>
                  <h2 className="text-[26px] text-[#E6E6EA] font-bold">
                    {currentLevel?.currentLevelName ||
                      "Build Momentum & Consistency"}
                  </h2>
                </div>
              </div>
              {!socialsLinked ? (
                <div className="bg-[#262034] my-8 mx-5 p-[32px] rounded-2xl flex items-center justify-between">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="flex items-start gap-12">
                      {/* Left side with social icons */}
                      <div className="relative">
                        <SocialSpeedometer />
                      </div>

                      {/* Right side content */}
                      <div className="flex-1">
                        <h2 className="font-['IBM_Plex_Sans'] text-[20px] leading-[100%] tracking-[0px] font-bold text-white mb-4">
                          Connect your Socials
                        </h2>
                        <p className="font-['IBM_Plex_Sans'] text-[14px] leading-[100%] tracking-[0px] font-normal text-[#E6E6EA] mb-8">
                          Connect your Instagram, Spotify or Soundcloud to track
                          your follower and content release goals.
                        </p>
                        <Button
                          className="flex-row mediumM w-full sm:w-auto"
                          variety="filled"
                          height={"48px"}
                          onClick={() => (window.location.href = "/social")}
                        >
                          <AddPreIcon />
                          Connect Socials
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : !goalsSet ? (
                <div className="bg-[#262034] my-8 mx-5 p-[32px] rounded-2xl flex items-center justify-between">
                  <div className="w-1/2">
                    <h4 className="text-xl text-white font-bold mb-3 flex items-center gap-2">
                      Set your goals.
                    </h4>
                    <p className="text-[#E6E6EA] text-sm">
                      Answer some questions to define your overall goals to
                      allow our system to setup perfect trackers to track your
                      growth.
                    </p>
                    <Button
                      variety="filled"
                      className="rounded-lg mt-[8px]"
                      onClick={handleStartQuestionnaire}
                    >
                      Set your goals
                    </Button>
                  </div>
                  <EmptyGoals />
                </div>
              ) : currentLevel?.currentLevel === 2 ? (
                /* Level 2 specific content */
                <div className="space-y-6">
                  {/* Task cards with CTAs - Now using reusable component */}
                  <CTACardList items={LevelTwoCTAs} className="mt-4" />

                  {/* Metrics/Stats Display */}
                  <div className="grid grid-cols-4 gap-4 mt-8">
                    {/* Content Published */}
                    <div className="text-center">
                      <Speedometer percentage={75} label="CONTENT PUBLISHED" />
                      <div className="mt-2">
                        <h3 className="text-white text-2xl font-bold">
                          12 Posts
                        </h3>
                        <p className="text-[#9E9CAE] text-xs">This week</p>
                      </div>
                    </div>

                    {/* Tracks Released */}
                    <div className="text-center">
                      <Speedometer percentage={50} label="TRACK RELEASED" />
                      <div className="mt-2">
                        <h3 className="text-white text-2xl font-bold">
                          2 Tracks
                        </h3>
                        <p className="text-[#9E9CAE] text-xs">This year</p>
                      </div>
                    </div>

                    {/* Appearances */}
                    <div className="text-center">
                      <Speedometer percentage={40} label="APPEARANCES" />
                      <div className="mt-2">
                        <h3 className="text-white text-2xl font-bold">2</h3>
                        <p className="text-[#9E9CAE] text-xs">This week</p>
                      </div>
                    </div>

                    {/* Outreach */}
                    <div className="text-center">
                      <Speedometer percentage={80} label="OUTREACH" />
                      <div className="mt-2">
                        <h3 className="text-white text-2xl font-bold">
                          4 Invitations
                        </h3>
                        <p className="text-[#9E9CAE] text-xs">This week</p>
                      </div>
                    </div>
                  </div>

                  {/* 6 Month Progress Bars */}
                  <div className="grid grid-cols-2 gap-8 mt-8">
                    {StatsCardData.map((item) => (
                      <StatCard key={item.label} {...item} />
                    ))}
                  </div>
                </div>
              ) : currentLevel?.currentLevel === 3 ? (
                <div className="space-y-6">
                  {/* Task cards with CTAs - Now using reusable component */}
                  <CTACardList items={LevelThreeCTAs} className="mt-4" />

                  {/* Metrics/Stats Display */}
                  <div className="grid grid-cols-4 gap-4 mt-8">
                    {/* Content Published */}
                    <div className="text-center">
                      <Speedometer percentage={75} label="CONTENT PUBLISHED" />
                      <div className="mt-2">
                        <h3 className="text-white text-2xl font-bold">
                          12 Posts
                        </h3>
                        <p className="text-[#9E9CAE] text-xs">This week</p>
                      </div>
                    </div>

                    {/* Tracks Released */}
                    <div className="text-center">
                      <Speedometer percentage={50} label="TRACK RELEASED" />
                      <div className="mt-2">
                        <h3 className="text-white text-2xl font-bold">
                          2 Tracks
                        </h3>
                        <p className="text-[#9E9CAE] text-xs">This year</p>
                      </div>
                    </div>

                    {/* Appearances */}
                    <div className="text-center">
                      <Speedometer percentage={40} label="APPEARANCES" />
                      <div className="mt-2">
                        <h3 className="text-white text-2xl font-bold">2</h3>
                        <p className="text-[#9E9CAE] text-xs">This week</p>
                      </div>
                    </div>

                    {/* Outreach */}
                    <div className="text-center">
                      <Speedometer percentage={80} label="OUTREACH" />
                      <div className="mt-2">
                        <h3 className="text-white text-2xl font-bold">
                          4 Invitations
                        </h3>
                        <p className="text-[#9E9CAE] text-xs">This week</p>
                      </div>
                    </div>
                  </div>

                  {/* 6 Month Progress Bars */}
                  <div className="grid grid-cols-2 gap-8 mt-8">
                    {StatsCardData.map((item) => (
                      <StatCard key={item.label} {...item} />
                    ))}
                  </div>
                </div>
              ) : (
                /* Left Column */
                <div className="space-y-6 p-5 bg-[#1F1B29]">
                  {/* Current Level Card */}
                  <div className="rounded-lg p-6">
                    <div className="flex items-center gap-3 mb-4 bg-[#262034] rounded-xl py-4 px-5 border-l-[10px] border-[--Genesis-Blue-200]">
                      <div className="flex -space-x-2">
                        {[1, 2, 3].map((item) => (
                          <div key={item} className="w-12 rounded-full ">
                            <BrandingBadges />
                          </div>
                        ))}
                      </div>
                      <p className="text-gray-300">
                        Define your brand through these questionnaires (
                        {questionnaireProgress !== undefined
                          ? questionnaireProgress
                          : 0}
                        /{questionnaireData?.sections?.length || 0}) and quickly
                        earn 1500 credits.
                      </p>
                      <Button
                        variety="filled"
                        onClick={() => {
                          setShowGoalsQuestionnaire(true);
                          setCurrentSectionIndex(
                            questionnaireProgress > 0
                              ? questionnaireProgress
                              : 0
                          );
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>

                  {/* Stats Grid */}
                  <div className="grid grid-cols-3 gap-4">
                    {StatsCardData.map((item) => (
                      <StatCard key={item.label} {...item} />
                    ))}
                  </div>

                  {/* Activity Metrics */}
                  <div className="grid grid-cols-4 gap-4 pt-8">
                    {ActivityMetrics.map((metric) => (
                      <div key={metric.label} className="p-4 rounded-lg">
                        <Speedometer {...metric} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Right Side - Progression Badges */}
        <div className="p-6 w-1/3">
          <ProgressionBadges
            earnedBadges={userProfileData?.data?.badges || []}
          />
        </div>
      </div>

      <OnboardingModal
        isOpen={showTutorial}
        onClose={handleCloseTutorial}
        icon={OnboardingBadge}
        position="bottom-right"
      />

      <QuestionnaireModal
        isOpen={showGoalsQuestionnaire}
        onClose={handleSkipQuestionnaire}
        sections={questionnaireData?.sections}
        currentSectionIndex={currentSectionIndex}
        onSubmit={handleQuestionnaireComplete}
        submitQuestionnaire={submitQuestionnaire}
        badgeIcon={BadgeIcon}
        refetchQuestionnaireProgress={refetchGamificationLevel}
        totalPoints={25}
      />

      {/* <LevelUpModal
        isOpen={showLevelUpModal}
        onClose={handleCloseLevelUpModal}
        level={currentLevel?.currentLevel}
      /> */}
    </div>
  );
};

export default BuzzFactory;
