import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { onboardingSteps } from "./onboardingSteps";
import { VALID_POSITIONS } from "./constants";
import {
  StepContent,
  StepIcon,
  StepIndicator,
  NavigationButtons,
  ModalWrapper,
  CompletionBadge,
} from "./components";
import styles from "./OnboardingModal.module.css";

/**
 * OnboardingModal component displays a step-by-step guide for new users
 *
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Handler for modal close
 * @param {number} props.badgeCount - Number of badges to display (if applicable)
 * @param {string} props.position - Position of the modal on screen
 * @returns {React.Component} OnboardingModal component
 */
const OnboardingModal = ({
  isOpen,
  onClose,
  badgeCount,
  position = "center",
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [showCompletionBadge, setShowCompletionBadge] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  // Reset the component state when it opens/closes
  useEffect(() => {
    if (isOpen) {
      setCurrentStep(0);
      setCompletedSteps([]);
      setShowCompletionBadge(false);
      setShowConfetti(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  // Determine position based on current step
  const currentPosition =
    currentStep === onboardingSteps.length - 1 ? "bottom-left" : position;

  /**
   * Advances to the next step or closes the modal on the final step
   * Shows completion badge when marking a step as complete
   */
  const handleNext = () => {
    // Mark the current step as completed
    setCompletedSteps([...completedSteps, currentStep]);
    setShowConfetti(true);

    // Hide confetti after animation completes
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 4000); // Match this with the CSS animation duration

    // If already completed, just move to next step without showing badge
    if (currentStep < onboardingSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }

    return () => clearTimeout(timer);
  };

  /**
   * Goes back to the previous step
   */
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const currentStepData = onboardingSteps[currentStep];
  const isFinalStep = Boolean(currentStepData.finalStep);
  const StepIconComponent = currentStepData.icon;

  return (
    <ModalWrapper onClose={onClose} position={currentPosition}>
      {/* Confetti overlay */}
      {showConfetti && (
        <img
          src="https://s3-alpha-sig.figma.com/img/2ad9/76df/29612f014bad04d4c3ac26dcaef52432?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=hru7EA5RWVfK8IPPfz4Inb~bd6jKVnWtt0tyTKpzcaKZ0SsuqDI0SRxg6PfTeyeTw5EkUQl27~AdBEsMoxLs-scFQvDQmbAFq57nKl8I3bmyouI81J1TSBwCJC9UFuxganV~dNe7A7t6TMIip3jITeYVaH-uX7sjkbS9mAkALuKa-DmWCy0paNAW83l0bUarQffzaxMhJPRPLUpe4qXQqM4J7gfDR1vxZHbzWJc~dWSJK7jmsqRsABZJDKjf60MFnN7AY7IO41KE0Q7ECuRsTD-ig0AUxihRJmobKUgXaYYKNQMlNY4sxSxJfSJFTnYTqH2eyg4wFs-dAhfN5sb--A__"
          alt="Confetti celebration"
          className={styles.modalConfettiOverlay}
        />
      )}

      <StepIcon
        Icon={currentStepData.icon}
        background={currentStepData.background}
      />

      <div className="p-8 pt-4 relative">
        <StepIndicator
          currentStep={currentStep}
          totalSteps={onboardingSteps.length}
        />

        <StepContent stepData={currentStepData} />

        <NavigationButtons
          currentStep={currentStep}
          isFinalStep={isFinalStep}
          onNext={handleNext}
          onBack={handleBack}
        />
      </div>
    </ModalWrapper>
  );
};

OnboardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  badgeCount: PropTypes.number,
  position: PropTypes.oneOf(VALID_POSITIONS),
};

OnboardingModal.defaultProps = {
  position: "center",
  badgeCount: 0,
};

export default OnboardingModal;
