export const BadgeIcon = (props) => {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_321_13122)">
        <mask
          id="mask0_321_13122"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="8"
          width="14"
          height="16"
        >
          <path
            d="M14.2453 8.43574C14.7123 8.1661 15.2877 8.1661 15.7547 8.43574L21.1735 11.5643C21.6405 11.8339 21.9282 12.3322 21.9282 12.8715V19.1285C21.9282 19.6678 21.6405 20.1661 21.1735 20.4357L15.7547 23.5643C15.2877 23.8339 14.7123 23.8339 14.2453 23.5643L8.82651 20.4357C8.35949 20.1661 8.0718 19.6678 8.0718 19.1285V12.8715C8.0718 12.3322 8.35949 11.8339 8.82651 11.5643L14.2453 8.43574Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_321_13122)">
          <path
            d="M13.7925 8.69714C14.2324 8.44316 14.4523 8.31617 14.6862 8.26646C14.8931 8.22248 15.1069 8.22248 15.3138 8.26646C15.5477 8.31617 15.7676 8.44316 16.2075 8.69714L20.7207 11.3028C21.1606 11.5568 21.3805 11.6838 21.5405 11.8614C21.682 12.0186 21.789 12.2038 21.8543 12.405C21.9282 12.6324 21.9282 12.8864 21.9282 13.3943V18.6056C21.9282 19.1136 21.9282 19.3676 21.8543 19.5949C21.789 19.7961 21.682 19.9813 21.5405 20.1385C21.3805 20.3162 21.1606 20.4432 20.7207 20.6971L16.2075 23.3028C15.7676 23.5568 15.5477 23.6838 15.3138 23.7335C15.1069 23.7774 14.8931 23.7774 14.6862 23.7335C14.4523 23.6838 14.2324 23.5568 13.7925 23.3028L9.27934 20.6971C8.83943 20.4432 8.61947 20.3162 8.4595 20.1385C8.31796 19.9813 8.21104 19.7961 8.14567 19.5949C8.0718 19.3676 8.0718 19.1136 8.0718 18.6056V13.3943C8.0718 12.8864 8.0718 12.6324 8.14567 12.405C8.21104 12.2038 8.31796 12.0186 8.4595 11.8614C8.61947 11.6838 8.83943 11.5568 9.27934 11.3028L13.7925 8.69714Z"
            fill="url(#paint0_linear_321_13122)"
          />
          <g filter="url(#filter1_d_321_13122)">
            <mask
              id="mask1_321_13122"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="8"
              y="8"
              width="14"
              height="16"
            >
              <path
                d="M13.7926 8.69715C14.2325 8.44316 14.4525 8.31617 14.6863 8.26646C14.8932 8.22248 15.1071 8.22248 15.314 8.26646C15.5478 8.31617 15.7678 8.44316 16.2077 8.69715L20.7208 11.3028C21.1607 11.5568 21.3807 11.6838 21.5406 11.8614C21.6822 12.0186 21.7891 12.2038 21.8545 12.405C21.9283 12.6324 21.9283 12.8864 21.9283 13.3943V18.6056C21.9283 19.1136 21.9283 19.3676 21.8545 19.5949C21.7891 19.7961 21.6822 19.9813 21.5406 20.1385C21.3807 20.3162 21.1607 20.4432 20.7208 20.6971L16.2077 23.3028C15.7678 23.5568 15.5478 23.6838 15.314 23.7335C15.1071 23.7775 14.8932 23.7775 14.6863 23.7335C14.4525 23.6838 14.2325 23.5568 13.7926 23.3028L9.27948 20.6971C8.83956 20.4432 8.61961 20.3162 8.45964 20.1385C8.31809 19.9813 8.21117 19.7961 8.14581 19.5949C8.07193 19.3676 8.07193 19.1136 8.07193 18.6056V13.3943C8.07193 12.8864 8.07193 12.6324 8.14581 12.405C8.21117 12.2038 8.31809 12.0186 8.45964 11.8614C8.61961 11.6838 8.83956 11.5568 9.27948 11.3028L13.7926 8.69715Z"
                fill="black"
              />
            </mask>
            <g mask="url(#mask1_321_13122)">
              <rect
                x="4.43408"
                y="5.43395"
                width="21.1321"
                height="21.1321"
                fill="#0B6F5E"
              />
              <path
                d="M4.43408 5.43395H25.5662V26.566H4.43408V5.43395Z"
                fill="url(#paint1_linear_321_13122)"
                fillOpacity="0.6"
                style={{ mixBlendMode: "overlay" }}
              />
              <path
                d="M4.43408 5.43395H25.5662V26.566H4.43408V5.43395Z"
                fill="url(#paint2_radial_321_13122)"
                fillOpacity="0.6"
                style={{ mixBlendMode: "overlay" }}
              />
              <g style={{ mixBlendMode: "soft-light" }}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.43408 5.43395H17.1133L13.0668 26.566H4.43408V5.43395Z"
                  fill="url(#paint3_linear_321_13122)"
                  style={{ mixBlendMode: "lighten" }}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_321_13122"
          x="0.524638"
          y="0.68634"
          width="28.9507"
          height="30.6273"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.77359" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.606568 0 0 0 0 0.539892 0 0 0 0 0.873271 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_321_13122"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_321_13122"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_321_13122"
          x="7.46816"
          y="7.78065"
          width="15.064"
          height="16.7405"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.150943" />
          <feGaussianBlur stdDeviation="0.301887" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="color-burn"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_321_13122"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_321_13122"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_321_13122"
          x1="20.3585"
          y1="20.4528"
          x2="9.03773"
          y2="11.8113"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.36" stop-color="#201D2C" />
          <stop offset="1" stop-color="#6A6092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_321_13122"
          x1="15.0001"
          y1="7.82855"
          x2="15.0001"
          y2="24.0619"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_321_13122"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.0001 12.4913) rotate(90) scale(10.7071 8.12368)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_321_13122"
          x1="4.43408"
          y1="5.43395"
          x2="4.43408"
          y2="26.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.135095" stop-color="white" />
          <stop offset="0.877908" stop-color="white" stop-opacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  );
};
