import React, { useState, useRef } from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import classNames from "classnames";
import Button from "../../Button";
import { format } from "date-fns";

// Helper components for question types
export const DateQuestion = ({
  question,
  value,
  onChange,
  isLastQuestion,
  showValidation,
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const formatDate = (date) => {
    if (!date) return null;
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleDateSelect = (date) => {
    const selectedDate = new Date(date);
    onChange(selectedDate.toISOString());
    setShowDatePicker(false);
  };

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const nextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1)
    );
  };

  const prevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1)
    );
  };

  const isEmpty = !value;
  const showError = showValidation && isEmpty;

  return (
    <div className="space-y-4">
      <div className="relative">
        <div
          className={classNames(
            "p-3 bg-[#262034] border rounded-lg text-white flex items-center cursor-pointer hover:border-[#7C3AED] transition-colors",
            showError ? "border-red-500" : "border-[#3D3A50]"
          )}
          onClick={() => setShowDatePicker(!showDatePicker)}
        >
          <CalendarIcon className="text-[#6F6C84] mr-2" />
          <span className={value ? "text-white" : "text-[#6F6C84]"}>
            {value ? formatDate(value) : "Select date"}
          </span>
          <div className="ml-auto">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="#6F6C84"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        {showDatePicker && (
          <div className="absolute z-10 mt-2 p-4 bg-[#262034] border border-[#3D3A50] rounded-lg shadow-xl w-[320px] backdrop-blur-sm">
            {/* Calendar Header with elegant gradient */}
            <div className="relative mb-6 pt-2">
              <div className="flex items-center justify-between">
                <button
                  onClick={prevMonth}
                  className="p-2 hover:bg-[#362C4E] rounded-lg transition-colors"
                >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M12 15L7 10L12 5"
                      stroke="#6F6C84"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="text-center">
                  <h3 className="text-white font-medium text-lg">
                    {currentMonth.toLocaleDateString("en-US", {
                      month: "long",
                    })}
                  </h3>
                  <p className="text-[#6F6C84] text-sm">
                    {currentMonth.getFullYear()}
                  </p>
                </div>
                <button
                  onClick={nextMonth}
                  className="p-2 hover:bg-[#362C4E] rounded-lg transition-colors"
                >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M8 5L13 10L8 15"
                      stroke="#6F6C84"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-[#7C3AED] to-transparent opacity-30" />
            </div>

            {/* Weekday Headers */}
            <div className="grid grid-cols-7 gap-1 mb-2">
              {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
                <div
                  key={day}
                  className="text-[#6F6C84] text-xs font-medium text-center py-2"
                >
                  {day}
                </div>
              ))}
            </div>

            {/* Calendar Days */}
            <div className="grid grid-cols-7 gap-1">
              {/* Empty cells for days before the first of the month */}
              {Array.from({ length: getFirstDayOfMonth(currentMonth) }).map(
                (_, index) => (
                  <div
                    key={`empty-${index}`}
                    className="h-9 flex items-center justify-center"
                  />
                )
              )}

              {/* Actual days */}
              {Array.from(
                { length: getDaysInMonth(currentMonth) },
                (_, i) => i + 1
              ).map((day) => {
                const date = new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth(),
                  day
                );
                const isSelected =
                  value && formatDate(value) === formatDate(date);
                const isToday = formatDate(date) === formatDate(new Date());

                return (
                  <div
                    key={day}
                    className="h-9 flex items-center justify-center"
                  >
                    <div
                      onClick={() => handleDateSelect(date)}
                      className={classNames(
                        "w-8 h-8 flex items-center justify-center rounded-full text-sm transition-all cursor-pointer",
                        "hover:bg-[#362C4E] hover:shadow-lg",
                        isSelected && [
                          "bg-[#7C3AED]",
                          "text-white",
                          "shadow-[0_0_0_2px_#1F1B29,_0_0_0_4px_#7C3AED]",
                          "scale-90",
                        ],
                        isToday &&
                          !isSelected &&
                          "border border-[#7C3AED] text-[#7C3AED]",
                        !isSelected && !isToday && "text-white"
                      )}
                    >
                      {day}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Today Button */}
            <div className="mt-4 pt-4 border-t border-[#3D3A50] flex justify-center">
              <button
                onClick={() => handleDateSelect(new Date())}
                className="px-4 py-2 text-sm text-[#7C3AED] hover:bg-[#362C4E] rounded-lg transition-colors"
              >
                Today
              </button>
            </div>
          </div>
        )}
      </div>

      {showError && (
        <p className="text-red-500 text-sm">Please select a date</p>
      )}

      {isLastQuestion && (
        <div className="mt-4 p-3 bg-[#332A49] border border-[#7C3AED] rounded-lg">
          <div className="flex items-center">
            <div className="bg-[#7C3AED] bg-opacity-20 p-2 rounded-full mr-3">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
                  fill="#7C3AED"
                />
              </svg>
            </div>
            <div>
              <h4 className="text-[#7C3AED] font-semibold text-sm">
                Final Question
              </h4>
              <p className="text-[#9E9CAE] text-xs">
                You've reached the end of the questionnaire!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const NumberQuestion = ({
  question,
  value,
  onChange,
  isLastQuestion,
  showValidation,
}) => {
  const handleNumberChange = (e) => {
    const newValue = e.target.value.trim() !== "" ? Number(e.target.value) : "";
    onChange(newValue);
  };

  const isEmpty = value === "" || value === undefined || value === null;
  const showError = showValidation && isEmpty;

  return (
    <div className="space-y-2">
      <input
        type="number"
        className={classNames(
          "w-full p-3 bg-[#262034] border rounded-lg text-white transition-colors",
          showError ? "border-red-500" : "border-[#3D3A50]"
        )}
        placeholder={question.placeholder || "Enter a number"}
        value={value || ""}
        onChange={handleNumberChange}
        min={question.min}
        max={question.max}
      />
      {showError && (
        <p className="text-red-500 text-sm">This field is required</p>
      )}
    </div>
  );
};

export const TextQuestion = ({
  question,
  value,
  onChange,
  isLastQuestion,
  showValidation,
}) => {
  const isEmpty = !value || value.trim() === "";
  const showError = showValidation && isEmpty;

  return (
    <div className="space-y-2">
      <textarea
        className={classNames(
          "w-full p-3 bg-[#262034] border rounded-lg text-white transition-colors",
          showError ? "border-red-500" : "border-[#3D3A50]"
        )}
        placeholder={question.placeholder || "Type your answer here..."}
        rows={4}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
      />
      {showError && (
        <p className="text-red-500 text-sm">This field is required</p>
      )}
    </div>
  );
};

export const ButtonsQuestion = ({
  question,
  value,
  onChange,
  isLastQuestion,
  showValidation,
}) => {
  const isEmpty = !value;
  const showError = showValidation && isEmpty;

  return (
    <div className="space-y-4">
      <div
        className={classNames(
          "flex gap-3",
          showError && "border border-red-500 rounded-lg p-3"
        )}
      >
        {(question.options || []).map((option) => (
          <Button
            key={option}
            variety={value === option ? "primary" : "outlined-primary"}
            className={classNames("flex-1", showError && "border-red-500")}
            onClick={() => onChange(option)}
          >
            {option}
          </Button>
        ))}
      </div>

      {showError && (
        <p className="text-red-500 text-sm">Please select an option</p>
      )}

      {isLastQuestion && (
        <div className="mt-4 p-3 bg-[#332A49] border border-[#7C3AED] rounded-lg">
          <div className="flex items-center">
            <div className="bg-[#7C3AED] bg-opacity-20 p-2 rounded-full mr-3">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
                  fill="#7C3AED"
                />
              </svg>
            </div>
            <div>
              <h4 className="text-[#7C3AED] font-semibold text-sm">
                Final Question
              </h4>
              <p className="text-[#9E9CAE] text-xs">
                You've reached the end of the questionnaire!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const SelectQuestion = ({
  question,
  value,
  onChange,
  isLastQuestion,
  showValidation,
}) => {
  const isEmpty = !value;
  const showError = showValidation && isEmpty;

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        {(question.options || []).map((option) => (
          <div
            key={option}
            className={classNames(
              "p-4 border rounded-lg transition-all cursor-pointer",
              "hover:border-[#7C3AED] hover:bg-[#332A49]",
              value === option
                ? "bg-[#332A49] border-[#7C3AED]"
                : showError
                ? "bg-[#262034] border-red-500"
                : "bg-[#262034] border-[#3D3A50]"
            )}
            onClick={() => onChange(option)}
          >
            <div className="flex items-center">
              <div
                className={classNames(
                  "w-5 h-5 rounded-full border mr-3 flex items-center justify-center",
                  value === option
                    ? "border-[#7C3AED] bg-[#7C3AED]"
                    : "border-[#6F6C84]"
                )}
              >
                {value === option && (
                  <div className="w-2 h-2 bg-white rounded-full" />
                )}
              </div>
              <span className="text-white">{option}</span>
            </div>
          </div>
        ))}
      </div>
      {showError && (
        <p className="text-red-500 text-sm">Please select an option</p>
      )}
    </div>
  );
};

export const MultiSelectQuestion = ({
  question,
  value = [],
  onChange,
  isLastQuestion,
  showValidation,
}) => {
  const isEmpty = !value || value.length === 0;
  const showError = showValidation && isEmpty;

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        {(question.options || []).map((option) => (
          <label
            key={option}
            className={classNames(
              "flex items-center p-3 border rounded-lg text-white cursor-pointer",
              value.includes(option)
                ? "bg-[#362C4E] border-[#7C3AED]"
                : showError
                ? "bg-[#262034] border-red-500 hover:bg-[#362C4E]"
                : "bg-[#262034] border-[#3D3A50] hover:bg-[#362C4E]"
            )}
            onClick={() => {
              if (value.includes(option)) {
                onChange(value.filter((item) => item !== option));
              } else {
                onChange([...value, option]);
              }
            }}
          >
            <input
              type="checkbox"
              className="mr-3"
              checked={value.includes(option)}
              onChange={() => {}} // Controlled component needs onChange
            />
            {option}
          </label>
        ))}
      </div>
      {showError && (
        <p className="text-red-500 text-sm">
          Please select at least one option
        </p>
      )}
    </div>
  );
};

export const GoalInputsQuestion = ({
  question,
  value = {},
  onChange,
  isLastQuestion,
  showValidation,
}) => {
  const isEmpty = !value || Object.values(value).some((val) => val === "");

  return (
    <div className="space-y-4">
      {question.inputs.map((input) => {
        const inputValue = value[input.id] || "";
        const isInputEmpty = inputValue === "";
        const showError = showValidation && isInputEmpty;

        return (
          <div key={input.id} className="space-y-2 flex items-center">
            <label className="text-[#9E9CAE] text-base w-1/2">
              {input.label}
            </label>
            <div className="w-full">
              <input
                type="number"
                className={classNames(
                  "w-full p-3 bg-[#262034] border rounded-lg text-white transition-colors",
                  showError ? "border-red-500" : "border-[#3D3A50]"
                )}
                value={inputValue}
                onChange={(e) => {
                  const newValues = {
                    ...value,
                    [input.id]: e.target.value,
                  };
                  onChange(newValues);
                }}
              />
              {showError && (
                <p className="text-red-500 text-sm mt-1">
                  This field is required
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const CombinedQuestion = ({
  question,
  values = {},
  onChangeQuestion,
  isLastQuestion,
  showValidation,
}) => {
  if (!question.parts) return null;

  const getQuestionComponent = (part) => {
    // Get the value from the parent values object
    const value = values[part.id];

    const handleChange = (newValue) => {
      // Call the parent's onChangeQuestion function with the part ID and its new value
      onChangeQuestion(part.id, newValue);
    };

    switch (part.type) {
      case "select":
        return (
          <SelectQuestion
            question={part}
            value={value}
            onChange={handleChange}
            isLastQuestion={isLastQuestion}
            showValidation={showValidation}
          />
        );
      case "number":
        return (
          <NumberQuestion
            question={part}
            value={value}
            onChange={handleChange}
            isLastQuestion={isLastQuestion}
            showValidation={showValidation}
          />
        );
      case "date":
        return (
          <DateQuestion
            question={part}
            value={value}
            onChange={handleChange}
            isLastQuestion={isLastQuestion}
            showValidation={showValidation}
          />
        );
      case "text":
        return (
          <TextQuestion
            question={part}
            value={value}
            onChange={handleChange}
            isLastQuestion={isLastQuestion}
            showValidation={showValidation}
          />
        );
      case "multiSelect":
        return (
          <MultiSelectQuestion
            question={part}
            value={value}
            onChange={handleChange}
            isLastQuestion={isLastQuestion}
            showValidation={showValidation}
          />
        );
      case "buttons":
        return (
          <ButtonsQuestion
            question={part}
            value={value}
            onChange={handleChange}
            isLastQuestion={isLastQuestion}
            showValidation={showValidation}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      {question.parts.map((part, index) => (
        <div key={part.id || index}>
          {/* Part label/title if needed */}
          {part.question && (
            <div className="text-sm text-[#9E9CAE] mb-2">{part.question}</div>
          )}
          {getQuestionComponent(part)}
        </div>
      ))}
    </div>
  );
};

export const HashtagsQuestion = ({
  question,
  value = [],
  onChange,
  isLastQuestion,
  showValidation,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const addHashtag = (tag) => {
    const newHashtag = tag.trim().replace(/^#/, ""); // Remove # if present
    if (newHashtag && !value.includes(newHashtag)) {
      onChange([...value, newHashtag]);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (newValue.endsWith(",")) {
      // Add hashtag when comma is typed
      addHashtag(newValue.slice(0, -1));
      setInputValue("");
    } else {
      setInputValue(newValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      addHashtag(inputValue);
      setInputValue("");
    } else if (e.key === "Backspace" && !inputValue && value.length > 0) {
      // Remove last hashtag when backspace is pressed on empty input
      onChange(value.slice(0, -1));
    }
  };

  const removeHashtag = (index) => {
    onChange(value.filter((_, i) => i !== index));
    inputRef.current?.focus();
  };

  return (
    <div className="space-y-4">
      {/* Search-style input with icon and inline hashtags */}
      <div className="relative">
        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none text-[#6F6C84]">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="currentColor"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="inline-flex min-h-[48px] pl-10 pr-3 py-2 bg-[#262034] border border-[#3D3A50] rounded-lg text-white items-center gap-2 flex-wrap">
          {value.map((hashtag, index) => (
            <div
              key={index}
              className="inline-flex items-center gap-1 px-2 py-1 bg-[#332A49] rounded-md text-sm m-1"
            >
              <span className="text-[#7C3AED]">#</span>
              {hashtag}
              <span
                onClick={() => removeHashtag(index)}
                className="ml-1 text-[#6F6C84] hover:text-white transition-colors cursor-pointer"
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 3L3 9M3 3L9 9"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          ))}
          <input
            ref={inputRef}
            type="text"
            className="inline-flex bg-transparent outline-none placeholder-[#6F6C84] focus:outline-none !focus:ring-0 !focus:outline-0"
            placeholder={value.length === 0 ? "Type a hashtag..." : ""}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>

      {isLastQuestion && (
        <div className="mt-4 p-3 bg-[#332A49] border border-[#7C3AED] rounded-lg">
          <div className="flex items-center">
            <div className="bg-[#7C3AED] bg-opacity-20 p-2 rounded-full mr-3">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
                  fill="#7C3AED"
                />
              </svg>
            </div>
            <div>
              <h4 className="text-[#7C3AED] font-semibold text-sm">
                Final Question
              </h4>
              <p className="text-[#9E9CAE] text-xs">
                You've reached the end of the questionnaire!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// The main renderer component that renders the appropriate question type
const QuestionRenderer = ({
  question,
  value,
  onChange,
  isLastQuestion = false,
  showValidation = false,
}) => {
  // Based on the question type, render the appropriate component
  switch (question.type) {
    case "date":
      return (
        <DateQuestion
          question={question}
          value={value}
          onChange={onChange}
          isLastQuestion={isLastQuestion}
          showValidation={showValidation}
        />
      );
    case "number":
      return (
        <NumberQuestion
          question={question}
          value={value}
          onChange={onChange}
          isLastQuestion={isLastQuestion}
          showValidation={showValidation}
        />
      );
    case "text":
      return (
        <TextQuestion
          question={question}
          value={value}
          onChange={onChange}
          isLastQuestion={isLastQuestion}
          showValidation={showValidation}
        />
      );
    case "buttons":
      return (
        <ButtonsQuestion
          question={question}
          value={value}
          onChange={onChange}
          isLastQuestion={isLastQuestion}
          showValidation={showValidation}
        />
      );
    case "select":
      return (
        <SelectQuestion
          question={question}
          value={value}
          onChange={onChange}
          isLastQuestion={isLastQuestion}
          showValidation={showValidation}
        />
      );
    case "multiSelect":
    case "multiselect":
      return (
        <MultiSelectQuestion
          question={question}
          value={value}
          onChange={onChange}
          isLastQuestion={isLastQuestion}
          showValidation={showValidation}
        />
      );
    case "goalInputs":
      return (
        <GoalInputsQuestion
          question={question}
          value={value}
          onChange={onChange}
          isLastQuestion={isLastQuestion}
          showValidation={showValidation}
        />
      );
    case "combined":
      return (
        <CombinedQuestion
          question={question}
          values={value || {}}
          onChangeQuestion={(partId, partValue) => {
            // Create a new values object with the updated part
            const newValues = {
              ...(value || {}),
              [partId]: partValue,
            };

            // Call the parent onChange with the complete updated values object
            onChange(newValues);
          }}
          isLastQuestion={isLastQuestion}
          showValidation={showValidation}
        />
      );
    case "hashtags":
      return (
        <HashtagsQuestion
          question={question}
          value={value || []}
          onChange={onChange}
          isLastQuestion={isLastQuestion}
          showValidation={showValidation}
        />
      );
    default:
      return (
        <div className="p-3 bg-[#262034] border border-[#3D3A50] rounded-lg text-white">
          Unsupported question type: {question.type}
        </div>
      );
  }
};

export default QuestionRenderer;
