import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Cross2Icon } from "@radix-ui/react-icons";
import classNames from "classnames";
import Dialog from "src/components/Dialog";

// Import components
import QuestionnaireHeader from "./components/QuestionnaireHeader";
import QuestionnaireContent from "./components/QuestionnaireContent";
import QuestionnaireFooter from "./components/QuestionnaireFooter";
import SkipConfirmation from "./components/SkipConfirmation";
import { TopCompletedSectionCards } from "./components/QuestionnaireCards";

// Import hooks
import useQuestionnaireState from "./hooks/useQuestionnaireState";

// Import styles
import styles from "./QuestionnaireModal.module.css";

const QuestionnaireModal = ({
  isOpen,
  onClose,
  sections = [],
  currentSectionIndex = 0,
  currentQuestionIndex = 0,
  onNext,
  onSubmit,
  submitQuestionnaire,
  badgeIcon: BadgeIcon,
  refetchQuestionnaireProgress,
  totalPoints = 0,
  position = "bottom-right",
}) => {
  const [showBackdropConfirmation, setShowBackdropConfirmation] =
    useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [lastCompletedSection, setLastCompletedSection] = useState(null);

  const {
    activeSection,
    activeSubSection,
    responses,
    isSubmitting,
    showSkipConfirmation,
    completedSections,
    sectionsToFadeOut,
    currentSection,
    subsections,
    currentSubsection,
    updateResponse,
    handleBack,
    handleNext: originalHandleNext,
    handleSkip,
    handleConfirmSkip,
    handleCancelSkip,
    showValidation,
  } = useQuestionnaireState({
    sections,
    currentSectionIndex,
    currentQuestionIndex,
    onNext,
    onSubmit,
    submitQuestionnaire,
    refetchQuestionnaireProgress,
    onClose,
  });

  // Update the effect to properly handle new completions
  useEffect(() => {
    if (completedSections.length > 0) {
      const latestCompletedSection =
        completedSections[completedSections.length - 1];

      // Check if this is a new completion
      if (
        !lastCompletedSection ||
        lastCompletedSection.index !== latestCompletedSection.index
      ) {
        setShowConfetti(true);
        setLastCompletedSection(latestCompletedSection);

        // Hide confetti after animation completes
        const timer = setTimeout(() => {
          setShowConfetti(false);
        }, 4000);

        return () => clearTimeout(timer);
      }
    }
  }, [completedSections, lastCompletedSection]);

  // Define position classes for the modal
  const positionClasses = {
    center: "items-center justify-center",
    "bottom-right": "items-end justify-end p-8",
    "bottom-left": "items-end justify-start p-8",
    "top-right": "items-start justify-end p-8",
    "top-left": "items-start justify-start p-8",
  };

  const currentPosition = position || "center";

  // If not open, don't render anything
  if (!isOpen) return null;

  // Guard clause to prevent errors when sections is undefined or empty
  if (!sections || sections.length === 0) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-[#1F1B29] border border-[#2B2735] rounded-2xl p-8 relative">
          <h2 className="text-white text-xl">No sections available</h2>
          <p className="text-gray-300 mt-4">Please try again later.</p>
          <button
            className="mt-6 px-4 py-2 bg-[#7C3AED] text-white rounded-lg hover:bg-[#6D28D9]"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  const handleBackdropClick = () => {
    setShowBackdropConfirmation(true);
  };

  const handleConfirmClose = () => {
    setShowBackdropConfirmation(false);
    onClose();
  };

  const handleCancelClose = () => {
    setShowBackdropConfirmation(false);
  };

  return (
    <>
      {isOpen && (
        <div
          className={classNames(
            "fixed inset-0 bg-black/50 flex z-50",
            positionClasses[currentPosition]
          )}
          onClick={handleBackdropClick}
        >
          <div
            className="bg-[#1F1B29] border border-[#2B2735] rounded-2xl relative shadow-[0px_0px_32px_4px_rgba(155,138,223,0.3)] w-[400px] max-h-[90vh] flex flex-col overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Confetti overlay */}
            {showConfetti && (
              <img
                src="https://s3-alpha-sig.figma.com/img/2ad9/76df/29612f014bad04d4c3ac26dcaef52432?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=hru7EA5RWVfK8IPPfz4Inb~bd6jKVnWtt0tyTKpzcaKZ0SsuqDI0SRxg6PfTeyeTw5EkUQl27~AdBEsMoxLs-scFQvDQmbAFq57nKl8I3bmyouI81J1TSBwCJC9UFuxganV~dNe7A7t6TMIip3jITeYVaH-uX7sjkbS9mAkALuKa-DmWCy0paNAW83l0bUarQffzaxMhJPRPLUpe4qXQqM4J7gfDR1vxZHbzWJc~dWSJK7jmsqRsABZJDKjf60MFnN7AY7IO41KE0Q7ECuRsTD-ig0AUxihRJmobKUgXaYYKNQMlNY4sxSxJfSJFTnYTqH2eyg4wFs-dAhfN5sb--A__"
                alt="Confetti celebration"
                className={styles.modalConfettiOverlay}
              />
            )}

            {/* Skip confirmation dialog */}
            {showSkipConfirmation && (
              <SkipConfirmation
                onConfirm={handleConfirmSkip}
                onCancel={handleCancelSkip}
              />
            )}

            <div className="flex flex-col flex-1 overflow-hidden">
              {/* Top completed cards - positioned at the top inside the modal */}
              <div className="relative">
                <TopCompletedSectionCards
                  completedSections={completedSections}
                  sectionsToFadeOut={sectionsToFadeOut}
                  BadgeIcon={BadgeIcon}
                />
              </div>

              {/* Header component */}
              <QuestionnaireHeader
                currentSection={currentSection}
                activeSection={activeSection}
                activeSubSection={activeSubSection}
                subsections={subsections}
                totalSections={sections.length}
                totalPoints={totalPoints}
                BadgeIcon={BadgeIcon}
                onBack={handleBack}
              />

              {/* Main content component */}
              <QuestionnaireContent
                currentSubsection={currentSubsection}
                responses={responses}
                updateResponse={updateResponse}
                sections={sections}
                activeSection={activeSection}
                activeSubSection={activeSubSection}
                subsections={subsections}
                BadgeIcon={BadgeIcon}
                isEndOfQuestionnaires={
                  activeSection === sections.length - 1 &&
                  activeSubSection === subsections.length - 1
                }
                showValidation={showValidation}
              />

              {/* Footer component */}
              <QuestionnaireFooter
                handleBack={handleBack}
                handleNext={originalHandleNext}
                handleSkip={handleSkip}
                isLastSection={activeSection === sections.length - 1}
                isLastSubsection={activeSubSection === subsections.length - 1}
              />
            </div>
          </div>
        </div>
      )}

      {/* Backdrop click confirmation dialog */}
      <Dialog
        show={showBackdropConfirmation}
        action1ButtonLabel="Continue"
        action2ButtonLabel="I'll do later"
        handelAction1={handleCancelClose}
        handelAction2={handleConfirmClose}
      >
        <div className="flex-col justify-center align-middle">
          <div
            style={{
              background: "rgba(37, 31, 52, 1)",
              width: 100,
              height: 100,
              borderRadius: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 20,
              left: "40%",
              fontSize: "48px",
            }}
          >
            😔
          </div>
          <div className="pt-28">
            <h3 className="white text-align-center">Leaving Buzz Factory?</h3>
            <h5 className="white text-align-center">
              Your progress will be saved. You can continue setting up your
              brand goals later.
            </h5>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default QuestionnaireModal;
