import React from "react";
import classNames from "classnames";
import { CardStack } from "./QuestionnaireCards";
import QuestionRenderer from "./QuestionTypes";

const QuestionnaireContent = ({
  currentSubsection,
  currentSubsection: { title, questions = [] } = {},
  responses,
  updateResponse,
  sections,
  activeSection,
  activeSubSection,
  subsections,
  BadgeIcon,
  isEndOfQuestionnaires = false,
  showValidation = false,
}) => {
  return (
    <div className="px-6 overflow-y-auto flex-1">
      {/* Subsection Title */}
      <h2 className="text-xl text-[#9E9CAE] font-medium mb-6">{title}</h2>

      {/* Questions */}
      <div className="space-y-8">
        {questions.map((question, index) => (
          <div key={question.id || index} className="mb-8">
            <h3 className="text-base text-white mb-4">{question.question}</h3>
            <QuestionRenderer
              question={question}
              value={responses[question.id]}
              onChange={(value) => updateResponse(question.id, value)}
              isLastQuestion={
                isEndOfQuestionnaires && index === questions.length - 1
              }
              showValidation={showValidation}
            />
          </div>
        ))}
      </div>

      {/* Badge info / Card stack */}
      <CardStack
        sections={sections}
        activeSection={activeSection}
        activeSubSection={activeSubSection}
        subsections={subsections}
        BadgeIcon={BadgeIcon}
      />
    </div>
  );
};

export default QuestionnaireContent;
