import moment from "moment";
import { CalendarIcon } from "../../assets/svg/CalendarIcon";
import { CheckCircle } from "../../assets/svg/CheckCircle";
import { LocationIcon } from "../../assets/svg/LocationIcon";
import { AnimatedList } from "../magicui/animated-list";
import "./ListView.css";
import React, { useEffect, useRef, useState } from "react";
import {
  ClockIcon,
  Pencil1Icon,
  CircleIcon,
  CrossCircledIcon,
  DoubleArrowUpIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@radix-ui/react-icons";
import { notify } from "../Toast/ToastComponent";
import { DatePicker } from "antd";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { API_END_POINTS } from "../../utils/api-endpoint";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import { Draggable } from "react-beautiful-dnd";
import { estimatedTimeData } from "src/constants/constant";
import Dropdown from "../Dropdown";
import PriorityDropdown from "../PriorityDropdown/PriorityDropdown";
import ProjectDropdown from "../ProjectDropdown/ProjectDropdown";
import ListViewSkeleton from "../Skeleton/ListViewSkeleton";

const ListViewHeader = () => {
  return (
    <div className="hidden md:flex flex-col md:flex-row md:justify-between md:items-center h-12 px-6 text-gray-400 text-sm">
      <div className="md:w-2/5">
        <span>Title</span>
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center md:w-3/5 gap-2 md:gap-0 justify-between">
        <div className="w-full md:w-40">
          <span>Project</span>
        </div>
        <div className="w-full md:w-32">
          <span>Priority</span>
        </div>
        <div className="w-full md:w-32">
          <span>Due Date</span>
        </div>
        <div className="w-full md:w-24">
          <span>Duration</span>
        </div>
        <div className="w-full md:w-32">
          <span>Status</span>
        </div>
      </div>
    </div>
  );
};

export const ListViewComponent = ({
  title,
  date,
  duration,
  onClick,
  handleMark,
  completed,
  projectData,
  data,
  handleEditTask,
  selectedProjectIndex,
  setData,
  onClickComplete = () => {},
  setSelectedData,
  selectedData,
  task,
  index,
  onUpdateTitle,
  isDraggable = false,
  refetch,
  setFilteredData,
  selectedEvent,
  setSelectedEvent,
  status,
  isLoading = false,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [editedDate, setEditedDate] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDurationDropdown, setShowDurationDropdown] = useState(false);
  const [showPriorityDropdown, setShowPriorityDropdown] = useState(false);
  const [showProjectDropdown, setShowProjectDropdown] = useState(false);
  const [hoveredProjectIndex, setHoveredProjectIndex] = useState(null);

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [hoveredPriorityIndex, setHoveredPriorityIndex] = useState(null);

  const inputRef = useRef(null);
  const priorityDropdownRef = useRef(null);
  const projectDropdownRef = useRef(null);

  // Add new state to track which item is being edited
  const [editingItemId, setEditingItemId] = useState(null);

  // update the task
  const { mutate: patchTask } = useMutationPatch(
    `${API_END_POINTS.tasks}/${task?._id}`
  );

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
    // setShowStatusDropdown(true);
  };

  const handleMouseLeave = (e) => {
    const relatedTarget = e.relatedTarget;
    if (
      !priorityDropdownRef.current.contains(relatedTarget) &&
      !showPriorityDropdown
    ) {
      setHoveredPriorityIndex(null);
    }
  };
  const calculateEstimatedTime = (selectedEvent) => {
    const startDate = new Date(selectedEvent?.startDate);
    const endDate = new Date(selectedEvent?.endDate);

    const timeDifference = endDate - startDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    return { days, hours, minutes };
  };

  const estimatedTime = calculateEstimatedTime(duration);
  const displayEstimatedTime = () => {
    const { days, hours, minutes } = estimatedTime;
    const parts = [];
    if (days > 0) parts.push(`${days} days`);
    if (hours > 0) parts.push(`${hours} hours`);
    if (minutes > 0) parts.push(`${minutes} mins`);
    return parts.length > 0 ? parts.join(", ") : "0 mins";
  };

  const handleEditComplete = () => {
    setIsEditingTitle(false);
    setEditingItemId(null); // Reset the editing item
    const updatedTask = { ...task, title: editedTitle };

    // Update UI immediately for both filtered data and project tasks
    if (setFilteredData) {
      setFilteredData((prev) => {
        const updatedData = prev.map((item) => {
          if (item._id === task._id) {
            return updatedTask;
          }
          return item;
        });
        return updatedData;
      });
    }

    // Update the project tasks list
    setData((prevData) => {
      return prevData.map((project) => {
        if (project.tasksList) {
          return {
            ...project,
            tasksList: project.tasksList.map((t) =>
              t._id === task._id ? updatedTask : t
            ),
          };
        }
        return project;
      });
    });

    // Update backend
    patchTask(updatedTask, {
      onSuccess(res) {
        notify("Task title updated successfully", "success");
        if (onUpdateTitle) {
          onUpdateTitle(editedTitle);
        }
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
        setEditedTitle(title);
      },
    });
  };

  const handleEditDateComplete = (date) => {
    setShowDatePicker(false);
    setEditedDate(date);

    const updatedTask = {
      ...task,
      endDate: date?.toISOString(),
      startDate: date?.toISOString(),
    };

    // Update UI immediately
    setData((prevData) => {
      return prevData.map((project) => {
        if (project.tasksList) {
          return {
            ...project,
            tasksList: project.tasksList.map((t) =>
              t._id === task._id ? updatedTask : t
            ),
          };
        }
        return project;
      });
    });

    patchTask(updatedTask, {
      onSuccess(res) {
        notify("Task date updated successfully", "success");
        setData((prevData) =>
          prevData.map((item) =>
            item._id === task._id ? { ...item, ...updatedTask } : item
          )
        );
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleStatusChange = (newStatus) => {
    setShowStatusDropdown(false);
    setHoveredIndex(null);
    const updatedTask = {
      ...task,
      status: newStatus,
      completed: newStatus === "COMPLETED",
    };

    // Update both filtered data and main data
    if (setFilteredData) {
      setFilteredData((prev) => {
        const updatedData = prev.map((item) => {
          if (item._id === task._id) {
            return updatedTask;
          }
          return item;
        });
        return updatedData;
      });
    }

    // Update the project tasks list
    setData((prevData) => {
      return prevData.map((project) => {
        if (project.tasksList) {
          return {
            ...project,
            tasksList: project.tasksList.map((t) =>
              t._id === task._id ? updatedTask : t
            ),
          };
        }
        return project;
      });
    });

    // Update selected event if it exists
    if (setSelectedEvent) {
      setSelectedEvent((prevData) => {
        if (prevData?.tasksList) {
          return {
            ...prevData,
            tasksList: prevData.tasksList.map((t) =>
              t._id === task._id ? updatedTask : t
            ),
          };
        }
        return prevData;
      });
    }

    patchTask(updatedTask, {
      onSuccess(res) {
        notify(`Status changed to ${newStatus}`, "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleDurationChange = (option) => {
    const updatedTask = {
      ...task,
      estimatedTimeHours: option.value,
    };

    // Update both filtered data and main data
    if (setFilteredData) {
      setFilteredData((prev) => {
        const updatedData = prev.map((item) => {
          if (item._id === task._id) {
            return updatedTask;
          }
          return item;
        });
        return updatedData;
      });
    }

    // Update the project tasks list
    setData((prevData) => {
      return prevData.map((project) => {
        if (project.tasksList) {
          return {
            ...project,
            tasksList: project.tasksList.map((t) =>
              t._id === task._id ? updatedTask : t
            ),
          };
        }
        return project;
      });
    });

    if (setSelectedEvent) {
      setSelectedEvent((prevData) => {
        if (prevData?.tasksList) {
          return {
            ...prevData,
            tasksList: prevData.tasksList.map((t) =>
              t._id === task._id ? updatedTask : t
            ),
          };
        }
        return prevData;
      });
    }

    patchTask(updatedTask, {
      onSuccess(res) {
        notify("Estimated time updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handlePriorityChange = (newPriority) => {
    setShowPriorityDropdown(false);
    setHoveredPriorityIndex(null);
    const updatedTask = {
      ...task,
      priority: newPriority,
    };

    // Update both filtered data and main data
    if (setFilteredData) {
      setFilteredData((prev) =>
        prev.map((item) => (item._id === task._id ? updatedTask : item))
      );
    }

    setData((prevData) => {
      return prevData.map((project) => {
        if (project.tasksList) {
          return {
            ...project,
            tasksList: project.tasksList.map((t) =>
              t._id === task._id ? updatedTask : t
            ),
          };
        }
        return project;
      });
    });

    if (setSelectedEvent) {
      setSelectedEvent((prevData) => {
        if (prevData?.tasksList) {
          return {
            ...prevData,
            tasksList: prevData.tasksList.map((t) =>
              t._id === task._id ? updatedTask : t
            ),
          };
        }
        return prevData;
      });
    }

    patchTask(updatedTask, {
      onSuccess(res) {
        notify(`Priority changed to ${newPriority}`, "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleProjectChange = (newProjectId) => {
    setShowProjectDropdown(false);
    setHoveredProjectIndex(null);
    const updatedTask = {
      ...task,
      project: newProjectId,
    };

    // Update both filtered data and main data
    if (setFilteredData) {
      setFilteredData((prev) =>
        prev.map((item) => (item._id === task._id ? updatedTask : item))
      );
    }

    setData((prevData) => {
      return prevData.map((project) => {
        if (project.tasksList) {
          return {
            ...project,
            tasksList: project.tasksList.map((t) =>
              t._id === task._id ? updatedTask : t
            ),
          };
        }
        return project;
      });
    });

    patchTask(updatedTask, {
      onSuccess(res) {
        notify(`Project updated successfully`, "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  useEffect(() => {
    const hasLoadedBefore = sessionStorage.getItem("hasLoadedBefore");

    if (!hasLoadedBefore) {
      setIsFirstRender(true);
      setTimeout(() => {
        sessionStorage.setItem("hasLoadedBefore", "true");
      }, 500);
    }
  }, [isFirstRender]);

  const isPastDate =
    new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  const dateClass = isPastDate ? "red" : "gray-300";

  const ListComponent = isFirstRender ? AnimatedList : "div";

  const selectedProject = projectData?.filter(
    (item) => data?.project === item?._id
  );

  const getPriorityIcon = (priority) => {
    switch (priority?.toUpperCase()) {
      case "HIGH":
        return <DoubleArrowUpIcon color="#EF4870" />;
      case "LOW":
        return <ArrowDownIcon color="#4CAF50" />;
      case "MEDIUM":
      default:
        return <ArrowUpIcon color="#FF7700" />;
    }
  };

  const getProjectTitle = () => {
    if (!task?.project || !projectData) return "Select Project";
    const project = projectData.find((proj) => proj._id === task.project);
    return project?.title || "Select Project";
  };

  // Add useEffect to handle auto-focus
  useEffect(() => {
    if (isEditingTitle && inputRef.current) {
      inputRef.current.focus();
      // Optional: Select all text
      // inputRef.current.select();
    }
  }, [isEditingTitle]);

  const content = (
    <>
      {/* Only show header for the first item in the list */}
      {index === 0 && <ListViewHeader />}
      <div
        onClick={(e) => {
          if (!isEditingTitle) {
            onClick(e);
          }
        }}
        className="list-view-wrapper h-auto md:h-16 p-4 md:p-6 m-2 cursor-pointer relative rounded-md hover:bg-[rgba(38,43,75,1)]"
      >
        <div className="flex flex-col md:flex-row md:justify-between md:items-center h-full gap-4 md:gap-0">
          {/* TITLE & EDIT ICON */}
          <div
            className="inline-flex items-center md:w-2/5"
            onMouseEnter={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
          >
            {isEditingTitle && editingItemId === task._id ? (
              <input
                ref={inputRef}
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={handleEditComplete}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleEditComplete();
                  }
                }}
                className="text-base md:text-lg font-semibold bg-transparent text-white border-none border-white focus:outline-none p-0 w-full"
              />
            ) : (
              <span className="text-white text-base md:text-lg font-semibold pl-2 md:pl-4 break-words">
                {selectedProject?.[0]
                  ? selectedProject?.[0]?.title + " - " + editedTitle
                  : title}
              </span>
            )}
            {showEditIcon && !isEditingTitle && (
              <Pencil1Icon
                onClick={(e) => {
                  e.stopPropagation();
                  if (editingItemId && editingItemId !== task._id) {
                    handleEditComplete();
                  }
                  setIsEditingTitle(true);
                  setEditingItemId(task._id);
                  setSelectedData(task);
                  setTimeout(() => {
                    if (inputRef.current) {
                      inputRef.current.focus();
                    }
                  }, 0);
                }}
                className="ml-2 cursor-pointer text-white"
              />
            )}
          </div>

          {/* PROJECT, PRIORITY, DATE, DURATION & STATUS */}
          <div className="flex flex-col md:flex-row items-start md:items-center md:w-3/5 gap-2 md:gap-0 justify-between">
            {/* Project Selection Section */}
            <div
              className="flex items-center w-full md:w-40 justify-start md:relative"
              onMouseEnter={() => setHoveredProjectIndex(task?._id)}
              onMouseLeave={(e) => {
                const relatedTarget = e.relatedTarget;
                if (
                  !projectDropdownRef.current?.contains(relatedTarget) &&
                  !showProjectDropdown
                ) {
                  setHoveredProjectIndex(null);
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowProjectDropdown(!showProjectDropdown);
              }}
            >
              {hoveredProjectIndex === task?._id || showProjectDropdown ? (
                <div
                  ref={projectDropdownRef}
                  className="w-full md:w-auto text-base left-[-20px] md:absolute md:z-10"
                >
                  <ProjectDropdown
                    projects={projectData}
                    selectedProject={task?.project}
                    onProjectChange={handleProjectChange}
                  />
                </div>
              ) : (
                <span className="regularS white p-1 text-xs md:text-sm">
                  {getProjectTitle()}
                </span>
              )}
            </div>

            {/* Priority Section */}
            <div
              className="flex items-center w-full md:w-32 justify-start md:relative"
              onMouseEnter={() => setHoveredPriorityIndex(task?._id)}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => {
                e.stopPropagation();
                setShowPriorityDropdown(!showPriorityDropdown);
              }}
            >
              {hoveredPriorityIndex === task?._id || showPriorityDropdown ? (
                <div
                  ref={priorityDropdownRef}
                  className="w-full md:w-auto text-base md:absolute md:z-10"
                >
                  <PriorityDropdown
                    priority={task?.priority || "MEDIUM"}
                    onPriorityChange={(newPriority) => {
                      handlePriorityChange(newPriority);
                      setShowPriorityDropdown(false);
                      setHoveredPriorityIndex(null);
                    }}
                  />
                </div>
              ) : (
                <>
                  {getPriorityIcon(task?.priority)}
                  <span className="regularS white p-1 text-xs md:text-sm">
                    {task?.priority?.charAt(0).toUpperCase() +
                      task?.priority?.slice(1).toLowerCase() || "Medium"}
                  </span>
                </>
              )}
            </div>
            {/* Date Picker Section */}
            <div
              className="flex items-center w-full md:w-32 justify-start relative"
              onMouseEnter={() => {
                setShowDatePicker(true);
                setEditedDate(date ? new Date(date) : new Date());
              }}
              onMouseLeave={(e) => {
                if (!e.relatedTarget?.closest(".react-datepicker-wrapper")) {
                  setShowDatePicker(false);
                }
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {showDatePicker ? (
                <div className="z-10 p-2 rounded-md shadow-lg">
                  <DatePicker
                    className="bg-transparent text-sm"
                    selected={editedDate || new Date(date)}
                    onChange={(newDate) => {
                      setEditedDate(newDate);
                      handleEditDateComplete(newDate);
                    }}
                    onClickOutside={() => setShowDatePicker(false)}
                    open={true}
                  />
                </div>
              ) : (
                <div className="flex items-center pr-2 md:pr-4">
                  <CalendarIcon color={isPastDate ? "#EF4870" : "#D1D5DB"} />
                  <span className={`pl-2 text-xs md:text-sm ${dateClass}`}>
                    {editedDate
                      ? moment(editedDate?.toISOString()).isValid()
                        ? moment(editedDate?.toISOString()).format("MMM D YYYY")
                        : "Not set"
                      : date && moment(date).isValid()
                      ? moment(date).format("MMM D YYYY")
                      : "Not set"}
                  </span>
                </div>
              )}
            </div>

            {/* Duration Section */}
            <div
              className="flex items-center w-full md:w-24 justify-start relative"
              onMouseEnter={() => setShowDurationDropdown(true)}
              onMouseLeave={(e) => {
                if (!e.relatedTarget?.closest(".duration-dropdown-wrapper")) {
                  setShowDurationDropdown(false);
                }
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {showDurationDropdown ? (
                <div className="z-10 duration-dropdown-wrapper">
                  <Dropdown
                    className="w-full md:w-[120px] text-base"
                    options={estimatedTimeData}
                    onOptionChange={(option) => {
                      handleDurationChange(option);
                      setShowDurationDropdown(false);
                    }}
                    value={task.estimatedTime}
                    placeholder="Time"
                  />
                </div>
              ) : (
                <div className="flex items-center">
                  <ClockIcon color={"var(--Genesis-Gray-300)"} />
                  <span className="regularS white p-1 text-xs md:text-sm">
                    {task.estimatedTimeHours
                      ? task.estimatedTimeHours + " hours"
                      : "Not set"}
                  </span>
                </div>
              )}
            </div>

            {/* Status Section */}
            <div
              className="flex items-center w-full md:w-32 justify-start relative"
              onMouseEnter={() => setShowStatusDropdown(true)}
              onMouseLeave={(e) => {
                if (!e.relatedTarget?.closest(".status-dropdown-wrapper")) {
                  setShowStatusDropdown(false);
                }
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {showStatusDropdown ? (
                <div className="z-10 status-dropdown-wrapper">
                  <StatusDropdown
                    status={task?.status || "TODO"}
                    onStatusChange={(newStatus) => {
                      handleStatusChange(newStatus);
                      setShowStatusDropdown(false);
                    }}
                    isListView={true}
                    className="w-[100px]"
                  />
                </div>
              ) : (
                <div className="flex items-center">
                  {task?.status === "TODO" && <CircleIcon color="#06719A" />}
                  {task?.status === "IN_PROGRESS" && (
                    <ClockIcon color="#FF7700" />
                  )}
                  {task?.status === "BLOCKED" && (
                    <CrossCircledIcon color="#EF4870" />
                  )}
                  {task?.status === "COMPLETED" && (
                    <CheckCircle color="#06719A" />
                  )}
                  <span className="regularS white p-1 text-xs md:text-sm">
                    {task?.status === "TODO" && "To Do"}
                    {task?.status === "IN_PROGRESS" && "In Progress"}
                    {task?.status === "BLOCKED" && "Blocked"}
                    {task?.status === "COMPLETED" && "Completed"}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  if (isLoading) {
    return <ListViewSkeleton />;
  }

  return (
    <ListComponent>
      {isDraggable ? (
        <Draggable draggableId={task._id.toString()} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={snapshot.isDragging ? "dragging" : ""}
            >
              {content}
            </div>
          )}
        </Draggable>
      ) : (
        content
      )}
    </ListComponent>
  );
};

export const EventListViewComponent = ({
  title,
  date,
  duration,
  description,
  location,
  item,
  onClick,
  handleEditTask,
  selectedProjectIndex,
  setData,
  onClickComplete = () => {},
  patchTask,
  setSelectedData,
  task,
  refetch,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [editedDate, setEditedDate] = useState(date);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const hasLoadedBeforeEvent = sessionStorage.getItem("hasLoadedBeforeEvent");
    if (!hasLoadedBeforeEvent) {
      setIsFirstRender(true);
      setTimeout(() => {
        sessionStorage.setItem("hasLoadedBeforeEvent", "true");
      }, 500);
    }
  }, [isFirstRender]);
  const EventComponent = isFirstRender ? AnimatedList : "div";

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const handleEditComplete = (index, title, description, date) => {
    setIsEditingTitle(false);
    // const updatedTasks = { ...task };
    // updatedTasks.title = editedTitle;
    // // setData(updatedTasks);
    // patchTask(updatedTasks, {
    //   onSuccess(res) {
    //     notify("Task updated successfully", "success");
    //   },
    //   onError: (err) => {
    //     notify(err?.error?.message, "error");
    //   },
    // });
  };

  const handleEditDateComplete = (date) => {
    if (editedDate === date) {
      setShowDatePicker(false);
      return;
    } else {
      setShowDatePicker(false);
      // const updatedData = data?.map((item, i) => {
      //   // If the item has a taskLists array, add the new task to it
      //   if (i === selectedProjectIndex && item?.tasksList) {
      //     const updatedTasks = [...item?.tasksList];
      //     // console.log("updatedTasks", updatedTasks);

      //     updatedTasks[i].endDate = date;
      //     // updatedTasks[index].description = description;
      //     // updatedTasks[index].date = date;
      //   }
      //   return item;
      // });

      // setData(updatedData);
      // patchTask(updatedData[selectedProjectIndex]?.tasksList?.[0], {
      //   onSuccess(res) {
      //     // if (selectedViewType === "List View") {
      //     //   setJustUpdated(true);
      //     // }
      //     // setIsModalOpen(false);
      //     // refetchDashboardData();
      //     notify("Task updated successfully", "success");
      //     refetch();
      //   },
      //   onError: (err) => {
      //     notify(err?.error?.message, "error");
      //   },
      // });
    }
    // handleEditTask();
    // Add any additional logic needed for successful edit here
  };
  return (
    <EventComponent>
      <div
        className="list-view-wrapper p-8 m-8 cursor-pointer"
        onClick={(e) => {
          if (!isEditingTitle) {
            onClick(e);
          }
        }}
      >
        <div className="flex-row justify-space-between">
          <div className="flex justify-between items-center">
            <div
              className="w-full relative"
              onMouseEnter={() => setShowEditIcon(true)}
              onMouseLeave={() => setShowEditIcon(false)}
            >
              {isEditingTitle ? (
                <input
                  ref={inputRef}
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleEditComplete}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEditComplete();
                    }
                  }}
                  className="text-lg font-semibold bg-transparent text-white border-none border-white focus:outline-none p-0"
                />
              ) : (
                <span className="text-white text-lg font-semibold">
                  {title}
                </span>
              )}
              {showEditIcon && !isEditingTitle && (
                <Pencil1Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditingTitle(true);
                    setSelectedData(task);

                    // setSelectedData(data)
                  }}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer text-white"
                />
              )}
            </div>
          </div>
          {/* <span className="regularS white">{title}</span> */}
          <div className="flex-row align-center w-40">
            <div className="flex-row align-center">
              <CalendarIcon />{" "}
              <span className="regularS p-4 red">
                {item?.isGoogleEvent
                  ? moment(item?.startDateTime).format("MMM D YYYY")
                  : moment(item?.startDateTime).format("MMM D YYYY")}
              </span>
            </div>
            {showDatePicker && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="absolute top-full mt-2 z-10 p-2 rounded-md shadow-lg"
              >
                <DatePicker
                  className="bg-transparent"
                  selected={new Date(date)}
                  value={
                    item?.isGoogleEvent
                      ? item?.calendarData?.start?.dateTime
                      : date
                  }
                  onChange={(newDate) => {
                    setSelectedData({ ...task, endDate: newDate });
                    setEditedDate(newDate);
                    handleEditDateComplete(newDate);
                    setShowDatePicker(false);
                  }}

                  // inline
                />
              </div>
            )}
            {location && (
              <div className="flex-row align-center">
                <LocationIcon />
                <span className="regularS p-4 white">
                  {truncateText(location, 20)}
                </span>
              </div>
            )}
            <span>{duration}</span>
          </div>
        </div>
        <div>
          <span className="white">{truncateText(description, 49)}</span>
        </div>
      </div>
    </EventComponent>
  );
};
