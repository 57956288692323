import React from "react";
import Button from "../../Button";

const QuestionnaireFooter = ({
  handleBack,
  handleNext,
  handleSkip,
  isLastSection,
  isLastSubsection,
}) => {
  const isLastStep = isLastSection && isLastSubsection;

  return (
    <div className="p-6 border-t border-[#2B2735] bg-[#1F1B29] rounded-b-2xl shrink-0">
      <div className="flex justify-between gap-4">
        <Button
          variety="outlined-primary"
          onClick={handleSkip}
          className="flex-1 rounded-lg"
        >
          {isLastStep ? "Skip for now" : "I'll do it later"}
        </Button>
        <Button
          variety="filled"
          onClick={handleNext}
          className="flex-1 rounded-lg"
        >
          {isLastStep ? "Complete" : "Continue"}
        </Button>
      </div>
    </div>
  );
};

export default QuestionnaireFooter;
