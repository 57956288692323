// Action Types
export const ACTIONS = {
  TOGGLE_DROPDOWN: "TOGGLE_DROPDOWN",
  SET_MODAL: "SET_MODAL",
  SET_SELECTED_DATA: "SET_SELECTED_DATA",
  SET_EXPANDED: "SET_EXPANDED",
  SET_LOADING: "SET_LOADING",
  SET_DATA: "SET_DATA",
  RESET_STATE: "RESET_STATE",
};

// Initial state
export const initialState = {
  dropdowns: {
    status: false,
    priority: false,
    estimatedTime: false,
  },
  modals: {
    isModalOpen: false,
    isCreateModalOpen: false,
    confirmationDialog: false,
    isPopupOpen: false,
    modalTitle: "",
    modalContentType: null,
  },
  data: {
    selectedData: null,
    expandedId: null,
    data: [],
    selectedEvent: null,
  },
  ui: {
    isEdit: false,
    isButtonLoading: false,
    justUpdated: false,
  },
};

// Reducer function
export function dashboardReducer(state, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_DROPDOWN:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          status:
            action.dropdown === "status" ? !state.dropdowns.status : false,
          priority:
            action.dropdown === "priority" ? !state.dropdowns.priority : false,
          estimatedTime:
            action.dropdown === "estimatedTime"
              ? !state.dropdowns.estimatedTime
              : false,
        },
      };

    case ACTIONS.SET_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          ...action.payload,
        },
        data: {
          ...state.data,
          ...(action.payload.modalContentType && {
            selectedData:
              action.payload.selectedData || state.data.selectedData,
          }),
        },
        ui: {
          ...state.ui,
          isEdit:
            action.payload.isEdit !== undefined
              ? action.payload.isEdit
              : state.ui.isEdit,
        },
      };

    case ACTIONS.SET_SELECTED_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          selectedData: action.payload,
        },
      };

    case ACTIONS.SET_EXPANDED:
      return {
        ...state,
        data: {
          ...state.data,
          expandedId: action.payload,
        },
      };

    case ACTIONS.SET_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          isButtonLoading: action.payload,
        },
      };

    case ACTIONS.SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          data: action.payload,
        },
      };

    case ACTIONS.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

// Action creators
export const dashboardActions = {
  toggleDropdown: (dropdown) => ({
    type: ACTIONS.TOGGLE_DROPDOWN,
    dropdown,
  }),

  setModal: (modalData) => ({
    type: ACTIONS.SET_MODAL,
    payload: modalData,
  }),

  setSelectedData: (data) => ({
    type: ACTIONS.SET_SELECTED_DATA,
    payload: data,
  }),

  setExpanded: (id) => ({
    type: ACTIONS.SET_EXPANDED,
    payload: id,
  }),

  setLoading: (isLoading) => ({
    type: ACTIONS.SET_LOADING,
    payload: isLoading,
  }),

  setData: (data) => ({
    type: ACTIONS.SET_DATA,
    payload: data,
  }),

  resetState: () => ({
    type: ACTIONS.RESET_STATE,
  }),
};
