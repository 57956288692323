export const StatCard = ({ label, value, current, goal, color }) => {
  const progress = (current / goal) * 100;

  return (
    <div className="bg-[#262034] p-[16px] rounded-2xl">
      <div className="text-[#6F6C84] text-sm font-medium">{label}</div>
      <div className="text-white text-3xl font-bold py-3">{value}</div>
      <div className="h-[16px] bg-[#3B354A] mt-2">
        <div
          className="h-full rounded-tr-sm rounded-br-sm transition-all duration-300"
          style={{
            width: `${progress}%`,
            background:
              "linear-gradient(0deg, #027B66 0%, #00E5BE 54%, #027B66 98%)",
          }}
        />
      </div>
      <div className="text-gray-500 text-sm font-medium py-[10px] flex justify-end">
        Goal: {current.toLocaleString()}/{goal.toLocaleString()}
      </div>
    </div>
  );
};
