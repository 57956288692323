import {
  AnalyticsIcon,
  GoalsIcon,
  OnboardingStep1BadgeIcon,
  ProgressionIcon,
} from "src/assets/svg/OnboardingModalIcons";
import OnboardingBadge from "src/assets/svg/OnboardingBadge";

/**
 * Onboarding flow steps configuration
 * Each step defines the content and appearance for a step in the onboarding process
 */
export const onboardingSteps = [
  {
    title: "Welcome to Buzz Factory",
    description:
      "Your pathway to create your buzz, a full journey path with exciting objectives and achievements for building your career.",
    secondaryDescription:
      "Earn credits through your achievements and use those credits as in-app currency for booking different services in our platform.",
    showBadgeCount: true,
    background: "bg-[#7C3AED]",
    icon: OnboardingStep1BadgeIcon,
  },
  {
    title: "View your progress as levels",
    description:
      "Level 1 to 5 defined and unlocked through your career progress. Earn badges, get achievements and unlock new opportunities.",
    background: "bg-[#7C3AED]",
    icon: OnboardingBadge,
  },
  {
    title: "Your Progress & Guided Actions",
    description:
      "Track your analytics based on your socials and also get personalised action directing you for taking next step towards earning new achievements.",
    background: "bg-[#7C3AED]",
    icon: AnalyticsIcon,
    showSocialIcons: true,
  },
  {
    title: "Tracking your goals",
    description:
      "Answer the initial set of questionnaire to set your own trackers up to track your growth as you go.",
    background: "bg-[#7C3AED]",
    icon: GoalsIcon,
  },
  {
    title: "Progression & Achievements",
    description:
      "Check out how much you've progressed in each facet based on different genre of badges you've gained and also track what are the next metrics you need to keep a track of to earn a badge.",
    background: "bg-[#7C3AED]",
    icon: ProgressionIcon,
    finalStep: true,
  },
];
