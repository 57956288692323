export const BuzzFactoryLogo = ({ color = "#00B8AE" }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.878906L16.7942 5.37891V14.3789L9 18.8789L1.20577 14.3789V5.37891L9 0.878906Z"
        stroke={color}
        stroke-width="1.35791"
      />
      <path
        d="M9 5.87891L10.4812 7.84018L12.8042 8.64284L11.3967 10.6576L11.3511 13.115L9 12.3989L6.64886 13.115L6.60334 10.6576L5.19577 8.64284L7.51878 7.84018L9 5.87891Z"
        stroke={color}
        stroke-width="0.905273"
      />
    </svg>
  );
};
