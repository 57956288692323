const SocialSpeedometer = () => {
  return (
    <svg
      width="167"
      height="163"
      viewBox="0 0 167 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.885 159.135C155.885 150.253 154.136 141.458 150.737 133.252C147.338 125.046 142.356 117.59 136.075 111.31C129.795 105.029 122.339 100.047 114.133 96.6484C105.927 93.2494 97.1322 91.5 88.2502 91.5C79.3683 91.5 70.5733 93.2494 62.3674 96.6484C54.1616 100.047 46.7056 105.029 40.4251 111.31C34.1446 117.59 29.1626 125.046 25.7636 133.252C22.3647 141.458 20.6152 150.253 20.6152 159.135H31.1257C31.1257 151.633 32.6033 144.205 35.4741 137.274C38.3448 130.344 42.5526 124.046 47.8571 118.742C53.1616 113.437 59.459 109.23 66.3896 106.359C73.3203 103.488 80.7485 102.01 88.2502 102.01C95.7519 102.01 103.18 103.488 110.111 106.359C117.042 109.23 123.339 113.437 128.643 118.742C133.948 124.046 138.156 130.344 141.026 137.274C143.897 144.205 145.375 151.633 145.375 159.135H155.885Z"
        fill="#413954"
      />
      <path
        d="M152.541 138.13C148.329 125.239 140.34 113.912 129.609 105.619C118.879 97.3264 105.903 92.4508 92.3668 91.6254L91.7272 102.114C103.16 102.812 114.12 106.93 123.183 113.934C132.246 120.938 138.995 130.506 142.552 141.394L152.541 138.13Z"
        fill="#00E1BA"
      />
      <path
        d="M21.7402 146.85C20.979 150.972 20.6025 155.155 20.6156 159.346L31.124 159.313C31.113 155.773 31.431 152.24 32.074 148.759L21.7402 146.85Z"
        fill="#EF4870"
      />
      <path
        d="M121.816 135.375L86.343 146.098C85.9396 146.22 85.5908 146.478 85.3555 146.827L80.7953 153.603C80.0181 154.757 80.7651 156.322 82.1516 156.444L89.9095 157.126C90.2769 157.159 90.6455 157.079 90.9668 156.898L123.242 138.717C125.145 137.646 123.906 134.743 121.816 135.375Z"
        fill="#413954"
      />
      <rect
        x="0.651922"
        y="51.921"
        width="47"
        height="47"
        rx="7.5"
        transform="rotate(-22.2139 0.651922 51.921)"
        fill="#1F1B29"
        stroke="#413954"
      />
      <path
        d="M36.9597 61.0374L38.1941 60.5333C39.8975 59.8377 42.5394 60.5937 43.4094 62.7242C44.2447 64.7695 43.0701 67.1834 41.2186 67.9396L33.8124 70.9641L28.7715 58.6204C32.4746 57.1081 35.0823 58.2035 36.9597 61.0374Z"
        stroke="#FF7700"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5723 61.3671L30.109 72.4764"
        stroke="#FF7700"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4065 73.9886L22.8779 65.348"
        stroke="#FF7700"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1996 74.2665L21.1914 71.7977"
        stroke="#FF7700"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="121.3"
        y="68.1534"
        width="47"
        height="47"
        rx="7.5"
        transform="rotate(22.5343 121.3 68.1534)"
        fill="#1F1B29"
        stroke="#413954"
      />
      <g clipPath="url(#clip0_348_5316)">
        <path
          d="M122.917 94.2664C122.313 95.722 122.002 97.2822 122.001 98.8581C122 100.434 122.309 101.995 122.911 103.451C123.513 104.907 124.397 106.23 125.51 107.345C126.624 108.46 127.946 109.345 129.402 109.949C130.857 110.553 132.418 110.864 133.993 110.865C135.569 110.866 137.13 110.557 138.586 109.955C140.042 109.352 141.366 108.469 142.481 107.356C143.596 106.242 144.48 104.92 145.084 103.464C145.688 102.009 146 100.448 146.001 98.8725C146.002 97.2966 145.692 95.736 145.09 94.2797C144.488 92.8234 143.605 91.5 142.491 90.3851C141.377 89.2701 140.055 88.3854 138.599 87.7815C137.144 87.1775 135.584 86.8662 134.008 86.8653C132.432 86.8643 130.871 87.1738 129.415 87.776C127.959 88.3781 126.635 89.2613 125.52 90.3749C124.405 91.4885 123.521 92.8109 122.917 94.2664Z"
          stroke="#1DB954"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M129.087 96.7881C132.919 96.2515 136.358 98.4002 138.055 101.269"
          stroke="#1DB954"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M128.773 101.027C131.131 100.562 134.21 101.839 134.675 104.198"
          stroke="#1DB954"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M129.376 92.6158C132.35 92.4062 137.787 93.2186 141.435 98.3414"
          stroke="#1DB954"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="70.5"
        y="18"
        width="47"
        height="47"
        rx="7.5"
        fill="#1F1B29"
        stroke="#413954"
      />
      <path
        d="M83.333 36.1667C83.333 34.7522 83.8949 33.3957 84.8951 32.3955C85.8953 31.3953 87.2519 30.8334 88.6663 30.8334H99.333C100.747 30.8334 102.104 31.3953 103.104 32.3955C104.104 33.3957 104.666 34.7522 104.666 36.1667V46.8334C104.666 48.2479 104.104 49.6044 103.104 50.6046C102.104 51.6048 100.747 52.1667 99.333 52.1667H88.6663C87.2519 52.1667 85.8953 51.6048 84.8951 50.6046C83.8949 49.6044 83.333 48.2479 83.333 46.8334V36.1667Z"
        stroke="#D62976"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90 41.5C90 42.5609 90.4214 43.5783 91.1716 44.3284C91.9217 45.0786 92.9391 45.5 94 45.5C95.0609 45.5 96.0783 45.0786 96.8284 44.3284C97.5786 43.5783 98 42.5609 98 41.5C98 40.4391 97.5786 39.4217 96.8284 38.6716C96.0783 37.9214 95.0609 37.5 94 37.5C92.9391 37.5 91.9217 37.9214 91.1716 38.6716C90.4214 39.4217 90 40.4391 90 41.5Z"
        stroke="#D62976"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100 35.5V35.5133"
        stroke="#D62976"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="70"
        y="70.5"
        width="47"
        height="3.88966"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M70 70.5486H99.5776C100.115 70.5486 100.55 70.9839 100.55 71.521V73.4658C100.55 74.0029 100.115 74.4382 99.5776 74.4382H70V70.5486Z"
        fill="url(#paint0_linear_348_5316)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_348_5316"
          x1="86.5843"
          y1="74.4382"
          x2="86.5843"
          y2="70.5486"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#027B66" />
          <stop offset="0.54" stopColor="#00E5BE" />
          <stop offset="0.98" stopColor="#027B66" />
        </linearGradient>
        <clipPath id="clip0_348_5316">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(125.354 77.9551) rotate(22.5343)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SocialSpeedometer;
