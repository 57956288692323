export const APP_ROUTES = {
  root: "/",

  // auth
  login: "/login",
  signup: "/signup",
  otpVerification: "/otp-verification",
  uploadProfileImage: "/upload-profile-image",
  onboardingQuestion: "/onboarding-question",
  forgotPassword: "/forgot-password",
  verify: "/verify",
  newPassword: "/new-password",
  resetPassword: "/reset-password",

  // private
  dashboard: "/home",
  eventAndTasks: "/tasks-and-projects",
  social: "/social",
  socialOverViewPage: "/social/overview",
  settings: "/settings",
  booking: "/booking",
  bookingSuccess: "/booking-success",
  badge: "/badge",
  callback: "/callback",
  instacallback: "/callback/instacallback",
  calendar: "/calendar",
  buzzFactory: "/buzz-factory",
};
