import React from "react";
import Button from "../../Button";

const SkipConfirmation = ({ onConfirm, onCancel }) => {
  return (
    <div className="absolute inset-0 bg-black/70 flex items-center justify-center z-20 rounded-2xl">
      <div className="bg-[#1F1B29] border border-[#2B2735] rounded-xl p-6 w-[80%] max-w-[300px]">
        <h3 className="text-white text-lg font-semibold mb-3">
          Skip Questionnaire?
        </h3>
        <p className="text-[#9E9CAE] mb-6">
          You're almost done! Are you sure you want to skip the rest of the
          questionnaire?
        </p>
        <div className="flex gap-3">
          <Button
            variety="outlined-primary"
            onClick={onCancel}
            className="flex-1"
          >
            Continue
          </Button>
          <Button variety="filled" onClick={onConfirm} className="flex-1">
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SkipConfirmation;
