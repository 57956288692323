import React from "react";
import classNames from "classnames";
import styles from "../QuestionnaireModal.module.css";

// Card component for showing questionnaire section cards
export const SectionCard = ({
  section,
  index,
  activeSection,
  activeSubSection,
  subsectionsLength,
  BadgeIcon,
}) => {
  // Determine the correct class based on section status
  const sectionClass = classNames(styles.card, {
    [styles.current]: index === activeSection,
    [styles.stacked]: index > activeSection,
  });

  const stackIndex = index - activeSection;

  const isCompleted = index < activeSection;
  const isInProgress = index === activeSection;
  const isPending = index > activeSection;

  return (
    <div
      className={sectionClass}
      style={
        index > activeSection
          ? {
              "--stack-index": stackIndex,
            }
          : undefined
      }
    >
      <div className="flex items-center gap-3">
        <div className="p-2 rounded-lg">
          {section.badgeInfo?.imageUrl ? (
            <img
              src={section.badgeInfo.imageUrl}
              alt={section.badgeInfo?.title || "Badge"}
              className="w-16 h-16"
            />
          ) : BadgeIcon ? (
            <BadgeIcon className="w-9 h-9" />
          ) : null}
        </div>
        <div className="flex-1">
          <h4 className="text-white font-semibold">
            {section.badgeInfo?.title || ""}
          </h4>
          <p className="text-[#9E9CAE] text-sm">
            Complete the "{section.title}" Section of the questionnaire.
          </p>
          <div className="mt-2 flex items-center gap-2">
            <div className="flex-1 h-1 bg-[#2B2735] rounded-full overflow-hidden">
              <div
                className={styles.progressBar}
                style={{
                  width: isCompleted
                    ? "100%"
                    : isInProgress
                    ? `${((activeSubSection + 1) / subsectionsLength) * 100}%`
                    : "0%",
                }}
              />
            </div>
            <span className="text-[#9E9CAE] text-xs flex items-center gap-1">
              {isCompleted ? (
                <>
                  <span
                    className={classNames(styles.statusDot, styles.completed)}
                  />
                  Completed
                </>
              ) : isInProgress ? (
                <>
                  <span
                    className={classNames(styles.statusDot, styles.inProgress)}
                  />
                  In progress
                </>
              ) : (
                <>
                  <span
                    className={classNames(styles.statusDot, styles.pending)}
                  />
                  Pending
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Component for showing floating completed cards
export const CompletedSectionCard = ({
  completedSection,
  isFadingOut,
  BadgeIcon,
}) => {
  return (
    <div
      className={classNames(styles.floatingCard, isFadingOut && styles.fadeOut)}
    >
      <div className={styles.completionBadge}>Completed!</div>
      <div className="flex items-center">
        <div className="bg-[#150F23] p-2 rounded-full mr-3">
          {completedSection.section.badgeInfo?.imageUrl ? (
            <img
              src={completedSection.section.badgeInfo.imageUrl}
              alt={completedSection.section.badgeInfo?.title || "Badge"}
              className="w-6 h-6"
            />
          ) : BadgeIcon ? (
            <BadgeIcon className="w-6 h-6" />
          ) : null}
        </div>
        <div className="flex-1">
          <h4 className="text-white font-semibold text-base">
            {completedSection.section.badgeInfo?.title || ""}
          </h4>
        </div>
      </div>
    </div>
  );
};

// NEW: Component for showing top completed cards
export const TopCompletedSectionCard = ({
  completedSection,
  isFadingOut,
  BadgeIcon,
}) => {
  return (
    <div
      className={classNames(
        "px-6 py-4 bg-[#251F34] rounded-b-lg",
        isFadingOut && "animate-fadeOut"
      )}
    >
      <div className="flex items-center gap-3">
        <div className="bg-[#1F1B29] p-2 rounded-lg">
          {completedSection.section.badgeInfo?.imageUrl ? (
            <img
              src={completedSection.section.badgeInfo.imageUrl}
              alt={completedSection.section.badgeInfo?.title || "Badge"}
              className="w-16 h-16"
            />
          ) : BadgeIcon ? (
            <BadgeIcon className="w-9 h-9" />
          ) : null}
        </div>
        <div className="flex-1">
          <h4 className="text-white font-semibold">
            {completedSection.section.badgeInfo?.title || ""}
          </h4>
          <p className="text-[#9E9CAE] text-sm">
            Complete the "{completedSection.section.title}" Section of the
            questionnaire.
          </p>
          <div className="mt-2 flex items-center gap-2">
            <div className="flex-1 h-1 bg-[#2B2735] rounded-full overflow-hidden">
              <div
                className={styles.progressBar}
                style={{
                  width: "100%",
                }}
              />
            </div>
            <span className="text-[#9E9CAE] text-xs flex items-center gap-1">
              <span
                className={classNames(styles.statusDot, styles.completed)}
              />
              Completed
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// NEW: Component for showing top completed cards container
export const TopCompletedSectionCards = ({
  completedSections,
  sectionsToFadeOut,
  BadgeIcon,
}) => {
  if (completedSections.length === 0) return null;

  return (
    <div className={styles.topCompletedCardsContainer}>
      {completedSections.map((completedSection) => (
        <TopCompletedSectionCard
          key={completedSection.index}
          completedSection={completedSection}
          isFadingOut={sectionsToFadeOut.includes(completedSection.index)}
          BadgeIcon={BadgeIcon}
        />
      ))}
    </div>
  );
};

// Component for showing floating completed cards container
export const CompletedSectionCards = ({
  completedSections,
  sectionsToFadeOut,
  BadgeIcon,
}) => {
  if (completedSections.length === 0) return null;

  return (
    <div className={styles.floatingCompletedCards}>
      {completedSections.map((completedSection) => (
        <CompletedSectionCard
          key={completedSection.index}
          completedSection={completedSection}
          isFadingOut={sectionsToFadeOut.includes(completedSection.index)}
          BadgeIcon={BadgeIcon}
        />
      ))}
    </div>
  );
};

// Card Stack component
export const CardStack = ({
  sections,
  activeSection,
  activeSubSection,
  subsections,
  BadgeIcon,
}) => {
  return (
    <div className={styles.cardStack}>
      {sections.map((section, sectionIndex) => {
        // Skip already completed sections
        if (sectionIndex < activeSection) {
          return null;
        }

        return (
          <SectionCard
            key={sectionIndex}
            section={section}
            index={sectionIndex}
            activeSection={activeSection}
            activeSubSection={activeSubSection}
            subsectionsLength={subsections.length}
            BadgeIcon={BadgeIcon}
          />
        );
      })}
    </div>
  );
};

export default {
  SectionCard,
  CompletedSectionCard,
  CompletedSectionCards,
  TopCompletedSectionCard,
  TopCompletedSectionCards,
  CardStack,
};
