"use client";

import { useEffect, useRef } from "react";
import { useInView, useMotionValue, useSpring } from "framer-motion";

import { cn } from "src/lib/utils";

export default function NumberTicker({
  value,
  direction = "up",
  delay = 0,
  className,
  decimals = 0,
}) {
  const ref = useRef(null);
  const motionValue = useMotionValue(direction === "down" ? value : 0);
  const springValue = useSpring(motionValue, {
    damping: 60,
    stiffness: 100,
  });
  const isInView = useInView(ref, { once: true, margin: "0px" });

  useEffect(() => {
    if (ref.current) {
      ref.current.textContent = "0";
    }
  }, []);

  useEffect(() => {
    isInView &&
      setTimeout(() => {
        motionValue.set(direction === "down" ? 0 : value);
      }, delay * 1000);
  }, [motionValue, isInView, delay, value, direction]);

  useEffect(
    () =>
      springValue.on("change", (latest) => {
        if (ref.current) {
          const isTargetValue = Math.abs(latest - (direction === "down" ? 0 : value)) < 0.0001;
          const formatted = isTargetValue
            ? Number(latest).toFixed(decimals)
            : latest.toFixed(decimals);

          // Use Intl.NumberFormat only for thousands separators, then add back fixed decimals
          const [whole, decimal] = formatted.split('.');
          const withThousands = Intl.NumberFormat("en-US").format(Number(whole));
          ref.current.textContent = decimal 
            ? `${withThousands}.${decimal}`
            : withThousands;
        }
      }),
    [springValue, decimals, direction, value]
  );

  return (
    <span
      className={cn(
        "inline-block tabular-nums text-white tracking-wider",
        className
      )}
      ref={ref}
    />
  );
}
