import React from "react";
import Skeleton from "./Skeleton";
import ProgressionBadgesSkeleton from "./ProgressionBadgesSkeleton";

const BuzzFactorySkeleton = () => {
  return (
    <div>
      {/* Header text skeleton */}
      <div className="mb-6">
        <Skeleton variant="text" className="w-96 h-6" />
      </div>

      {/* Main Content Container */}
      <div className="flex min-h-screen">
        {/* Left Side - Buzz Factory Card */}
        <div className="p-6 w-2/3">
          {/* Progress Track Skeleton */}
          <div className="mb-6">
            <Skeleton
              variant="rectangular"
              className="w-full h-4 rounded-full"
            />
          </div>

          {/* Tab Navigation Skeleton */}
          <div className="flex gap-4 mb-6">
            <Skeleton
              variant="rectangular"
              className="w-40 h-10 rounded-full"
            />
            <Skeleton
              variant="rectangular"
              className="w-40 h-10 rounded-full"
            />
          </div>

          {/* Main Content Skeleton */}
          <div className="space-y-6 p-8 bg-[#1F1B29] rounded-lg">
            {/* Header Section */}
            <div className="flex items-center gap-4 mb-4">
              <Skeleton variant="circular" className="w-16 h-16" />
              <div>
                <div className="flex items-center gap-1 mb-2">
                  <Skeleton variant="rectangular" className="w-20 h-4" />
                </div>
                <Skeleton variant="text" className="w-48 h-8" />
              </div>
            </div>

            {/* Content Section */}
            <div className="bg-[#262034] my-8 mx-5 p-[32px] rounded-2xl">
              <div className="flex items-center gap-4">
                <div className="flex items-start gap-12">
                  {/* Left side with social icons */}
                  <Skeleton variant="rectangular" className="w-48 h-48" />

                  {/* Right side content */}
                  <div className="flex-1">
                    <Skeleton variant="text" className="w-48 h-6 mb-4" />
                    <Skeleton variant="text" className="w-full h-4 mb-8" />
                    <Skeleton
                      variant="rectangular"
                      className="w-40 h-12 rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Stats Grid */}
            <div className="grid grid-cols-3 gap-4">
              {[...Array(3)].map((_, i) => (
                <div key={i} className="p-4 bg-[#262034] rounded-lg">
                  <Skeleton variant="text" className="w-24 h-4 mb-2" />
                  <Skeleton variant="text" className="w-16 h-6" />
                </div>
              ))}
            </div>

            {/* Activity Metrics */}
            <div className="grid grid-cols-4 gap-4 pt-8">
              {[...Array(4)].map((_, i) => (
                <div key={i} className="p-4 bg-[#262034] rounded-lg">
                  <Skeleton
                    variant="rectangular"
                    className="w-full h-32 rounded-lg"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Side - Progression Badges */}
        <div className="p-6 w-1/3">
          <ProgressionBadgesSkeleton />
        </div>
      </div>
    </div>
  );
};

export default BuzzFactorySkeleton;
