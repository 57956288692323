import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { COLORS } from "../constants";
import "./CompletionBadge.css";

/**
 * CompletionBadge displays an animated badge when a section is completed
 *
 * @param {Object} props - Component props
 * @param {string} props.title - The title of the badge
 * @param {string} props.description - Description text for the badge
 * @param {boolean} props.isVisible - Whether the badge should be visible and animate
 * @param {Function} props.icon - The icon component to render
 * @returns {React.Component} CompletionBadge component
 */
const CompletionBadge = ({
  title,
  description,
  isVisible,
  icon: BadgeIcon,
}) => {
  const [animationState, setAnimationState] = useState("hidden"); // hidden, popping, sliding, complete

  useEffect(() => {
    let timeoutId;

    if (isVisible) {
      // Start animation sequence
      setAnimationState("popping");

      // After popping, start sliding
      timeoutId = setTimeout(() => {
        setAnimationState("sliding");

        // After sliding, mark as complete
        timeoutId = setTimeout(() => {
          setAnimationState("complete");
        }, 600); // sliding duration
      }, 400); // popping duration
    } else {
      setAnimationState("hidden");
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isVisible]);

  if (animationState === "hidden") return null;

  return (
    <div className={`badge-${animationState} badge-stack badge-confetti`}>
      <div
        className="flex items-center gap-4 p-5 rounded-xl shadow-lg w-full max-w-[90%] md:max-w-[400px]"
        style={{
          backgroundColor: COLORS.background,
          borderColor: COLORS.border,
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      >
        {/* Confetti elements */}
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>

        {/* Badge Icon */}
        <div
          className="flex items-center justify-center w-12 h-12 rounded-lg text-white"
          style={{ backgroundColor: COLORS.primary }}
        >
          {BadgeIcon && <BadgeIcon />}
        </div>

        {/* Badge Content */}
        <div className="flex-1">
          <h3 className="font-bold text-lg text-white mb-1">{title}</h3>
          <p className="text-sm" style={{ color: COLORS.textPrimary }}>
            {description}
          </p>
        </div>

        {/* Complete indicator */}
        <div className="flex items-center">
          <div className="flex items-center justify-center w-6 h-6 rounded-full bg-[#10B981] text-white">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 3L4.5 8.5L2 6"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span className="ml-1 text-xs text-[#10B981]">Complete</span>
        </div>
      </div>
    </div>
  );
};

CompletionBadge.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  icon: PropTypes.elementType,
};

CompletionBadge.defaultProps = {
  isVisible: false,
};

export default CompletionBadge;
