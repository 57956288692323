import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import Button from "../Button";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { useMutationDelete } from "../../hooks/crud/mutation.delete";
import { API_END_POINTS } from "../../utils/api-endpoint";
import "./GoogleCalendar.css";
import { useDataQuery } from "src/hooks/crud/data.query";

const GoogleCalendar = ({
  setGoogleEvents,
  googleCalendarStatus,
  refetchDashboardData,
  dashboardLoading,
}) => {
  const [token, setToken] = useState(null);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [events, setEvents] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { mutate: authorizeGoogle, isLoading: authorizeGoogleLoading } =
    useMutationCreate(API_END_POINTS.googleCalendarAuth);

  const [isLoading, setIsLoading] = useState(false);

  const { mutate: unlinkGoogleCalendar, isLoading: unlinkGoogleLoading } =
    useMutationDelete(API_END_POINTS.googleCalendarUnlink);

  const [showUnlinkConfirmation, setShowUnlinkConfirmation] = useState(false);

  const {
    data: googleCalendarLinkedStatus,
    refetch: refetchGoogleCalendarLinkedStatus,
  } = useDataQuery({
    url: API_END_POINTS.googleCalendarLinkStatus,
    params: {},
  });

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsLoading(true);
      try {
        // Exchange the authorization code for tokens
        const response = await fetch("https://oauth2.googleapis.com/token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            code: tokenResponse.code,
            client_id:
              "314658481742-a7thi0t58qkbibqv2c8n8q8nv06sgsfq.apps.googleusercontent.com",
            client_secret: "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",
            redirect_uri: window.location.origin,
            grant_type: "authorization_code",
          }),
        });

        const tokens = await response.json();
        console.log("tokens", tokens);

        // Now you have both access_token and refresh_token
        setToken(tokens.access_token);
        await authorizeGoogle(
          {
            accessToken: tokens.access_token,
            refreshToken: tokens.refresh_token,
          },
          {
            onSuccess: () => {
              refetchDashboardData();
              refetchGoogleCalendarLinkedStatus();
            },
          }
        );
      } catch (error) {
        console.error("Error during Google Calendar sync:", error);
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      console.error("Login failed:", error);
      setIsLoading(false);
    },
    flow: "auth-code",
    access_type: "offline",
    prompt: "consent",
    scope:
      "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/tasks https://www.googleapis.com/auth/calendar.readonly",
  });

  const fetchCalendarList = async (token) => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/users/me/calendarList",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Calendar list:", data);
      return data.items || [];
    } catch (error) {
      console.error("Error fetching calendar list:", error);
      return [];
    }
  };
  // Get today's date and calculate one week later
  const getStartOfWeek = () => {
    const now = new Date();
    const startOfWeek = new Date(now.setHours(0, 0, 0, 0));
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to the beginning of the week
    return startOfWeek.toISOString();
  };

  const getEndOfWeek = () => {
    const now = new Date();
    const endOfWeek = new Date(now.setHours(23, 59, 59, 999));
    endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 6); // Set to the end of the week
    return endOfWeek.toISOString();
  };

  const handleCreateEvent = async () => {
    if (!token) {
      console.log("Please log in first");
      return;
    }

    const event = {
      summary: "New Task",
      description: "A new task created from React app",
      start: {
        dateTime: "2024-08-15T10:00:00-07:00", // Adjust date and time accordingly
        timeZone: "America/Los_Angeles",
      },
      end: {
        dateTime: "2024-08-15T10:30:00-07:00", // Adjust date and time accordingly
        timeZone: "America/Los_Angeles",
      },
    };

    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(event),
        }
      );

      const data = await response.json();
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  const createTask = async () => {
    if (!token) {
      console.log("Please log in first");
      return;
    }

    const task = {
      title: taskTitle,
      due: taskDueDate ? new Date(taskDueDate).toISOString() : undefined, // Optional due date
    };

    try {
      const response = await fetch(
        "https://www.googleapis.com/tasks/v1/lists/@default/tasks",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(task),
        }
      );

      const data = await response.json();
      console.log("Task created:", data);
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const fetchEvents = async (token) => {
    if (!token) {
      console.log("Please log in first");
      return;
    }

    const startOfWeek = getStartOfWeek();
    const endOfWeek = getEndOfWeek();

    try {
      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setEvents(data.items || []);

      setGoogleEvents(data.items || []);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleSyncClick = () => {
    // Show confirmation dialog when the button is clicked
    setShowConfirmation(true);
  };

  const handleConfirmSync = () => {
    // Proceed with login after user confirms
    setShowConfirmation(false);
    login();
  };

  const handleCancelSync = () => {
    // Hide the confirmation dialog when user cancels
    setShowConfirmation(false);
  };

  const handleUnlinkClick = () => {
    setShowUnlinkConfirmation(true);
  };

  const handleConfirmUnlink = async () => {
    setShowUnlinkConfirmation(false);
    try {
      await unlinkGoogleCalendar(
        {},
        {
          onSuccess: () => {
            refetchDashboardData();
            refetchGoogleCalendarLinkedStatus();
          },
        }
      );
    } catch (error) {
      console.error("Error unlinking calendar:", error);
    }
  };

  const handleCancelUnlink = () => {
    setShowUnlinkConfirmation(false);
  };

  return (
    <div className="google-calendar-container">
      {(authorizeGoogleLoading ||
        dashboardLoading ||
        isLoading ||
        unlinkGoogleLoading) && (
        <FullPageLoader
          message={
            googleCalendarStatus?.isLinked
              ? "Loading your Calendar..."
              : "Syncing with Google Calendar..."
          }
        />
      )}

      <div className="button-container">
        <Button
          variety="primary-outlined"
          className="primary-button black"
          height={"48px"}
          onClick={
            googleCalendarStatus?.isLinked ? handleUnlinkClick : handleSyncClick
          }
        >
          {googleCalendarStatus?.isLinked
            ? "Unlink Google Calendar"
            : "Sync Google Calendar"}
        </Button>

        {showConfirmation && (
          <>
            <div className="overlay" onClick={handleCancelSync}></div>
            <div className="confirmation-dialog">
              <p>
                You will sync with your Google Calendar account. Are you sure
                about that?
              </p>
              <Button variety="filled" onClick={handleConfirmSync}>
                Yes, continue
              </Button>
              <Button
                variety="filled"
                onClick={handleCancelSync}
                className="cancel-button"
              >
                Cancel
              </Button>
            </div>
          </>
        )}

        {showUnlinkConfirmation && (
          <>
            <div className="overlay" onClick={handleCancelUnlink}></div>
            <div className="confirmation-dialog">
              <p>Are you sure you want to unlink your Google Calendar?</p>
              <Button variety="filled" onClick={handleConfirmUnlink}>
                Yes, unlink
              </Button>
              <Button
                variety="filled"
                onClick={handleCancelUnlink}
                className="cancel-button"
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GoogleCalendar;
