import React from "react";
import classNames from "classnames";
import { ArrowLeftIcon } from "@radix-ui/react-icons";

const QuestionnaireHeader = ({
  currentSection,
  activeSection,
  activeSubSection,
  subsections,
  totalSections,
  totalPoints,
  BadgeIcon,
  onBack,
}) => {
  return (
    <>
      {/* Section Progress */}
      <div className="p-6 pb-2 shrink-0">
        <div className="flex items-center gap-2 mb-2">
          <button
            onClick={onBack}
            className="p-2 hover:bg-[#332A49] rounded-lg transition-colors mr-2"
            title="Go back"
          >
            <ArrowLeftIcon className="w-4 h-4 text-[#6F6C84]" />
          </button>
          <span className="text-[#6F6C84] text-[10px]">
            {activeSection + 1}/{totalSections} Questionnaires
          </span>
          {Array.from({ length: totalSections }).map((_, index) => (
            <div
              key={index}
              className={classNames(
                "w-[7px] h-[7px] rounded-[2px]",
                index === activeSection ? "bg-[#7C3AED]" : "bg-gray-600"
              )}
            />
          ))}
        </div>
        {/* Main Header */}
        <div className="p-6 shrink-0">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-2xl text-white font-medium">
              {currentSection.title}
            </h1>
          </div>
        </div>
      </div>

      {/* Section Title */}
      <div className="px-6 mb-4">
        {/* <h1 className="text-2xl font-bold text-white">
          {currentSection.title}
        </h1> */}

        {/* Questions Progress Bar - Individual Steps */}
        <div className="flex items-center gap-1 mt-4">
          {Array.from({ length: subsections.length }).map((_, index) => (
            <div
              key={index}
              className={classNames(
                "h-1 rounded-full flex-1 transition-colors duration-300",
                index <= activeSubSection ? "bg-[#7C3AED]" : "bg-[#2B2735]"
              )}
            />
          ))}
        </div>

        {/* Questions Counter */}
        <div className="flex items-center justify-between mb-2">
          <span className="text-[#6F6C84] text-sm">
            {activeSubSection + 1}/{subsections.length} Questions
          </span>
          <div className="flex items-center gap-2">
            {BadgeIcon && <BadgeIcon className="w-4 h-4 text-[#9E9CAE]" />}
            <span className="text-[#9E9CAE] text-sm">
              Earn {totalPoints} points
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireHeader;
