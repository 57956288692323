import React from "react";
import Button from "src/components/Button";

/**
 * A reusable CTA card component
 * @param {Object} props
 * @param {string} props.title - Card title
 * @param {string} props.description - Card description
 * @param {string} props.buttonText - Text for the CTA button
 * @param {Function} props.buttonAction - Function to execute when button is clicked
 * @param {string} [props.indicatorColor="#7C3AED"] - Color of the left indicator
 * @param {string} [props.buttonColor="#38B2AC"] - Color of the CTA button
 */
const CTACard = ({
  title,
  description,
  buttonText,
  buttonAction,
  indicatorColor = "#7C3AED",
  buttonColor = "#38B2AC",
}) => {
  return (
    <div className="flex items-center w-full gap-3 mb-4 bg-[#262034] rounded-xl py-4 px-5 border-l-[10px] border-[--Genesis-Blue-200]">
      <div className="flex flex-col gap-2 w-full">
        <h3 className="text-white font-semibold text-lg">{title}</h3>
        <p className="text-[#9E9CAE] text-sm mt-1">{description}</p>
      </div>
      <Button variety="filled" className="font-bold" onClick={buttonAction}>
        {buttonText}
      </Button>
    </div>
  );
};

export default CTACard;
