import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * StepIcon displays the icon for the current step in the onboarding flow
 *
 * @param {Object} props - Component props
 * @param {Function} props.Icon - The icon component to render
 * @param {string} props.background - The background class for the icon container
 * @returns {React.Component} StepIcon component
 */
const StepIcon = ({ Icon, background }) => {
  if (!Icon) return null;

  return (
    <div
      className={classNames(
        "rounded-t-2xl p-8 pb-4 flex items-center justify-center min-h-[261px]",
        background
      )}
    >
      <div className="relative">
        <Icon color="#fff" />
      </div>
    </div>
  );
};

StepIcon.propTypes = {
  Icon: PropTypes.elementType,
  background: PropTypes.string,
};

StepIcon.defaultProps = {
  background: "bg-[#7C3AED]",
};

export default StepIcon;
