import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * StepIndicator displays the current step progress in the onboarding flow
 *
 * @param {Object} props - Component props
 * @param {number} props.currentStep - The current active step index
 * @param {number} props.totalSteps - The total number of steps
 * @returns {React.Component} StepIndicator component
 */
const StepIndicator = ({ currentStep, totalSteps }) => {
  return (
    <div className="flex items-center gap-2 mb-4">
      <span className="text-[#6F6C84] text-[10px]">Getting Started</span>
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={classNames(
            "w-[7px] h-[7px] rounded-[2px]",
            index === currentStep ? "bg-[#7C3AED]" : "bg-gray-600"
          )}
        />
      ))}
    </div>
  );
};

StepIndicator.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default StepIndicator;
