import React, { useState } from "react";
import {
  BadgeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import {
  FirstTenFollowersBadge,
  RapidRiserBadge,
  ReleaseWizardBadge,
  PunctualPublisherBadge,
  BrandIdentityBadge,
  VisualStorytellerBadge,
  ConversationStarterBadge,
  NetworkingProBadge,
} from "../../assets/svg/ProgressionBadgeIcons";
import { Level1Icon } from "src/assets/svg/LevelIcon";

// Arrow component to show progression between badges
const ProgressionArrow = ({ direction = "right", color = "#3B82F6" }) => {
  return (
    <div className="flex items-center mx-4">
      <svg
        width="80"
        height="4"
        viewBox="0 0 80 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0"
          y1="2"
          x2="70"
          y2="2"
          stroke="#3ABFF8"
          strokeWidth="2"
          strokeDasharray="4 4"
        />
        <path d="M70 2L80 2" stroke="#3ABFF8" strokeWidth="2" />
      </svg>
    </div>
  );
};

// Badge component for displaying individual badges
const Badge = ({
  title,
  description,
  icon,
  progress,
  maxProgress,
  completed,
  locked,
}) => {
  return (
    <div
      className={`flex-shrink-0 flex flex-col items-center gap-2 ${
        locked ? "opacity-50" : ""
      }`}
    >
      <div className="relative w-24 h-24 flex items-center justify-center">
        {icon}
      </div>
      <div className="text-center w-32">
        <h3 className="text-white text-sm font-medium">{title}</h3>
        <p className="text-[#9E9CAE] text-xs mt-1">{description}</p>
        {progress !== undefined && (
          <div className="mt-2">
            <div className="w-full bg-[#2D2D3D] h-1 rounded-full overflow-hidden">
              <div
                className="bg-[#7C3AED] h-full rounded-full"
                style={{
                  width: `${Math.min(100, (progress / maxProgress) * 100)}%`,
                }}
              ></div>
            </div>
            <div className="text-[#9E9CAE] text-xs mt-1">
              {progress} {maxProgress ? `/ ${maxProgress}` : ""}{" "}
              {maxProgress ? "followers" : ""}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Grid Badge component for the "Your Badges" tab
const GridBadge = ({ title, icon, completed }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center justify-center">
        <img src={icon} alt={title} className="w-10 h-10" />
      </div>
      <h3 className="text-white text-sm font-medium text-center">{title}</h3>
    </div>
  );
};

// Section component for grouping related badges
const BadgeSection = ({ title, badges, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="py-6">
      <div
        className="flex items-center justify-between cursor-pointer bg-[#2B2735] py-[9.5px] px-[16px] border-b-1 border-[#6F6C84]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-white text-base font-medium">{title}</h2>
        <ChevronDownIcon
          className={`text-[#9E9CAE] w-5 h-5 transition-transform ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </div>

      {isOpen && (
        <div className="mt-6 overflow-x-auto px-6 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
          <div className="flex items-center min-w-max">
            {badges.map((badge, index) => (
              <React.Fragment key={index}>
                <Badge {...badge} />
                {index < badges.length - 1 && <ProgressionArrow />}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Tab component for individual tabs
const Tab = ({ label, isActive, onClick }) => {
  return (
    <div className="relative">
      <button
        className={`px-4 py-2 font-medium transition-all duration-200 ${
          isActive ? "text-white text-[20px]" : "text-[#9E9CAE] text-[14px]"
        }`}
        onClick={onClick}
      >
        {label}
      </button>
      {isActive && (
        <div className="absolute bottom-0 left-1/4 w-[20%] h-[2px] bg-[#7C3AED]"></div>
      )}
    </div>
  );
};

// Main component
const ProgressionBadges = ({ earnedBadges }) => {
  const [activeTab, setActiveTab] = useState("progression");

  const tabs = [
    { id: "progression", label: "Progression" },
    { id: "badges", label: "Your Badges" },
  ];

  // Sample data for the badges
  const badgeSections = [
    {
      title: "Social media growth",
      badges: [
        {
          title: "First Ten Followers",
          description: "Gain 10 followers after sign up",
          progress: 10,
          maxProgress: 10,
          completed: true,
          icon: <Level1Icon />,
        },
        {
          title: "Rapid Riser",
          description: "Gain 100 followers after sign up",
          progress: 10,
          maxProgress: 100,
          completed: false,
          icon: <Level1Icon />,
        },
        {
          title: "Social Butterfly",
          description: "Reach 500 followers",
          progress: 10,
          maxProgress: 500,
          completed: false,
          icon: <Level1Icon />,
        },
        {
          title: "Influencer Status",
          description: "Achieve 1000 followers",
          progress: 10,
          maxProgress: 1000,
          completed: false,
          icon: <Level1Icon />,
        },
        {
          title: "Viral Sensation",
          description: "Hit 5000 followers",
          progress: 10,
          maxProgress: 5000,
          completed: false,
          icon: <Level1Icon />,
        },
      ],
    },
    {
      title: "Building a buzz",
      badges: [
        {
          title: "Release wizard",
          description: "Release your first single",
          completed: false,
          icon: <Level1Icon />,
        },
        {
          title: "Punctual Publisher",
          description: "Two consecutive releases on schedule",
          progress: 0,
          maxProgress: 2,
          completed: false,
          icon: <Level1Icon />,
        },
      ],
    },
    {
      title: "Defining your brand",
      badges: [
        {
          title: "Brand Identity",
          description: "Complete your artist profile",
          completed: false,
          icon: <Level1Icon />,
        },
        {
          title: "Visual Storyteller",
          description: "Upload your first artist photo",
          completed: false,
          icon: <Level1Icon />,
        },
      ],
    },
    {
      title: "Community & outreach",
      badges: [
        {
          title: "Conversation Starter",
          description: "Reply to 5 fan comments",
          progress: 0,
          maxProgress: 5,
          completed: false,
          icon: <Level1Icon />,
        },
        {
          title: "Networking Pro",
          description: "Connect with 3 other artists",
          progress: 0,
          maxProgress: 3,
          completed: false,
          icon: <Level1Icon />,
        },
      ],
    },
  ];

  // Get all badges for the "Your Badges" tab
  const allBadges = badgeSections.flatMap((section) => section.badges);
  // const earnedBadges = allBadges.filter((badge) => badge.completed);

  return (
    <div className="bg-[#1F1B29] rounded-[16px] h-full">
      {/* Tabs */}
      <div className="flex pt-6">
        <div className="flex ">
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              isActive={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)}
            />
          ))}
        </div>
      </div>

      {/* Content based on active tab */}
      {activeTab === "progression" ? (
        <div className="py-4 space-y-2 overflow-y-auto max-h-[calc(100vh-200px)]">
          {badgeSections.map((section, index) => (
            <BadgeSection
              key={index}
              title={section.title}
              badges={section.badges}
              defaultOpen={index === 0}
            />
          ))}
        </div>
      ) : (
        <div className="py-4 px-6 space-y-2 overflow-y-auto max-h-[calc(100vh-200px)]">
          <h2 className="text-white text-xl font-semibold mb-4">
            Earned Badges ({earnedBadges.length})
          </h2>
          {console.log("earnedBadges", earnedBadges)}
          {earnedBadges.length > 0 ? (
            <div className="grid grid-cols-3 gap-6">
              {earnedBadges.map((badge, index) => (
                <GridBadge
                  key={index}
                  title={badge?.badge?.title}
                  icon={badge?.badge?.iconURL}
                  completed={badge?.badge?.completed}
                />
              ))}
            </div>
          ) : (
            <div className="text-center py-8">
              <p className="text-[#9E9CAE]">
                You haven't earned any badges yet.
              </p>
              <p className="text-[#9E9CAE] mt-2">
                Complete tasks to earn badges and track your progress!
              </p>
            </div>
          )}

          <h2 className="text-white text-xl font-semibold mt-8 mb-4">
            Available Badges ({allBadges.length - earnedBadges.length})
          </h2>
          <div className="grid grid-cols-3 gap-6">
            {allBadges
              .filter((badge) => !badge.completed)
              .map((badge, index) => (
                <GridBadge
                  key={index}
                  title={badge.title}
                  icon={badge.icon}
                  completed={badge.completed}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressionBadges;
