import React from "react";

const TabSkeleton = () => (
  <div className="flex gap-4 border-b border-[#2D2D3D] mb-6">
    <div className="h-10 w-32 bg-[#2D2D3D] rounded-md"></div>
    <div className="h-10 w-32 bg-[#2D2D3D] rounded-md opacity-50"></div>
  </div>
);

const BadgeSkeleton = () => (
  <div className="flex-shrink-0 flex flex-col items-center gap-2 w-32">
    <div className="w-24 h-24 bg-[#2D2D3D] rounded-lg"></div>
    <div className="w-full flex flex-col items-center gap-2">
      <div className="h-4 w-24 bg-[#2D2D3D] rounded"></div>
      <div className="h-3 w-20 bg-[#2D2D3D] rounded opacity-50"></div>
      <div className="w-full space-y-2">
        <div className="w-full h-1 bg-[#2D2D3D] rounded-full"></div>
        <div className="h-3 w-16 bg-[#2D2D3D] rounded mx-auto"></div>
      </div>
    </div>
  </div>
);

const ProgressionArrowSkeleton = () => (
  <div className="flex items-center mx-4">
    <div className="w-20 h-1 bg-[#2D2D3D] rounded"></div>
  </div>
);

const BadgeSectionSkeleton = () => (
  <div className="py-6">
    <div className="flex items-center justify-between bg-[#2B2735] py-[9.5px] px-[16px] border-b-1 border-[#6F6C84]">
      <div className="h-6 w-48 bg-[#2D2D3D] rounded"></div>
      <div className="h-5 w-5 bg-[#2D2D3D] rounded"></div>
    </div>
    <div className="mt-6 overflow-x-auto px-6">
      <div className="flex items-center min-w-max">
        {[1, 2, 3, 4].map((i) => (
          <React.Fragment key={i}>
            <BadgeSkeleton />
            {i < 4 && <ProgressionArrowSkeleton />}
          </React.Fragment>
        ))}
      </div>
    </div>
  </div>
);

const ProgressionBadgesSkeleton = () => {
  return (
    <div className="bg-[#1F1B29] rounded-lg p-6">
      <TabSkeleton />
      <div className="space-y-6">
        {[1, 2, 3, 4].map((i) => (
          <BadgeSectionSkeleton key={i} />
        ))}
      </div>
    </div>
  );
};

export default ProgressionBadgesSkeleton;
