/**
 * Position classes for modal placement
 * Maps position names to Tailwind CSS classes for flex positioning
 */
export const POSITION_CLASSES = {
  center: "items-center justify-center",
  "bottom-right": "items-end justify-end pb-4 pr-4",
  "bottom-left": "items-end justify-start pb-4 pl-4",
  "top-right": "items-start justify-end pt-4 pr-4",
  "top-left": "items-start justify-start pt-4 pl-4",
  "bottom-center": "items-end justify-center pb-4",
  "top-center": "items-start justify-center pt-4",
};

/**
 * Valid position values for the OnboardingModal
 */
export const VALID_POSITIONS = Object.keys(POSITION_CLASSES);

/**
 * Default theme colors
 */
export const COLORS = {
  primary: "#7C3AED",
  textPrimary: "#E6E6EA",
  textSecondary: "#6F6C84",
  background: "#1F1B29",
  border: "#2B2735",
};
