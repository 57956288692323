import React from "react";

const Speedometer = ({
  value,
  label,
  subtext = "",
  period = "",
  maxValue = 100,
  size = 135.27, // Updated size to match width
  color = "#00E1BA", // Updated default green color
}) => {
  // Calculate the rotation angle based on value and maxValue
  // For a semicircle, we want to go from -90 to 90 degrees
  const rotation = -90 + (180 * Math.min(value, maxValue)) / maxValue;

  // Calculate dash values for segments
  // Total arc length is approximately 180 for semicircle
  const warningSegmentLength = 180 * 0.05; // 5% of the track
  const successSegmentLength = 180 * 0.4; // 40% of the track (from 50% to 90%)
  const successSegmentOffset = 180 * 0.5; // Start at 50% of the track

  return (
    <div className="flex flex-col items-center bg-[#1F1B29]">
      {/* Speedometer */}
      <div className="relative" style={{ width: size, height: 66.42 }}>
        {/* Background Arc - adjusted viewBox to match new proportions */}
        <svg viewBox="0 0 135.27 66.42" className="w-full h-full">
          {/* Base track - adjusted coordinates for new viewBox */}
          <path
            d="M 13.527 59.778 A 54.108 54.108 0 0 1 121.743 59.778"
            fill="none"
            stroke="#312944"
            strokeWidth="11"
            strokeLinecap="round"
            strokeDasharray="180"
            strokeDashoffset="0"
          />

          {/* Warning segment */}
          <path
            d="M 13.527 59.778 A 54.108 54.108 0 0 1 121.743 59.778"
            fill="none"
            stroke="#EF4870"
            strokeWidth="11"
            strokeLinecap="round"
            strokeDasharray={`${warningSegmentLength} ${
              180 - warningSegmentLength
            }`}
            strokeDashoffset="180"
          />

          {/* Success segment */}
          <path
            d="M 13.527 59.778 A 54.108 54.108 0 0 1 121.743 59.778"
            fill="none"
            stroke={color}
            strokeWidth="11"
            strokeLinecap="round"
            strokeDasharray={`${successSegmentLength} ${
              180 - successSegmentLength
            }`}
            strokeDashoffset={180 - successSegmentOffset}
          />

          {/* Progress indicator */}
          <path
            d="M 13.527 59.778 A 54.108 54.108 0 0 1 121.743 59.778"
            fill="none"
            stroke={color}
            strokeWidth="11"
            strokeLinecap="round"
            strokeDasharray={`${
              (180 * Math.min(value, maxValue)) / maxValue
            } ${180}`}
            strokeDashoffset="180"
            className="transition-all duration-700 ease-in-out"
          />

          {/* Needle */}
          <g transform={`rotate(${rotation} 67.635 59.778)`}>
            {/* Needle body */}
            <path
              d="M 63.635 59.778 L 67.635 33.21 L 71.635 59.778 Z"
              fill="#52476D"
              className="transition-all duration-700 ease-in-out"
            />
            {/* Center circle */}
            <circle cx="67.635" cy="59.778" r="5.5" fill="#52476D" />
          </g>
        </svg>
      </div>

      {/* Labels */}
      <div className="text-[#6F6C84] text-sm pt-6">{label}</div>
      <div className="flex mt-1 gap-1">
        <span className="text-[#E6E6EA] text-[32px] font-bold">{value}</span>
        {subtext && (
          <span className="text-[#E6E6EA] text-[20px] font-bold">
            {subtext}
          </span>
        )}
      </div>
      {period && <div className="text-gray-500 text-xs mt-1">{period}</div>}
    </div>
  );
};

export default Speedometer;
