import React from "react";
import PropTypes from "prop-types";
import Button from "src/components/Button";

/**
 * NavigationButtons provides the navigation controls for the onboarding flow
 *
 * @param {Object} props - Component props
 * @param {number} props.currentStep - The current active step index
 * @param {boolean} props.isFinalStep - Whether this is the final step
 * @param {Function} props.onNext - Handler for next button click
 * @param {Function} props.onBack - Handler for back button click
 * @returns {React.Component} NavigationButtons component
 */
const NavigationButtons = ({ currentStep, isFinalStep, onNext, onBack }) => {
  return (
    <div className="flex justify-end gap-4 absolute bottom-0 left-0 right-0 p-8 border-t border-[#2B2735] bg-[#1F1B29] rounded-b-2xl">
      {currentStep > 0 && (
        <Button
          variety="outlined-primary"
          onClick={onBack}
          className="w-1/4 rounded-lg"
        >
          Back
        </Button>
      )}
      <Button variety="filled" onClick={onNext} className="w-1/4 rounded-lg">
        {isFinalStep ? "Let's go!" : "Continue"}
      </Button>
    </div>
  );
};

NavigationButtons.propTypes = {
  currentStep: PropTypes.number.isRequired,
  isFinalStep: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

NavigationButtons.defaultProps = {
  isFinalStep: false,
};

export default NavigationButtons;
