import React from "react";
import PropTypes from "prop-types";

/**
 * HexagonBadgeIcon displays a hexagonal badge icon similar to the one in the image
 *
 * @param {Object} props - Component props
 * @param {string} props.color - The color of the icon
 * @returns {React.Component} HexagonBadgeIcon component
 */
const HexagonBadgeIcon = ({ color = "#fff" }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Hexagon shape */}
      <path
        d="M24 2L42.7846 12V34L24 44L5.21539 34V12L24 2Z"
        stroke={color}
        strokeWidth="2"
        fill="none"
      />

      {/* Trophy icon inside */}
      <path
        d="M30.5 15H33C33.8284 15 34.5 15.6716 34.5 16.5V18C34.5 20.4853 32.4853 22.5 30 22.5V22.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 15H15C14.1716 15 13.5 15.6716 13.5 16.5V18C13.5 20.4853 15.5147 22.5 18 22.5V22.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 31.5H28.5L27 34.5H21L19.5 31.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 28.5V31.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 22.5H18C18 26.6421 21.3579 30 25.5 30V30C29.6421 30 33 26.6421 33 22.5H30Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

HexagonBadgeIcon.propTypes = {
  color: PropTypes.string,
};

HexagonBadgeIcon.defaultProps = {
  color: "#fff",
};

export default HexagonBadgeIcon;
