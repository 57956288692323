export const EmptyGoals = () => {
  return (
    <svg
      width="167"
      height="152"
      viewBox="0 0 167 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.885 141.635C155.885 132.753 154.136 123.958 150.737 115.752C147.338 107.546 142.356 100.09 136.075 93.8098C129.795 87.5293 122.339 82.5474 114.133 79.1484C105.927 75.7494 97.1322 74 88.2502 74C79.3683 74 70.5733 75.7494 62.3674 79.1484C54.1616 82.5474 46.7056 87.5293 40.4251 93.8098C34.1446 100.09 29.1626 107.546 25.7636 115.752C22.3647 123.958 20.6152 132.753 20.6152 141.635H31.1257C31.1257 134.133 32.6033 126.705 35.4741 119.774C38.3448 112.844 42.5526 106.546 47.8571 101.242C53.1616 95.9374 59.459 91.7296 66.3896 88.8588C73.3203 85.988 80.7485 84.5105 88.2502 84.5105C95.7519 84.5105 103.18 85.988 110.111 88.8588C117.042 91.7296 123.339 95.9374 128.643 101.242C133.948 106.546 138.156 112.844 141.026 119.774C143.897 126.705 145.375 134.133 145.375 141.635H155.885Z"
        fill="#413954"
      />
      <path
        d="M152.541 120.63C148.329 107.739 140.34 96.4121 129.609 88.1193C118.879 79.8264 105.903 74.9508 92.3668 74.1254L91.7272 84.6144C103.16 85.3116 114.12 89.4297 123.183 96.434C132.246 103.438 138.995 113.006 142.552 123.894L152.541 120.63Z"
        fill="#00E1BA"
      />
      <path
        d="M21.7402 129.35C20.979 133.472 20.6025 137.655 20.6156 141.846L31.124 141.813C31.113 138.273 31.431 134.74 32.074 131.259L21.7402 129.35Z"
        fill="#EF4870"
      />
      <path
        d="M121.816 117.875L86.343 128.598C85.9396 128.72 85.5908 128.978 85.3555 129.327L80.7953 136.103C80.0181 137.257 80.7651 138.822 82.1516 138.944L89.9095 139.626C90.2769 139.659 90.6455 139.579 90.9668 139.398L123.242 121.217C125.145 120.146 123.906 117.243 121.816 117.875Z"
        fill="#413954"
      />
      <rect
        x="0.651922"
        y="34.421"
        width="47"
        height="47"
        rx="7.5"
        transform="rotate(-22.2139 0.651922 34.421)"
        fill="#1F1B29"
      />
      <rect
        x="0.651922"
        y="34.421"
        width="47"
        height="47"
        rx="7.5"
        transform="rotate(-22.2139 0.651922 34.421)"
        stroke="#413954"
      />
      <path
        d="M36.9597 43.5374L38.1941 43.0333C39.8975 42.3377 42.5394 43.0937 43.4094 45.2242C44.2447 47.2695 43.0701 49.6834 41.2186 50.4396L33.8124 53.4641L28.7715 41.1204C32.4746 39.6081 35.0823 40.7035 36.9597 43.5374Z"
        stroke="#FF7700"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.5723 43.8671L30.109 54.9764"
        stroke="#FF7700"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.4065 56.4886L22.8779 47.848"
        stroke="#FF7700"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.1996 56.7665L21.1914 54.2977"
        stroke="#FF7700"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="121.3"
        y="50.6534"
        width="47"
        height="47"
        rx="7.5"
        transform="rotate(22.5343 121.3 50.6534)"
        fill="#1F1B29"
      />
      <rect
        x="121.3"
        y="50.6534"
        width="47"
        height="47"
        rx="7.5"
        transform="rotate(22.5343 121.3 50.6534)"
        stroke="#413954"
      />
      <g clip-path="url(#clip0_378_5532)">
        <path
          d="M122.917 76.7664C122.313 78.222 122.002 79.7822 122.001 81.3581C122 82.9339 122.309 84.4945 122.911 85.9508C123.513 87.4071 124.397 88.7305 125.51 89.8455C126.624 90.9604 127.946 91.8451 129.402 92.4491C130.857 93.053 132.418 93.3643 133.993 93.3653C135.569 93.3662 137.13 93.0567 138.586 92.4546C140.042 91.8524 141.366 90.9693 142.481 89.8556C143.596 88.742 144.48 87.4197 145.084 85.9641C145.688 84.5086 146 82.9483 146.001 81.3725C146.002 79.7966 145.692 78.236 145.09 76.7797C144.488 75.3234 143.605 74 142.491 72.8851C141.377 71.7701 140.055 70.8854 138.599 70.2815C137.144 69.6775 135.584 69.3662 134.008 69.3653C132.432 69.3643 130.871 69.6738 129.415 70.276C127.959 70.8781 126.635 71.7613 125.52 72.8749C124.405 73.9885 123.521 75.3109 122.917 76.7664Z"
          stroke="#1DB954"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M129.087 79.2881C132.919 78.7515 136.358 80.9002 138.055 83.7694"
          stroke="#1DB954"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M128.773 83.5269C131.131 83.0618 134.21 84.3393 134.675 86.6976"
          stroke="#1DB954"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M129.376 75.1158C132.35 74.9062 137.787 75.7186 141.435 80.8414"
          stroke="#1DB954"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <rect x="70.5" y="0.5" width="47" height="47" rx="7.5" fill="#1F1B29" />
      <rect x="70.5" y="0.5" width="47" height="47" rx="7.5" stroke="#413954" />
      <path
        d="M83.333 18.6667C83.333 17.2522 83.8949 15.8957 84.8951 14.8955C85.8953 13.8953 87.2519 13.3334 88.6663 13.3334H99.333C100.747 13.3334 102.104 13.8953 103.104 14.8955C104.104 15.8957 104.666 17.2522 104.666 18.6667V29.3334C104.666 30.7479 104.104 32.1044 103.104 33.1046C102.104 34.1048 100.747 34.6667 99.333 34.6667H88.6663C87.2519 34.6667 85.8953 34.1048 84.8951 33.1046C83.8949 32.1044 83.333 30.7479 83.333 29.3334V18.6667Z"
        stroke="#D62976"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M90 24C90 25.0609 90.4214 26.0783 91.1716 26.8284C91.9217 27.5786 92.9391 28 94 28C95.0609 28 96.0783 27.5786 96.8284 26.8284C97.5786 26.0783 98 25.0609 98 24C98 22.9391 97.5786 21.9217 96.8284 21.1716C96.0783 20.4214 95.0609 20 94 20C92.9391 20 91.9217 20.4214 91.1716 21.1716C90.4214 21.9217 90 22.9391 90 24Z"
        stroke="#D62976"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M100 18V18.0133"
        stroke="#D62976"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="70"
        y="53"
        width="47"
        height="3.88966"
        fill="white"
        fill-opacity="0.4"
      />
      <path
        d="M70 53.0486H99.5776C100.115 53.0486 100.55 53.4839 100.55 54.021V55.9658C100.55 56.5029 100.115 56.9382 99.5776 56.9382H70V53.0486Z"
        fill="url(#paint0_linear_378_5532)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_378_5532"
          x1="86.5843"
          y1="56.9382"
          x2="86.5843"
          y2="53.0486"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#027B66" />
          <stop offset="0.54" stop-color="#00E5BE" />
          <stop offset="0.98" stop-color="#027B66" />
        </linearGradient>
        <clipPath id="clip0_378_5532">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(125.354 60.4551) rotate(22.5343)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
