import { Cross1Icon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import { CheckCircle } from "../../assets/svg/CheckCircle";
import { DeleteOutlined } from "../../assets/svg/DeleteOutlined";
import Button from "../Button";
import Dialog from "../Dialog";

const Modal = ({
  isOpen,
  onClose,
  children,
  className,
  title,
  handleMark,
  completed,
  handleDeleteTask,
  isEditButton,
  onEditClick,
  completionStatus,
  showActions = true,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black/70 flex justify-center items-center z-[999]">
      <div
        className={`${className} w-[95%] md:w-[80%] lg:w-[60%] h-[90%] md:h-[70%] bg-[rgb(30,26,42)] relative flex flex-col overflow-hidden rounded-md`}
      >
        <div
          className={`${className} flex items-center justify-between p-16 border-b border-gray-700`}
        >
          {title && (
            <span className="text-white text-lg truncate flex-1">{title}</span>
          )}
          <div className={`flex items-center gap-2 ml-4`}>
            {showActions && (
              <>
                {completionStatus && (
                  <div
                    className="flex items-center cursor-pointer hover:opacity-80"
                    onClick={handleMark}
                  >
                    <CheckCircle />
                    <span className="text-white ml-2 hidden sm:inline whitespace-nowrap">
                      {completed ? "Completed" : "Mark as complete"}
                    </span>
                  </div>
                )}
                {isEditButton && (
                  <div className="shrink-0">
                    <Button onClick={onEditClick} variety="filled">
                      Edit
                    </Button>
                  </div>
                )}
                {handleDeleteTask && (
                  <div
                    className="cursor-pointer p-2 hover:opacity-80 shrink-0"
                    onClick={handleDeleteTask}
                  >
                    <DeleteOutlined />
                  </div>
                )}
              </>
            )}
            <button onClick={onClose} className="p-2 hover:opacity-80 shrink-0">
              <Cross1Icon color="white" />
            </button>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
            position: "relative",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;

export const CreateModal = ({
  isOpen,
  onClose,
  children,
  className,
  title,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  if (!isOpen) {
    return null;
  }

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowConfirmation(true);
  };

  const handleConfirmClose = () => {
    setShowConfirmation(false);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/70 flex justify-center items-center z-[99]">
      <div
        className={`${className} w-[95%] md:w-[700px] bg-[rgb(30,26,42)] relative rounded-md flex flex-col max-h-[90vh]`}
      >
        <div className="flex items-center justify-between p-16 border-b border-gray-700">
          {title && (
            <span className="text-white text-lg truncate flex-1">{title}</span>
          )}
          <div
            className="cursor-pointer p-2 hover:opacity-80 shrink-0"
            onClick={handleClose}
          >
            <Cross1Icon color="white" />
          </div>
        </div>
        {children}

        {/* Confirmation Dialog */}
        <Dialog
          show={showConfirmation}
          action1ButtonLabel="Cancel"
          action2ButtonLabel="Confirm"
          handelAction1={() => setShowConfirmation(false)}
          handelAction2={handleConfirmClose}
        >
          <div className="flex-col justify-center align-middle">
            <div
              style={{
                background: "rgba(37, 31, 52, 1)",
                width: 100,
                height: 100,
                borderRadius: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 20,
                left: "40%",
              }}
            >
              <Cross1Icon width={40} height={40} color="white" />
            </div>
            <div className="pt-28">
              <h3 className="white text-align-center">Are you sure?</h3>
              <h5 className="white text-align-center">
                You will lose any unsaved changes.
              </h5>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
