import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { COLORS } from "../constants";
import "./CompletionBadge.css";

/**
 * QuestionnaireBadge displays an animated badge when a questionnaire section is completed
 * This is styled to match the design in the provided image
 *
 * @param {Object} props - Component props
 * @param {string} props.title - The title of the badge ("Goal Getter!")
 * @param {string} props.description - Description text for the badge (questionnaire section name)
 * @param {boolean} props.isVisible - Whether the badge should be visible and animate
 * @param {Function} props.icon - The icon component to render
 * @returns {React.Component} QuestionnaireBadge component
 */
const QuestionnaireBadge = ({
  title,
  description,
  isVisible,
  icon: BadgeIcon,
}) => {
  const [animationState, setAnimationState] = useState("hidden"); // hidden, popping, sliding, complete

  useEffect(() => {
    let timeoutId;

    if (isVisible) {
      // Start animation sequence
      setAnimationState("popping");

      // After popping, start sliding
      timeoutId = setTimeout(() => {
        setAnimationState("sliding");

        // After sliding, mark as complete
        timeoutId = setTimeout(() => {
          setAnimationState("complete");
        }, 600); // sliding duration
      }, 400); // popping duration
    } else {
      setAnimationState("hidden");
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isVisible]);

  if (animationState === "hidden") return null;

  return (
    <div className={`badge-${animationState} badge-stack badge-confetti`}>
      <div
        className="rounded-xl shadow-lg w-full max-w-[360px] overflow-hidden"
        style={{
          backgroundColor: "#1f1f33",
          borderColor: "#2B2735",
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      >
        {/* Confetti elements */}
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>

        {/* Badge content */}
        <div className="p-4 flex items-center gap-4">
          {/* Badge Icon */}
          <div className="flex items-center justify-center w-14 h-14 rounded-xl text-white">
            {BadgeIcon && <BadgeIcon />}
          </div>

          {/* Badge Content */}
          <div className="flex-1">
            <h3 className="font-bold text-xl text-white mb-1">{title}</h3>
            <p className="text-sm text-gray-300">{description}</p>
          </div>
        </div>

        {/* Progress bar */}
        <div className="relative h-1 w-full bg-gray-700">
          <div
            className="absolute left-0 top-0 h-full bg-[#10B981]"
            style={{ width: "100%" }}
          />
        </div>

        {/* Complete indicator */}
        <div className="flex items-center justify-end p-1 pr-3">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-5 h-5 rounded-full bg-[#10B981] text-white">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 3L4.5 8.5L2 6"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="ml-1 text-xs text-[#10B981]">Complete</span>
          </div>
        </div>
      </div>
    </div>
  );
};

QuestionnaireBadge.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  icon: PropTypes.elementType,
};

QuestionnaireBadge.defaultProps = {
  isVisible: false,
};

export default QuestionnaireBadge;
