import React, { useState, useCallback, useMemo } from "react";
import { ImpressionIcon } from "../../assets/svg/ImpressionIcon";
import { UserIcon } from "../../assets/svg/UserIcon";
import Card from "../../components/Card/Card";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { countryInitialsToName } from "../../utils/countryInitials";
import Button from "../../components/Button";
import { AddPreIcon } from "../../assets/svg/AddPreIcon";
import { InstagramIcon } from "../../assets/svg/InstagramIcon";
import { periodDataType } from "./data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SpotifyIcon } from "../../assets/svg/SpotifyIcon";
import Dialog from "../../components/Dialog/Dialog";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { notify } from "../../components/Toast/ToastComponent";
import { APP_ROUTES } from "../../utils/app-routes";
import Chart from "../../components/Chart/LineChart";
import FormSelect from "../../components/Select/FormSelect";
import { useForm } from "react-hook-form";
import moment from "moment";
import { ChartDropDown } from "../../constants/constant";
import { BallTriangleLoader } from "../../components/Loader/Loader";
import NumberTicker from "src/components/magicui/number-ticker";
import {
  ChevronLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CalendarIcon,
  PlayIcon,
} from "@radix-ui/react-icons";
import { EmptySocial } from "../../assets/svg/EmptySocial";
import queryString from "query-string";
import ProgressChart from "../../components/ProgressChart/ProgressChart";
import SocialChart from "../../components/SocialChart/SocialChart";
import Dropdown from "../../components/Dropdown/Dropdown";
import LabeledDropdown from "../../components/StatusDropdown/LabeledDropdown";
import Modal from "../../components/Modal/Modal";
import { TrackModalViewDetails } from "../../components/Dashboard/ModalView";

const SocialOverviewPage = () => {
  const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_ENDPOINT = process.env.REACT_APP_SPOTIFY_AUTH_ENDPOINT;
  const RESPONSE_TYPE = "code";
  // const RESPONSE_TYPE = process.env.REACT_APP_SPOTIFY_RESPONSE_TYPE;
  const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI;
  const { platform } = useParams();
  const location = useLocation();
  const isLinked = location?.state?.isLinked;
  const navigate = useNavigate();
  const [selectedDuration, setSelectedDuration] = React.useState("today");
  const [confirmationDialog, setConfirmationDialog] = React.useState(false);
  const [selectedChartValue, setSelectedChartValue] = useState("Weekly");
  const [dataFormatType, setDataFormatType] = useState("val");
  const [showAllTracks, setShowAllTracks] = useState(false);
  const [trackSortOption, setTrackSortOption] = useState({
    value: "release_date",
    label: "Release Date",
  });
  const TRACK_SORT_OPTIONS = [
    {
      value: "release_date",
      label: "Release Date",
      icon: CalendarIcon,
      color: "#E2E2E4",
    },
    {
      value: "streams",
      label: "Total Streams",
      icon: PlayIcon,
      color: "#E2E2E4",
    },
  ];
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});
  const {
    data: userData,
    isFetching,
    isLoading,
    refetch: refetchUserData,
  } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });
  // Memoize the query parameters to prevent unnecessary re-fetches
  const chartQueryParams = useMemo(
    () => ({
      social: platform,
      startDate:
        selectedChartValue === "Monthly"
          ? moment().subtract(1, "month").format("YYYY-MM-DD")
          : selectedChartValue === "Yearly"
          ? moment().subtract(1, "year").format("YYYY-MM-DD")
          : moment().subtract(6, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      view: dataFormatType,
    }),
    [platform, selectedChartValue, dataFormatType]
  );
  const {
    data: chartData,
    isLoading: isLoadingSocialData,
    isFetching: isSocialDataFetching,
    error: isErrorOverview,
  } = useDataQuery({
    url: API_END_POINTS.charts,
    params: chartQueryParams,
  });
  const { mutate: unlinkPlatform } = useMutationCreate(
    API_END_POINTS.unlinkSocial
  );
  // const { refetch: refetchUserData } = useDataQuery(API_END_POINTS.profile);
  let apiEndpoint;
  switch (platform) {
    case "instagram":
      apiEndpoint = API_END_POINTS.instagramOverview;
      break;
    // Add more cases for other platforms...
    default:
      apiEndpoint = API_END_POINTS.spotifyOverview; // Replace with your default API endpoint
  }
  const { data: overviewData } = useDataQuery({
    url: apiEndpoint,
    params: {
      period: selectedDuration === "today" ? "" : selectedDuration,
    },
  });
  const [selectedTrack, setSelectedTrack] = useState(null);

  const { data: trackDetails, isLoading: isLoadingTrackDetails } = useDataQuery(
    {
      url: API_END_POINTS.spotifyTrackDetails.replace(":id", selectedTrack?.id),
      params: {},
      enabled: !!selectedTrack?.id,
    }
  );

  const getCountryName = (initials) => {
    return countryInitialsToName[initials] || initials;
  };

  // Function to sort the country data
  function sortCountryData(countryData) {
    return countryData.sort((a, b) => {
      const countryA = getCountryName(a?.countryInitials);
      const countryB = getCountryName(b?.countryInitials);
      return countryA?.localeCompare(countryB);
    });
  }

  // Get the sorted country data
  const sortedCountryData = sortCountryData(
    overviewData?.data?.demographic?.country || []
  );

  // Calculate the total count of Male, Female, and Unspecified
  const totalM = overviewData?.data?.demographic?.gender?.map((item) => item.M);
  const totalF = overviewData?.data?.demographic?.gender?.reduce(
    (total, item) => total + item.F,
    0
  );
  const totalU = overviewData?.data?.demographic?.gender?.reduce(
    (total, item) => total + item.U,
    0
  );

  const sortedCountries = overviewData?.data?.demographic?.country
    ?.sort((a, b) => b.followersCount - a.followersCount)
    .slice(0, 10);

  const handleUnlinkPlatform = () => {
    unlinkPlatform(
      { provider: platform },
      {
        onSuccess: () => {
          setConfirmationDialog(false);
          notify("Platform unlinked successfully", "success");

          navigate(APP_ROUTES.social);
          refetchUserData();
        },
        onError: (err) => {
          notify(err?.message, "error");
        },
      }
    );
  };

  const onClickLogin = async (type) => {
    switch (type) {
      case "Spotify".toLowerCase():
        const queryParams = {
          client_id: CLIENT_ID,
          response_type: RESPONSE_TYPE,
          redirect_uri:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/callback"
              : REDIRECT_URI,
          scope: "user-read-private user-read-email", // Add any required scopes
          state: Math.random() * 100000,
        };
        window.location.href = `${AUTH_ENDPOINT}?${queryString.stringify(
          queryParams
        )}`;
        break;

      case "Instagram".toLowerCase():
        window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=3712894485649552&display=popup&redirect_uri=${
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/callback/instacallback"
            : process.env.REACT_APP_INSTAGRAM_REDIRECT_URI
        }&response_type=token&scope=email,instagram_manage_insights,pages_show_list,business_management`;

        break;
      default:
        break;
    }
  };

  // Add this function to handle form submissions
  const onSubmit = (data) => {
    // Handle form submission if needed
    console.log(data);
  };

  const isDataAvailable = useMemo(() => {
    if (!isLinked) return false;
    if (!overviewData?.data) return false;
    if (platform === "spotify") {
      return (
        overviewData.data.overview?.totalFollowers !== undefined ||
        overviewData.data.overview?.monthlyListeners !== undefined
      );
    }
    return overviewData.data.overview?.totalFollowers !== undefined;
  }, [overviewData, platform, isLinked]);

  const handleDataFormatChange = useCallback((newFormat) => {
    setDataFormatType(newFormat);
  }, []);

  const memoizedChartData = useMemo(() => chartData?.data, [chartData?.data]);

  const handleChartValueChange = useCallback((newValue) => {
    setSelectedChartValue(newValue);
  }, []);

  const progressChartComponent = useMemo(
    () =>
      isDataAvailable ? (
        <ProgressChart
          chartData={chartData?.data}
          selectedChartValue={selectedChartValue}
          setSelectedChartValue={handleChartValueChange}
          className="mt-20"
          size="large"
          onDataFormatChange={handleDataFormatChange}
          defaultDataFormat={dataFormatType}
          showFormatToggle={true}
        />
      ) : null,
    [
      chartData?.data,
      selectedChartValue,
      dataFormatType,
      handleChartValueChange,
      handleDataFormatChange,
      isDataAvailable,
    ]
  );

  const getSortedTracks = useCallback(
    (tracks) => {
      if (!tracks) return [];

      return [...tracks].sort((a, b) => {
        if (trackSortOption.value === "release_date") {
          return new Date(b.release_date) - new Date(a.release_date);
        }
        return (b.sp_streams || 0) - (a.sp_streams || 0);
      });
    },
    [trackSortOption]
  );

  const handleTrackClick = (track) => {
    setSelectedTrack(track);
  };

  return (
    // Add onSubmit handler to prevent default form behavior
    <div
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <div className="p-absolute" style={{ top: 0 }}>
        <h1 className="white">Social & Streaming</h1>
        <span className="gray-400 regularL">
          Task lists, event vibes, and social beats
        </span>
      </div>
      {isLoadingSocialData || isSocialDataFetching ? (
        <div className="align-center justify-center">
          <BallTriangleLoader />
        </div>
      ) : (
        <div className="my-4">
          <Button
            className="my-8 flex-row"
            variety="outlined"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon /> Back
          </Button>
          {isLinked ? (
            !isDataAvailable ? (
              // New loading state for linked accounts without data
              <Card className="card-second-wrapper p-16">
                <div className="items-center justify-center flex flex-column min-h-96">
                  <div className="animate-pulse">
                    {platform === "spotify" ? (
                      <SpotifyIcon />
                    ) : (
                      <InstagramIcon />
                    )}
                  </div>
                  <h3 className="white mt-8 text-center">
                    Fetching data from{" "}
                    {platform.charAt(0).toUpperCase() + platform.slice(1)}...
                  </h3>
                  <p className="gray-400 mt-4 text-center max-w-md">
                    We're collecting your data for the first time. This might
                    take a few minutes. Please check back soon!
                  </p>
                </div>
              </Card>
            ) : (
              // Existing content for when data is available
              <div>
                <Card className={"card-second-wrapper "}>
                  <div
                    className="flex-row justify-space-between p-16"
                    style={{ background: "rgba(90, 75, 126, 0.1)" }}
                  >
                    <div className="flex-row align-center w-35 justify-space-between gap-8">
                      <div className="overview-platform-selector w-40">
                        {platform === "instagram" ? (
                          <InstagramIcon />
                        ) : (
                          <SpotifyIcon />
                        )}
                        <span className="regularS white">
                          {platform?.charAt(0)?.toUpperCase() +
                            platform?.toLowerCase()?.slice(1)}
                        </span>
                      </div>
                      <Card className={"p-6 quick-add-task-container w-40"}>
                        <div className="flex-row align-center">
                          <div>{<UserIcon />}</div>
                          <div
                            className="flex-column"
                            style={{ paddingLeft: 12 }}
                          >
                            <span className="gray-400 regularXS whitespace-nowrap">
                              Total Followers
                            </span>
                            <span className="gray-200 regularM">
                              <NumberTicker
                                value={
                                  overviewData?.data?.overview?.totalFollowers
                                    ?.value || 0
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </Card>
                      {platform === "instagram" && (
                        <Card className={"p-6 quick-add-task-container w-40"}>
                          <div className="flex-row align-center">
                            <div>{<UserIcon />}</div>
                            <div
                              className="flex-column"
                              style={{ paddingLeft: 12 }}
                            >
                              <span className="gray-400 regularXS whitespace-nowrap">
                                Engagement Rate
                              </span>
                              {/* <span className="gray-200 regularM">
                              <NumberTicker value={overviewData?.data?.overview?.engagementRate?.value || 0} decimals={2} />
                            </span> */}
                              <div className="flex items-center">
                                <div className="gray-200 regularM w-[40px]">
                                  <NumberTicker
                                    value={
                                      overviewData?.data?.overview
                                        ?.engagementRate?.value || 0
                                    }
                                    decimals={2}
                                  />
                                </div>
                                <span className="gray-200 regularM min-w-[20px] flex-shrink-0">
                                  %
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card>
                      )}
                      {platform === "spotify" && (
                        <Card className={"p-6 quick-add-task-container w-40"}>
                          <div className="flex-row align-center">
                            <div>{<UserIcon />}</div>
                            <div
                              className="flex-column"
                              style={{ paddingLeft: 12 }}
                            >
                              <span className="gray-400 regularXS whitespace-nowrap">
                                Monthly Listeners
                              </span>
                              <span className="gray-200 regularM">
                                <NumberTicker
                                  value={
                                    overviewData?.data?.overview
                                      ?.monthlyListeners || 0
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </Card>
                      )}
                    </div>
                    <Button
                      className="flex-row mediumM"
                      variety="filled"
                      height={"48px"}
                      onClick={() => setConfirmationDialog(!confirmationDialog)}
                    >
                      <AddPreIcon />
                      {"Connected"}
                    </Button>
                  </div>
                  <div className="flex-row align-center justify-space-between">
                    <h3 className="white p-12">Overview</h3>
                    <div className="w-20 flex-row">
                      <div className="bg-[rgba(90,75,126,0.15)] rounded-lg p-2 shadow-lg flex gap-1">
                        {periodDataType?.map((item, index) => (
                          <div
                            key={item.value}
                            className={`px-3 py-1.5 rounded-md transition-all duration-200 ${
                              selectedDuration === item.value
                                ? "bg-[rgba(90,75,126,0.3)] shadow-inner"
                                : "hover:bg-[rgba(90,75,126,0.2)]"
                            }`}
                          >
                            <span
                              className={`${
                                selectedDuration === item.value
                                  ? "text-green-400"
                                  : "text-gray-300"
                              } cursor-pointer text-sm font-medium transition-colors duration-200 hover:text-green-400`}
                              onClick={() => setSelectedDuration(item.value)}
                            >
                              {item.label}
                            </span>
                            {index < periodDataType.length - 1 && (
                              <span className="text-gray-500 ml-2">|</span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex-row justify-space-between flex-wrap p-8">
                    {/* <Card className={"w-15 p-24 m-t-8"}>
              <div className="white flex-column">
                <div style={{ height: 24 }}>{<UserIcon />}</div>
                <span>Total Followers</span>
                <span>{overviewData?.data?.overview?.totalFollowers}</span>
              </div>
            </Card> */}
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                      {overviewData?.data?.metrics?.map((item, index) => (
                        <Card key={index} className={"w-100 p-24 m-t-8"}>
                          <div className="white flex-column">
                            <div style={{ height: 24 }}>
                              <img src={item?.imageUrl} alt={item?.title} />
                            </div>
                            <span>{item?.title}</span>
                            <span>
                              {item?.title === "Listeners" ||
                              item?.title === "Followers" ? (
                                <span>
                                  {item?.value >= 0 ? "+" : "-"}
                                  <NumberTicker value={Math.abs(item?.value)} />
                                </span>
                              ) : item?.title ===
                                "Followers: Listeners Ratio" ? (
                                <NumberTicker
                                  value={parseFloat(item?.value || 0)}
                                  decimals={2}
                                />
                              ) : typeof item?.value === "number" ? (
                                item?.suffix ? (
                                  <div className="flex items-center">
                                    <div className="w-[40px]">
                                      <NumberTicker
                                        value={item?.value}
                                        decimals={2}
                                      />
                                    </div>
                                    <span className="ml-1 min-w-[20px] flex-shrink-0">
                                      {item?.suffix}
                                    </span>
                                  </div>
                                ) : (
                                  <NumberTicker value={item?.value} />
                                )
                              ) : (
                                item?.value
                              )}
                            </span>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                </Card>
                {platform === "instagram" ? (
                  <div>
                    <div className="flex-row justify-space-between">
                      <Card className={"w-45 p-8 m-t-24"}>
                        <div>
                          <span className="white">Gender</span>
                          <div className="quick-add-task-container m-8 p-12">
                            <span className="white regularM">
                              {"Overall Summary"}
                            </span>
                            {overviewData?.data?.demographic?.gender?.map(
                              (item, index) => (
                                <div className="flex-row justify-space-between">
                                  <span className="gray-400 regularXS">
                                    {item?.gender}
                                  </span>
                                  <span className="gray-200 regularM">
                                    {item.followersCount}&nbsp;
                                  </span>
                                </div>
                              )
                            )}

                            {overviewData?.data?.demographic?.age?.map(
                              (item, index) => {
                                const totalCount = item.M + item.F + item.U;

                                const totalPercentage = (
                                  (item?.followersCount /
                                    overviewData?.data?.overview?.totalFollowers
                                      ?.value) *
                                  100
                                ).toFixed(2);

                                return (
                                  <div className="border-b-1">
                                    <div
                                      className="flex-row justify-space-between"
                                      style={{ padding: "12px 0" }}
                                    >
                                      <span className="white regularM">
                                        {item.group}
                                      </span>
                                      <span className="gray-200 regularM">
                                        <NumberTicker
                                          value={item?.followersCount}
                                        />{" "}
                                        ({totalPercentage}%)
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </Card>

                      <Card className={"w-50 p-8 m-t-24"}>
                        <div>
                          <div className="flex-row justify-space-between">
                            <span className="white">Countries</span>
                            <span className="white">Followers</span>
                          </div>
                          <div className="m-8 p-12">
                            {sortedCountries?.map((item, index) => (
                              <div className="border-b-1">
                                <div className="flex-row justify-space-between align-center p-8">
                                  <span className="gray-400 regularXS">
                                    {getCountryName(item?.countryInitials)}
                                  </span>
                                  <span className="gray-200 regularM">
                                    <NumberTicker value={item.followersCount} />
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Card>

                      <Dialog
                        show={confirmationDialog}
                        action1ButtonLabel={"Cancel"}
                        action2ButtonLabel={"Unlink"}
                        handelAction1={() => setConfirmationDialog(false)}
                        handelAction2={() => {
                          handleUnlinkPlatform();
                        }}
                      >
                        <div>
                          <h3 className="white">
                            Are you sure you want to unlink {platform}?
                          </h3>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                ) : platform === "spotify" ? (
                  <div>
                    <div className="flex-row justify-space-between">
                      <Card className={"w-45 p-8 m-t-24"}>
                        <div>
                          <div className="flex-row justify-space-between px-4">
                            <span className="white">City</span>
                            <span className="white">Total Listeners</span>
                          </div>
                          <div className="m-4 p-8">
                            {overviewData?.data?.demographics?.city?.map(
                              (item, index) => (
                                <div className="border-b-1">
                                  <div className="flex-row justify-space-between align-center p-8">
                                    <span className="gray-400 regularXS">
                                      {/* {getCountryName(item?.countryInitials)} */}
                                      {item?.city}
                                    </span>
                                    <span className="gray-200 regularM">
                                      <NumberTicker
                                        value={item?.totalListeners}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </Card>

                      <Card className={"w-45 p-8 m-t-24"}>
                        <div>
                          <div className="flex-row justify-space-between px-4">
                            <span className="white">Countries</span>
                            <span className="white">Total Listeners</span>
                          </div>
                          <div className="m-4 p-8">
                            {overviewData?.data?.demographics?.country?.map(
                              (item, index) => (
                                <div className="border-b-1">
                                  <div className="flex-row justify-space-between align-center p-8">
                                    <span className="gray-400 regularXS">
                                      {/* {getCountryName(item?.countryInitials)} */}
                                      {item?.country}
                                    </span>
                                    <span className="gray-200 regularM">
                                      <NumberTicker
                                        value={item?.totalListeners}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </Card>
                    </div>

                    <div className="flex-row justify-space-between">
                      <Card className={"w-full p-8 m-t-24"}>
                        <div>
                          <div className="flex-row justify-space-between align-center px-4">
                            <span className="white">Tracks</span>
                            <div className="flex-row align-center gap-4">
                              <LabeledDropdown
                                options={TRACK_SORT_OPTIONS}
                                value={trackSortOption.value}
                                onChange={(value) =>
                                  setTrackSortOption(
                                    TRACK_SORT_OPTIONS.find(
                                      (opt) => opt.value === value
                                    )
                                  )
                                }
                                prefixLabel="Sort By"
                                showIcon={true}
                              />
                              <span className="white">Total Streams</span>
                            </div>
                          </div>
                          <div className="m-4 p-8">
                            {getSortedTracks(overviewData?.data?.tracks)
                              ?.slice(0, showAllTracks ? undefined : 5)
                              ?.map((track, index) => (
                                <div
                                  className="border-b-1 cursor-pointer hover:bg-[rgba(90,75,126,0.1)]"
                                  key={track._id}
                                  onClick={() => handleTrackClick(track)}
                                >
                                  <div className="flex-row justify-space-between align-center p-8">
                                    <div className="flex-row align-center gap-3">
                                      <img
                                        src={track.image_url}
                                        alt={track.name}
                                        className="w-8 h-8 rounded"
                                      />
                                      <span className="gray-400 regularXS">
                                        {track.name}
                                      </span>
                                    </div>
                                    <span className="gray-200 regularM">
                                      {track.sp_streams ? (
                                        <NumberTicker
                                          value={track.sp_streams}
                                        />
                                      ) : (
                                        "< 1,000"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            {overviewData?.data?.tracks?.length > 5 && (
                              <div className="flex justify-center mt-4">
                                <span
                                  className="flex items-center gap-1 text-[#9B9BA4] hover:text-[#E2E2E4] cursor-pointer transition-colors duration-200 regularXS"
                                  onClick={() =>
                                    setShowAllTracks(!showAllTracks)
                                  }
                                >
                                  {showAllTracks ? (
                                    <>
                                      Show Less{" "}
                                      <ChevronUpIcon className="w-3 h-3" />
                                    </>
                                  ) : (
                                    <>
                                      Show More{" "}
                                      <ChevronDownIcon className="w-3 h-3" />
                                    </>
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </Card>
                    </div>

                    <Dialog
                      show={confirmationDialog}
                      action1ButtonLabel={"Cancel"}
                      action2ButtonLabel={"Unlink"}
                      handelAction1={() => setConfirmationDialog(false)}
                      handelAction2={() => {
                        handleUnlinkPlatform();
                      }}
                    >
                      <div>
                        <h3 className="white">
                          Are you sure you want to unlink {platform}?
                        </h3>
                      </div>
                    </Dialog>
                  </div>
                ) : null}
              </div>
            )
          ) : (
            <div>
              <Card className={"card-second-wrapper "}>
                <div
                  className="flex-row justify-space-between p-16"
                  style={{ background: "rgba(90, 75, 126, 0.1)" }}
                >
                  <div className="flex-row align-center w-35 justify-space-between">
                    <div className="overview-platform-selector w-40">
                      {platform === "instagram" ? (
                        <InstagramIcon />
                      ) : (
                        <SpotifyIcon />
                      )}
                      <span className="regularS white">
                        {platform?.charAt(0)?.toUpperCase() +
                          platform?.toLowerCase()?.slice(1)}
                      </span>
                    </div>
                  </div>
                  <Button
                    className="flex-row mediumM"
                    variety="filled"
                    height={"48px"}
                    onClick={() => setConfirmationDialog(!confirmationDialog)}
                  >
                    <AddPreIcon />
                    {"Connected"}
                  </Button>
                </div>
                <div className="items-center justify-center flex flex-column min-h-96">
                  <EmptySocial />
                  <span className="white boldL p-24">
                    Connect your{" "}
                    {`${
                      platform === "instagram"
                        ? "Instagram Business"
                        : "Spotify"
                    }`}{" "}
                    account
                  </span>
                  <p className="white w-2/5 text-center">
                    Connect your{" "}
                    {`${
                      platform === "instagram"
                        ? "Instagram Business"
                        : "Spotify"
                    }`}{" "}
                    account to get insights on your posts, stories, audience
                    growth, reach, engagement and more!
                  </p>
                  <Button
                    variety="filled"
                    className="mt-8 flex-row mediumM"
                    onClick={() => onClickLogin(platform)}
                  >
                    <AddPreIcon /> Connect
                  </Button>
                </div>
              </Card>
            </div>
          )}
        </div>
      )}
      {isLinked && isDataAvailable && <SocialChart platform={platform} />}
      <Modal
        isOpen={!!selectedTrack}
        onClose={() => setSelectedTrack(null)}
        title="Track Details"
      >
        <TrackModalViewDetails
          selectedTrack={trackDetails?.data || selectedTrack}
        />
      </Modal>
    </div>
  );
};

export default SocialOverviewPage;
