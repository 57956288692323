import moment from "moment";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Level1Icon } from "src/assets/svg/BadgeLevelIcon";
import useWindowSize from "src/hooks/ui/useWindowSize";
import { queryClient } from "../..";
import AvatarImage from "../../assets/png/avatar.png";
import { DownArrow } from "../../assets/svg/DownArrow";
import { useDataQuery } from "../../hooks/crud/data.query";
import usePageTitle from "../../hooks/pageTitleHook";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { APP_ROUTES } from "../../utils/app-routes";
import {
  GENESIS_USER_EMAIL,
  GENESIS_USER_SIGNUP,
  GENESIS_USER_TOKEN,
  USER_DETAILS,
} from "../../utils/constants";
import Button from "../Button";
import "./Header.css";
import { Box, Modal } from "@mui/material";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../pages/Auth/UploadProfileImage/getCroppedImage";
import { useMutationCreate } from "src/hooks/crud/mutation.create";
import { Pencil1Icon } from "@radix-ui/react-icons";

const Header = (props) => {
  const title = usePageTitle();
  const { width } = useWindowSize();
  const { data: userData, refetch: refetchUserData } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });

  return (
    <div
      className={`header ${
        props.isSidebarOpen
          ? width > 1024
            ? "w-full"
            : width > 640
            ? "w-[90%]"
            : "calc-width"
          : "w-full"
      }`}
      {...props}
    >
      <div className="header-title white">
        <span className="hidden sm:inline">{title}</span>
      </div>
      <div className="header-actions-container">
        <div className="hidden sm:flex box-border flex-row items-center p-[6px_12px] gap-2 w-[116px] h-[44px] bg-[#201B2C] border border-[rgba(26,255,216,0.4)] shadow-[-24px_20px_80px_rgba(0,0,0,0.12)] rounded-lg flex-none order-0 flex-grow-0 text-base">
          <Level1Icon />
          <span className="w-[51px] h-[21px] font-ibm-plex-sans font-normal text-[16px] leading-[21px] flex items-center text-white flex-none order-1 flex-grow-0">
            Level 1
          </span>
        </div>
        <UserView userData={userData} refetchUserData={refetchUserData} />
      </div>
    </div>
  );
};

const UserView = ({ userData, refetchUserData }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const { width } = useWindowSize();
  const userViewRef = useRef(null);
  const [previewUrl, setPreviewUrl] = useState(userData?.data?.profileImageURL);

  console.log("previewUrl", previewUrl);
  // useEffect(() => {
  //   setPreviewUrl(userData?.data?.profileImageURL);
  // }, [userData?.data?.profileImageURL]);

  useEffect(() => {
    // Add class to body when popup is open to prevent scrolling
    if (isProfileOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isProfileOpen]);

  return (
    <div
      ref={userViewRef}
      className={`user-view-container ${isProfileOpen ? "active" : ""}`}
      onClick={() => {
        setIsProfileOpen(!isProfileOpen);
      }}
    >
      <div className="user-avatar">
        {previewUrl || userData?.data?.profileImageURL ? (
          <img
            src={previewUrl || userData?.data?.profileImageURL}
            alt="User Avatar"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50%",
              border: "2px solid var(--Rove-White, #FFF)",
            }}
          />
        ) : (
          <img
            src={AvatarImage}
            style={{
              width: width <= 640 ? "24px" : "30px",
              height: width <= 640 ? "24px" : "30px",
              objectFit: "cover",
              borderRadius: "50%",
              border: "2px solid var(--Rove-White, #FFF)",
            }}
          />
        )}
      </div>
      <span className="hidden sm:inline">{userData?.data?.fullName}</span>
      <DownArrow />
      {isProfileOpen && (
        <>
          <div className="fixed inset-0 bg-black/50 z-40" /> {/* Overlay */}
          <ProfilePopup
            width={width}
            userData={userData}
            refetchUserData={refetchUserData}
            previewUrl={previewUrl || userData?.data?.profileImageURL}
            setPreviewUrl={setPreviewUrl}
            onClose={() => {
              setIsProfileOpen(false);
            }}
          />
        </>
      )}
    </div>
  );
};

const ProfilePopup = ({
  onClose,
  userData,
  width,
  refetchUserData,
  previewUrl,
  setPreviewUrl,
}) => {
  const popupRef = useRef(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  let clickCount = 0;

  // Add new state for cropping
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preview, setPreview] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedArea, setCroppedArea] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const { mutate: uploadProfileImage } = useMutationCreate(
    API_END_POINTS.uploadProfileImage,
    { "Content-Type": "multipart/form-data" }
  );

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file size and type
      if (file.size > 25 * 1024 * 1024) {
        alert("File size must be less than 25MB");
        return;
      }
      if (!file.type.startsWith("image/")) {
        alert("Please select an image file");
        return;
      }

      setSelectedFile(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleCropComplete = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(preview, croppedArea, rotation);

      // Set preview immediately
      setPreviewUrl(croppedImage);

      // Convert base64 to blob
      const response = await fetch(croppedImage);
      const blob = await response.blob();

      // Create a new File object from the blob
      const croppedFile = new File([blob], selectedFile.name, {
        type: selectedFile.type,
      });

      const formData = new FormData();
      formData.append("profile-image", croppedFile);

      uploadProfileImage(formData, {
        onSuccess(res) {
          refetchUserData();
          // setPreviewUrl(res.data.profileImageURL);
          setIsModalOpen(false);

          onClose();
        },
        onError: (err) => {
          console.error("Failed to upload image:", err);
        },
      });
    } catch (error) {
      console.error("Error processing image:", error);
    }
  }, [preview, croppedArea, rotation, selectedFile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        if (clickCount !== 0) {
          // onClose();
        }
        clickCount++;
      }
    };

    // Add click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return (
    <>
      <div
        className="user-view-pop-up card-app-background"
        ref={popupRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="user-avatar-2 relative">
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="User Avatar"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
                border: "4px solid var(--Rove-Blue, #92D7E7)",
              }}
            />
          ) : (
            <img
              src={AvatarImage}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: width >= 480 ? "80px" : "54px",
                border: "2px solid var(--Rove-White, #FFF)",
              }}
            />
          )}
          <div
            className="absolute bottom-[0px] right-[0px] bg-[--Genesis-Green-300] p-2 rounded-full cursor-pointer hover:bg-[--Genesis-Green-400]"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              fileInputRef.current.click();
            }}
          >
            <Pencil1Icon color="#000" />
          </div>

          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={handleFileChange}
          />
        </div>

        <div className="user-info">
          <h2 className="white text-center" style={{ lineHeight: "1.5rem" }}>
            {userData?.data?.fullName}
          </h2>

          <span className="regularL white mt-2 block text-center">
            {userData?.data?.emailAddress}
          </span>

          <span className="regularM white my-2 block text-center">
            Member since{" "}
            {moment(userData?.data?.createdAt).format("D MMM YYYY")}
          </span>

          <div className="divider"></div>

          <Button
            variety="filled"
            className="w-full mt-4"
            onClick={() => {
              localStorage.removeItem(USER_DETAILS);
              localStorage.removeItem(GENESIS_USER_EMAIL);
              localStorage.removeItem(GENESIS_USER_SIGNUP);
              localStorage.removeItem(GENESIS_USER_TOKEN);
              queryClient.removeQueries();
              navigate(APP_ROUTES.login);
            }}
          >
            <div className="flex items-center justify-center gap-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4093 16.2855C13.7828 16.2855 13.2896 16.792 13.2896 17.4052V20.1511C13.2896 21.0308 12.5832 21.7373 11.7034 21.7373H3.83893C2.95918 21.7373 2.25271 21.0308 2.25271 20.1511V3.84893C2.25271 2.96917 2.95918 2.2627 3.83893 2.2627H11.7034C12.5832 2.2627 13.2896 2.96917 13.2896 3.84893V6.2216C13.2896 6.8481 13.7962 7.34129 14.4093 7.34129C15.0225 7.34129 15.529 6.83477 15.529 6.2216V3.84893C15.529 1.72952 13.8095 0.00999451 11.6901 0.00999451H3.83893C1.71952 0.00999451 0 1.72952 0 3.84893V20.1511C0 22.2705 1.71952 23.99 3.83893 23.99H11.7034C13.8228 23.99 15.5423 22.2705 15.5423 20.1511V17.4052C15.5423 16.7787 15.0358 16.2855 14.4227 16.2855H14.4093Z"
                  fill="#2B3138"
                />
                <path
                  d="M23.6601 11.1802L19.6612 7.18134C19.2213 6.7548 18.5282 6.74147 18.0883 7.16801C17.6351 7.60789 17.6484 8.34102 18.0883 8.79423L20.1677 10.8737H10.4238C9.79727 10.8737 9.30408 11.3802 9.30408 11.9933C9.30408 12.6065 9.8106 13.113 10.4238 13.113H20.1677L18.0616 15.2191C17.6218 15.659 17.6218 16.3655 18.0616 16.8053C18.5015 17.2452 19.208 17.2452 19.6479 16.8053L23.6601 12.8064C24.1133 12.3532 24.1133 11.6201 23.6601 11.1669V11.1802Z"
                  fill="#2B3138"
                />
              </svg>
              <span className="regularL">Log out</span>
            </div>
          </Button>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onClick={(e) => e.stopPropagation()}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            height: 500,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="modal-header">
            <h2>Crop Profile Picture</h2>
            <div className="crop-instructions">
              <p>
                Drag to reposition • Pinch or use slider to zoom • Click and
                drag corners to crop
              </p>
            </div>
          </div>
          <div style={{ position: "relative", height: "80%" }}>
            <Cropper
              image={preview}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              minZoom={1}
              onMediaLoaded={(mediaSize) => {
                const initialZoom = Math.max(1, 300 / mediaSize.naturalHeight);
                setZoom(initialZoom);
              }}
            />
          </div>
          <div className="controls">
            <div className="zoom-control">
              <label>Zoom: </label>
              <input
                type="range"
                min={0.5}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(parseFloat(e.target.value))}
              />
            </div>
          </div>
          <Button variety="filled" onClick={handleCropComplete}>
            Done
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
