import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import "./LabeledDropdown.css";

const OptionWithLabel = ({
  icon: Icon,
  label,
  color,
  showPrefix = false,
  prefixLabel,
}) => (
  <div className="labeled-option">
    {showPrefix && (
      <span className="gray-300 regularXS mr-1">{prefixLabel}:</span>
    )}
    {Icon && <Icon className="option-icon" style={{ color }} />}
    <span className="regularXS">{label}</span>
  </div>
);

const LabeledDropdown = ({
  options: rawOptions,
  value,
  onChange,
  prefixLabel,
  isDashboard,
  className = "",
  showIcon = true,
  isListView,
}) => {
  const options = rawOptions.map((option) => ({
    ...option,
    label: (
      <OptionWithLabel
        icon={showIcon ? option.icon : null}
        label={option.label}
        color={option.color}
      />
    ),
    displayLabel: (
      <OptionWithLabel
        icon={showIcon ? option.icon : null}
        label={option.label}
        color={option.color}
        showPrefix={isListView ? false : true}
        prefixLabel={prefixLabel}
      />
    ),
  }));

  const getCurrentLabel = (value) => {
    const option = options.find((opt) => opt.value === value);
    return option?.displayLabel || options[0].displayLabel;
  };

  return (
    <Dropdown
      options={options}
      onOptionChange={(option) => onChange(option.value)}
      initialOption={{
        label: getCurrentLabel(value),
        value: value,
      }}
      className={`labeled-dropdown ${className}`}
      isDashboard={isDashboard}
    />
  );
};

export default LabeledDropdown;
