import React from "react";
import LabeledDropdown from "./LabeledDropdown";
import {
  ClockIcon,
  CircleIcon,
  CrossCircledIcon,
  CheckCircledIcon,
} from "@radix-ui/react-icons";

const StatusDropdown = ({
  status,
  onStatusChange,
  isDashboard,
  isListView,
}) => {
  const statusOptions = [
    {
      label: "To Do",
      value: "TODO",
      icon: CircleIcon,
      color: "#06719A",
    },
    {
      label: "In Progress",
      value: "IN_PROGRESS",
      icon: ClockIcon,
      color: "#FF7700",
    },
    {
      label: "Blocked",
      value: "BLOCKED",
      icon: CrossCircledIcon,
      color: "#EF4870",
    },
    {
      label: "Completed",
      value: "COMPLETED",
      icon: CheckCircledIcon,
      color: "#4CAF50",
    },
  ];

  return (
    <LabeledDropdown
      options={statusOptions}
      value={status}
      onChange={onStatusChange}
      prefixLabel="Status"
      isListView={isListView}
      isDashboard={isDashboard}
      className="status-dropdown"
    />
  );
};

export default StatusDropdown;
