import React, { useState } from "react";
import { useIsSidebarOpen } from "src/hooks/ui/IsSidebarOpen";
import DashboardComponent from "../../components/Dashboard/DashboardEventComponent";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";

const EventAndTaskPage = () => {
  const { isSidebarOpen } = useIsSidebarOpen();
  const [googleEvents, setGoogleEvents] = useState([]);
  const {
    data: timelineData,
    isFetching: isFetchingTimeline,
    isLoading: isLoadingTimeline,
  } = useDataQuery({
    url: API_END_POINTS.timeline,
    params: {},
  });
  const {
    data: projectData,
    isFetching: isFetchingProject,
    isLoading: isLoadingProject,
  } = useDataQuery({
    url: API_END_POINTS.project,
    params: {},
  });
  const { data: googleCalendarLinkedStatus } = useDataQuery({
    url: API_END_POINTS.googleCalendarLinkStatus,
    params: {},
  });

  return (
    <div
      className={`${isSidebarOpen ? "w-full sm:w-[92%] md:w-full" : "w-full"}`}
    >
      <span className="gray-400 regularL">
        Task lists, event vibes, and social beats
      </span>

      <div className="flex-row justify-space-between ">
        {/* <GanttChart timelineData={timelineData?.data} /> */}
        <DashboardComponent
          className="w-100"
          isEventTab={true}
          timelineData={timelineData}
          isLoadingTimeline={isLoadingTimeline}
          googleCalendarStatus={googleCalendarLinkedStatus?.data}
        />
      </div>
    </div>
  );
};

export default EventAndTaskPage;
