import { useState, useEffect } from "react";
import { notify } from "../../../components/Toast/ToastComponent";

const useQuestionnaireState = ({
  sections = [],
  currentSectionIndex = 0,
  currentQuestionIndex = 0,
  onNext,
  onSubmit,
  submitQuestionnaire,
  refetchQuestionnaireProgress,
  onClose,
}) => {
  const [activeSection, setActiveSection] = useState(currentSectionIndex);
  const [activeSubSection, setActiveSubSection] = useState(0);
  const [responses, setResponses] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSkipConfirmation, setShowSkipConfirmation] = useState(false);
  const [completedSections, setCompletedSections] = useState([]);
  const [sectionsToFadeOut, setSectionsToFadeOut] = useState([]);
  const [showValidation, setShowValidation] = useState(false);

  // Reset to initial state when modal is opened
  useEffect(() => {
    setActiveSection(currentSectionIndex);
    setActiveSubSection(0);
  }, [currentSectionIndex]);

  // Reset validation state when section or subsection changes
  useEffect(() => {
    setShowValidation(false);
  }, [activeSection, activeSubSection]);

  // Prepare the current section and subsections
  const currentSection = sections[activeSection] || {
    title: "Section",
    questions: [],
  };

  // Group questions by subsection based on badges
  const groupQuestionsBySubsection = (questions) => {
    const subsections = [];
    let currentSubsection = null;
    let currentQuestions = [];

    questions.forEach((question) => {
      // If this is a new badge/subsection or the first question
      if (!currentSubsection || currentSubsection) {
        // Save the previous subsection if it exists
        if (currentSubsection) {
          subsections.push({
            ...currentSubsection,
            questions: [...currentQuestions],
          });
          currentQuestions = [];
        }

        // Start a new subsection
        currentSubsection = {
          title: determineSubsectionTitle(question),
          badgeTitle: question.badgeTitle,
          badgeDescription: question.badgeDescription,
        };
      }

      // Add question to current subsection
      currentQuestions.push(question);
    });

    // Add the last subsection
    if (currentSubsection) {
      subsections.push({
        ...currentSubsection,
        questions: [...currentQuestions],
      });
    }

    return subsections;
  };

  // Function to determine subsection title based on question content
  const determineSubsectionTitle = (question) => {
    // First question of "Goals & Releases" section
    if (question.question?.includes("next planned release")) {
      return "Next Release & Yearly Output";
    }
    // Second question of "Goals & Releases" section
    else if (question.question?.includes("success")) {
      return "Definition of Success";
    }
    // Third question of "Goals & Releases" section
    else if (question.question?.includes("aggressively")) {
      return "Growth Approach";
    }
    // First two questions of "Music Creation & Content"
    else if (
      question.question?.includes("record/mix/master") ||
      question.question?.includes("create/edit video")
    ) {
      return "DIY Production & Video";
    }
    // Last two questions of "Music Creation & Content"
    else if (
      question.question?.includes("frequently") ||
      question.question?.includes("daily stories")
    ) {
      return "Posting Cadence";
    }
    // First question of "Brand & Identity"
    else if (question.question?.includes("start making music")) {
      return "Start & Inspiration";
    }
    // Second and third questions of "Brand & Identity"
    else if (
      question.question?.includes("influences") ||
      question.question?.includes("music style")
    ) {
      return "Influences & Aesthetic";
    }
    // Last three questions of "Brand & Identity"
    else if (
      question.question?.includes("messages/values") ||
      question.question?.includes("ideal fan") ||
      question.question?.includes("combination of three")
    ) {
      return "Core Message & Audience";
    }
    // "Live Events" section
    else if (
      question.question?.includes("performing live") ||
      question.question?.includes("local venues")
    ) {
      return "Performance Plans";
    }
    // First two questions of "Time & Budget"
    else if (
      question.question?.includes("hours per week") ||
      question.question?.includes("task sprints")
    ) {
      return "Weekly Commitment";
    }
    // Last two questions of "Time & Budget"
    else if (
      question.question?.includes("budget") ||
      question.question?.includes("equipment")
    ) {
      return "Budget & Resources";
    }
    // Default fallback
    else {
      return question.badgeTitle || "Questions";
    }
  };

  // Create subsections
  const subsections = groupQuestionsBySubsection(
    currentSection.questions || []
  );
  const currentSubsection = subsections[activeSubSection] || {
    title: "",
    questions: [],
  };

  // Update a response
  const updateResponse = (questionId, value) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  // Get a response
  const getResponse = (questionId) => {
    return responses[questionId];
  };

  // Format questions for API
  const formatQuestionPayload = (question) => {
    const answer = getResponse(question.id);

    // Start with a clone of the original question to maintain all properties
    const questionPayload = { ...question };

    // Add answer to the payload but maintain original structure
    if (question.type === "combined" && question.parts) {
      // For combined questions, add answers to each part
      questionPayload.parts = question.parts.map((part) => {
        // Try to get answer from the combined answer object first
        const partAnswer =
          answer && answer[part.id]
            ? answer[part.id]
            : getResponse(part.id) || ""; // Fallback to direct part responses

        return {
          ...part, // Keep all original part properties
          answer: partAnswer,
        };
      });

      // Add combined answer as comma-separated values if needed
      questionPayload.answer = questionPayload.parts
        .map((part) => part.answer)
        .filter(Boolean)
        .join(", ");
    } else if (question.type === "goalInputs" && question.inputs) {
      // For goalInputs, add answers to each input
      questionPayload.inputs = question.inputs.map((input) => {
        const inputAnswer = answer && answer[input.id] ? answer[input.id] : "";
        return {
          ...input, // Keep all original input properties
          answer: inputAnswer,
        };
      });

      // Add combined answer as comma-separated values if needed
      questionPayload.answer = answer
        ? Object.values(answer).filter(Boolean).join(", ")
        : "";
    } else if (question.type === "multiSelect") {
      // For multiSelect, format as array or comma-separated string based on what's needed
      questionPayload.answer = Array.isArray(answer) ? answer : [];
    } else {
      // For all other question types
      questionPayload.answer = answer || "";
    }

    return questionPayload;
  };

  const handleBack = () => {
    if (activeSubSection > 0) {
      setActiveSubSection(activeSubSection - 1);
    } else if (activeSection > 0) {
      setActiveSection(activeSection - 1);
      const prevSubsections = groupQuestionsBySubsection(
        sections[activeSection - 1]?.questions || []
      );
      setActiveSubSection(Math.max(0, prevSubsections.length - 1));
    }
  };

  const handleNext = () => {
    setShowValidation(true);

    // Validate current subsection questions
    const currentQuestions = currentSubsection.questions || [];
    let hasEmptyFields = false;
    let firstEmptyField = null;

    for (const question of currentQuestions) {
      const response = responses[question.id];

      // Check different question types
      if (question.type === "text" && (!response || response.trim() === "")) {
        hasEmptyFields = true;
        firstEmptyField = question;
        break;
      } else if (question.type === "select" && !response) {
        hasEmptyFields = true;
        firstEmptyField = question;
        break;
      } else if (question.type === "buttons" && !response) {
        hasEmptyFields = true;
        firstEmptyField = question;
        break;
      } else if (
        question.type === "multiSelect" &&
        (!response || response.length === 0)
      ) {
        hasEmptyFields = true;
        firstEmptyField = question;
        break;
      } else if (
        question.type === "goalInputs" &&
        (!response || Object.values(response).some((val) => val === ""))
      ) {
        hasEmptyFields = true;
        firstEmptyField = question;
        break;
      } else if (
        question.type === "combined" &&
        (!response || Object.values(response).some((val) => val === ""))
      ) {
        hasEmptyFields = true;
        firstEmptyField = question;
        break;
      }
    }

    if (hasEmptyFields) {
      notify({
        type: "error",
        message: `Please fill in all required fields. "${firstEmptyField.question}" is required.`,
      });
      return;
    }

    // Format questions for API while preserving the original structure
    const sectionQuestions = currentSection.questions.map(
      formatQuestionPayload
    );

    // Create consistent payload structure for all cases
    const payload = {
      isSectionEnd: activeSubSection === subsections.length - 1,
      section: activeSection + 1, // 1-based indexing for API
      number: activeSection + 1,
      isEndOfQuestionnaires:
        activeSection === sections.length - 1 &&
        activeSubSection === subsections.length - 1,
      title: sections[activeSection].title,
      questions: sectionQuestions,
    };

    if (activeSubSection < subsections.length - 1) {
      // Move to the next subsection within the current section
      setActiveSubSection(activeSubSection + 1);

      // We're not at the end of a section, so don't make API calls
      // Just notify parent component for UI updates if needed
      if (onNext) {
        try {
          onNext(payload);
          // Reset validation state only after successful navigation
          setShowValidation(false);
        } catch (error) {
          notify(error?.message || "Failed to proceed to next step", "error");
          setActiveSubSection(activeSubSection); // Revert the subsection change
          return; // Prevent further execution
        }
      } else {
        // If no onNext handler, just reset validation
        setShowValidation(false);
      }
    } else if (activeSection < sections.length - 1) {
      // End of section - add to completed sections
      const completedSection = {
        section: {
          ...sections[activeSection],
          badge: {
            ...sections[activeSection].badge,
            imageUrl:
              sections[activeSection].badge?.imageUrl ||
              currentSection?.badge?.imageUrl,
          },
        },
        index: activeSection,
        timestamp: Date.now(),
      };

      // Now we're at the end of a section, so make API calls
      if (submitQuestionnaire) {
        submitQuestionnaire(payload, {
          onSuccess: () => {
            setCompletedSections((prev) => [...prev, completedSection]);
            // Move to next section
            setActiveSection(activeSection + 1);
            setActiveSubSection(0);
            if (refetchQuestionnaireProgress) {
              refetchQuestionnaireProgress();
            }
            // Still call onNext for backward compatibility
            if (onNext) {
              onNext(payload);
            }
          },
          onError: (error) => {
            notify(
              error?.message || "Failed to submit questionnaire section",
              "error"
            );
            // Don't move to next section on error
            return;
          },
        });
      } else if (onNext) {
        // Fallback to onNext if submitQuestionnaire is not available
        try {
          onNext(payload);
          setCompletedSections((prev) => [...prev, completedSection]);
          // Move to next section
          setActiveSection(activeSection + 1);
          setActiveSubSection(0);
        } catch (error) {
          notify(
            error?.message || "Failed to proceed to next section",
            "error"
          );
          return; // Don't proceed on error
        }
      }

      // Set a timeout to initiate fade out after 5 seconds
      const currentSectionIndex = activeSection; // Capture current value
      setTimeout(() => {
        setSectionsToFadeOut((prev) => [...prev, currentSectionIndex]);

        // Remove from both lists after fade animation completes
        setTimeout(() => {
          setCompletedSections((prev) =>
            prev.filter((cs) => cs.index !== currentSectionIndex)
          );
          setSectionsToFadeOut((prev) =>
            prev.filter((index) => index !== currentSectionIndex)
          );
        }, 1000); // Fade animation duration
      }, 5000); // Show for 5 seconds
    } else {
      // End of questionnaire - submit and close
      setIsSubmitting(true);

      // Directly call submitQuestionnaire with the payload if available
      if (submitQuestionnaire) {
        submitQuestionnaire(payload, {
          onSuccess: () => {
            // Still call onSubmit for backward compatibility
            if (onSubmit) {
              onSubmit(payload);
            }

            // Close the modal after a short delay
            setTimeout(() => {
              setIsSubmitting(false);
              onClose();
            }, 1000);
          },
          onError: (error) => {
            notify(
              error?.message || "Failed to submit final questionnaire",
              "error"
            );
            setIsSubmitting(false);
            // Don't close the modal on error
            return;
          },
        });
      } else if (onSubmit) {
        // Fallback to onSubmit if submitQuestionnaire is not available
        try {
          onSubmit(payload);
          // Close the modal after a short delay
          setTimeout(() => {
            setIsSubmitting(false);
            onClose();
          }, 1000);
        } catch (error) {
          notify(error?.message || "Failed to submit questionnaire", "error");
          setIsSubmitting(false);
          return; // Don't close on error
        }
      } else {
        // If no callbacks provided, just close the modal
        setIsSubmitting(false);
        onClose();
      }
    }
  };

  const handleSkip = () => {
    // Create a payload with the current section data even when skipping
    // const sectionQuestions = currentSection.questions.map(
    //   formatQuestionPayload
    // );

    // const payload = {
    //   isSectionEnd: true,
    //   section: activeSection + 1, // 1-based indexing for API
    //   number: activeSection + 1,
    //   isEndOfQuestionnaires:
    //     activeSection === sections.length - 1 &&
    //     activeSubSection === subsections.length - 1,
    //   title: sections[activeSection].title,
    //   questions: sectionQuestions,
    //   skipped: true, // Add flag to indicate this section was skipped
    // };

    // // If we're at the last section, show confirmation dialog
    // if (
    //   activeSection === sections.length - 1 &&
    //   activeSubSection === subsections.length - 1
    // ) {
    //   setShowSkipConfirmation(true);
    //   return;
    // }

    // // Send the skip payload if applicable
    // if (submitQuestionnaire) {
    //   submitQuestionnaire(payload, {
    //     onSuccess: () => {
    //       if (refetchQuestionnaireProgress) {
    //         refetchQuestionnaireProgress();
    //       }
    //       onClose();
    //     },
    //     onError: (error) => {
    //       console.error("Failed to submit skipped questionnaire:", error);
    //       onClose();
    //     },
    //   });
    // } else {
    // Close the modal when skip is clicked for non-final steps
    onClose();
    // }
  };

  const handleConfirmSkip = () => {
    // Create a payload with the current section data when skipping final section
    const sectionQuestions = currentSection.questions.map(
      formatQuestionPayload
    );

    const payload = {
      isSectionEnd: true,
      section: activeSection + 1, // 1-based indexing for API
      number: activeSection + 1,
      isEndOfQuestionnaires: true,
      title: sections[activeSection].title,
      questions: sectionQuestions,
      skipped: true, // Add flag to indicate this section was skipped
    };

    // Send the skip payload if applicable
    if (submitQuestionnaire) {
      submitQuestionnaire(payload, {
        onSuccess: () => {
          if (refetchQuestionnaireProgress) {
            refetchQuestionnaireProgress();
          }
          setShowSkipConfirmation(false);
          onClose();
        },
        onError: (error) => {
          console.error("Failed to submit skipped final questionnaire:", error);
          setShowSkipConfirmation(false);
          onClose();
        },
      });
    } else {
      setShowSkipConfirmation(false);
      onClose();
    }
  };

  const handleCancelSkip = () => {
    setShowSkipConfirmation(false);
  };

  return {
    activeSection,
    activeSubSection,
    responses,
    isSubmitting,
    showSkipConfirmation,
    completedSections,
    sectionsToFadeOut,
    currentSection,
    subsections,
    currentSubsection,
    updateResponse,
    getResponse,
    handleBack,
    handleNext,
    handleSkip,
    handleConfirmSkip,
    handleCancelSkip,
    formatQuestionPayload,
    showValidation,
  };
};

export default useQuestionnaireState;
