import { BuzzFactoryLogo } from "src/assets/svg/BuzzFactoryLogo";
import { Level1Icon } from "src/assets/svg/LevelIcon";

const LevelProgress = ({ currentLevel = 2, progress = 0.1 }) => {
  return (
    <div className="flex items-center p-6 rounded-lg">
      <div className="flex-1">
        <div className="relative flex justify-between">
          {/* Level Steps */}
          {[1, 2, 3, 4, 5].map((level) => (
            <div key={level} className="flex flex-col items-center z-10">
              {/* Level Badge */}
              <div className={`flex items-center justify-center relative`}>
                <Level1Icon
                  color={
                    level === currentLevel
                      ? "#6B7280"
                      : "url(#currentLevelGradient)"
                  }
                  style={
                    level === currentLevel
                      ? {
                          filter:
                            "drop-shadow(0px 0.6px 2.42px rgba(0, 0, 0, 0.16))",
                        }
                      : undefined
                  }
                />
                {level === currentLevel && (
                  <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2">
                    <div className="w-2 h-2 rounded-full bg-[#4ADE80]" />
                  </div>
                )}

                {/* Progress Line for each segment */}
                {level < 5 && (
                  <div className="absolute top-1/2 -translate-y-1/2 left-[calc(50%+28px)] h-[16px] bg-[#1F2937] w-[116%]">
                    {level < currentLevel && (
                      <div
                        className="absolute inset-0 h-[16px]"
                        style={{
                          background:
                            "linear-gradient(0deg, #027B66 0%, #00E5BE 54%, #027B66 98%)",
                        }}
                      />
                    )}
                    {level === currentLevel && (
                      <div
                        className="absolute left-0 h-[16px]"
                        style={{
                          width: `${progress * 100}%`,
                          background:
                            "linear-gradient(0deg, #027B66 0%, #00E5BE 54%, #027B66 98%)",
                          transition: "width 0.3s ease-in-out",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>

              {/* Level Text */}
              <div className="flex items-center mt-4">
                <div
                  className={`
                    text-sm
                    ${
                      level === currentLevel
                        ? "text-[#4ADE80]"
                        : "text-gray-500"
                    }
                  `}
                >
                  Level {level}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LevelProgress;
