import React from "react";
import CTACard from "./CTACard";

/**
 * A component to display a list of CTA cards
 * @param {Object} props
 * @param {Array} props.items - Array of CTA items
 * @param {string} [props.className] - Additional class names
 */
const CTACardList = ({ items, className = "" }) => {
  return (
    <div className={`flex items-start space-y-6 flex-col ${className}`}>
      {items.map((item) => (
        <CTACard
          key={item.id}
          title={item.title}
          description={item.description}
          buttonText={item.buttonText}
          buttonAction={item.buttonAction}
          indicatorColor={item.indicatorColor}
          buttonColor={item.buttonColor}
        />
      ))}
    </div>
  );
};

export default CTACardList;
