import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/app-routes";
import { notify } from "../../components/Toast/ToastComponent";
import { INSTAGRAM_TOKEN } from "../../utils/constants";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";

const InstagramCallback = () => {
  const navigate = useNavigate();
  const { mutate: storeInstagramToken } = useMutationCreate(
    API_END_POINTS.storeInstagramToken
  );

  const storeToken = async (access_token) => {
    try {
      // Verify token is valid by making a test API call
      const response = await fetch(
        `https://graph.facebook.com/v19.0/me?access_token=${access_token}`
      );
      const data = await response.json();

      if (data.error) {
        throw new Error(data.error.message);
      }

      console.log("access_token", access_token);
      // Store token in backend
      storeInstagramToken(
        { access_token },
        {
          onSuccess: () => {
            notify("Successfully connected to Instagram!", "success");
            navigate(APP_ROUTES.social);
          },
          onError: (err) => {
            notify(err?.message || "Failed to store Instagram token", "error");
            navigate(APP_ROUTES.social);
          },
        }
      );
    } catch (error) {
      notify(error.message || "Failed to connect Instagram", "error");
      navigate(APP_ROUTES.social);
    }
  };

  useEffect(() => {
    try {
      const url = new URL(window.location);
      const hashParams = new URLSearchParams(url.hash.substring(1));
      const access_token = hashParams.get("access_token");

      if (!access_token) {
        throw new Error("No access token received");
      }

      storeToken(access_token);
    } catch (error) {
      notify("Failed to process Instagram authentication", "error");
      navigate(APP_ROUTES.social);
    }
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h3 className="white mb-4">Connecting to Instagram...</h3>
      </div>
    </div>
  );
};

export default InstagramCallback;
